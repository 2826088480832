import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import FontAwesome from 'react-fontawesome';
import { Row, Col } from 'reactstrap';

import { setUserLogsPage } from '../../../../redux/actions/uiActions';
import { fetchUserLogs } from '../../../../redux/actions/userLogsActions';

class AdminChangeLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: props.userLogActivePage || 1,
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const { fetchUserLogs, userId, userLogActivePage } = this.props;

    fetchUserLogs(userId, {
      page: userLogActivePage,
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    const { userId, fetchUserLogs, setUserLogsPage } = this.props;
    setUserLogsPage(pageNumber);

    fetchUserLogs(userId, {
      page: pageNumber,
    });
  }

  isExpandableRow(row) {
    const message = row.message;
    const messageArray = message.split('\n');

    if (messageArray.length === 1) return false;
    else return true;
  }

  expandComponent(row) {
    const message = row.message;
    const messageArray = message.split('\n');

    if (messageArray.length === 1) return null;
    else {
      messageArray.splice(0, 1);
      return (
        <div className="user-logList-wrapper">
          <ul className="user-logList">
            {messageArray.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      );
    }
  }

  render() {
    const { userLogsList, userLogsTotal, isFetchingUserLogs } = this.props;

    const options = {
      page: this.state.activePage, // which page you want to show as default
      sizePerPage: 10, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      onPageChange: this.handlePageChange,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true,
      noDataText:
        !isFetchingUserLogs && userLogsList.length === 0 ? 'No records found' : 'Loading...',
      hideSizePerPage: true,
    };

    return (
      <Row>
        <Col md={{ size: 12 }}>
          <h3 className="aic-subtitle">{`Change Logs`}</h3>
          <div className="away-table-b">
            <BootstrapTable
              data={userLogsList}
              hover
              remote={true}
              pagination={true}
              fetchInfo={{ dataTotalSize: userLogsTotal }}
              options={options}
              expandableRow={this.isExpandableRow}
              expandComponent={this.expandComponent}
              expandColumnOptions={{
                expandColumnVisible: false,
              }}
              trClassName="at-row"
              tableHeaderClass="at-header"
              bootstrap4
            >
              <TableHeaderColumn
                isKey={true}
                dataField="created_at"
                dataFormat={(cell, row) => {
                  const date = row.created_at;
                  return <span>{date}</span>;
                }}
                dataAlign="center"
                dataSort={false}
                width="200"
              >
                <span className="at-th">Date</span>
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="message"
                dataFormat={(cell, row) => {
                  const message = row.message;
                  const messageArray = message.split('\n');
                  let messageContent;

                  if (messageArray.length === 1) {
                    messageContent = <div className="at-message">{messageArray[0]}</div>;
                  } else {
                    messageContent = (
                      <div className="at-message">
                        <span>{messageArray[0]}</span>
                        <div className="expand-icon">
                          <FontAwesome name="info-circle" className="fa-small" />
                        </div>
                      </div>
                    );
                  }

                  return messageContent;
                }}
                dataAlign="center"
                dataSort={false}
                width="500"
              >
                <span className="at-th">Message</span>
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStoreToProps = store => ({
  userLogsList: store.userLogs.userLogsList,
  userLogsPage: store.userLogs.userLogsPage,
  userLogsTotal: store.userLogs.userLogsTotal,
  isFetchingUserLogs: store.userLogs.isFetchingUserLogs,
  userLogActivePage: store.ui.userLogActivePage,
});

const mapDispatchToProps = dispatch => ({
  fetchUserLogs: bindActionCreators(fetchUserLogs, dispatch),
  setUserLogsPage: bindActionCreators(setUserLogsPage, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(AdminChangeLog);
