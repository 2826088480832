const validate = values => {
  const errors = [];
  if (values.name === '') {
    errors.name = 'Please enter a vacation type name';
  }
  if (values.timesheet_label === '') {
    errors.timesheet_label = 'Please enter a timesheet label';
  }
  if (values.value === '') {
    errors.value = 'Please enter a number of days';
  }
  if (values.value === '') {
    errors.value = 'Please enter a number of days';
  }
  const dayDecimal = values.value - Math.floor(values.value);
  if (dayDecimal > 0 && dayDecimal !== 0.5) {
    errors.value = 'The number of days has the following format: "1" or "1.5"';
  }
  if (values.color_code.hex === '' || values.color_code.hex.toUpperCase() === '#FFFFFF') {
    errors.color_code = 'Please select a calendar color';
  }

  return errors;
};

export default validate;
