import React, { Component } from 'react';
import { Button, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchTeamDetails } from '../../../../redux/actions/actualTeamActions';
import CustomLoader from '../../../../components/ui/Loader';
import Avatar from '../../../../components/ui/Avatar';
import './teamItem.scss';

class TeamItem extends Component {
  componentDidMount() {
    const { teamId, fetchTeamDetails } = this.props;
    fetchTeamDetails(teamId);
  }

  renderTeamUsers = () => {
    const { teamDetails: team } = this.props;
    if (team.users && team.users.length) {
      return team.users.map(user => (
        <Avatar key={user.id} title={user.name} avatar={user.avatar} />
      ));
    }
    return null;
  };

  renderTeamDetails = () => {
    const { teamDetails: team, edit } = this.props;
    return (
      <Col md={{ size: 4 }} className="away-team-item-card">
        <div className="team-card card-shadow team-grid">
          <Button
            type="button"
            className="btn btn-default edit-team-btn"
            onClick={() => edit(team)}
          >
            <i className="fa fa-edit" />
          </Button>
          <div className="away-card-pairs">
            Team name: <span>{team.name}</span>
          </div>
          <div className="away-card-pairs">
            Members: <span>{team.users && team.users.length}</span>
          </div>
          <div className="away-team-members-container">{this.renderTeamUsers()}</div>
        </div>
      </Col>
    );
  };

  render() {
    const { isFetching } = this.props;

    if (isFetching) return <CustomLoader />;

    return this.renderTeamDetails();
  }
}

const mapStoreToProps = (store, ownProps) => ({
  isFetching: store.actualTeams.teamDetails[ownProps.teamId].isFetching,
  teamDetails: store.actualTeams.teamDetails[ownProps.teamId],
});

const mapDispatchToProps = dispatch => ({
  fetchTeamDetails: bindActionCreators(fetchTeamDetails, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(TeamItem);
