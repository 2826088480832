import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import {
  updateTeam,
  deleteTeam,
  addUsersToTeam,
  removeUsersFromTeam,
} from '../../../../redux/actions/actualTeamActions';
import { TextInput, Select2 } from '../../../../components/form';
import ConfirmModal from '../../../../components/modals/ConfirmModal';

class EditTeamForm extends Component {
  state = {
    deleteConfirmationIsOpen: false,
  };

  toggleDeleteConfirmation = () => {
    this.setState({ deleteConfirmationIsOpen: !this.state.deleteConfirmationIsOpen });
  };

  handleDeleteTeam = () => {
    const { team, deleteTeam, toggleModal } = this.props;
    deleteTeam(team.id);
    toggleModal();
  };

  handleSubmitClicked(values) {
    const { toggleModal, team, updateTeam, addUsersToTeam, removeUsersFromTeam } = this.props;
    const teamMembers = team.users.map(item => ({ value: item.id, label: item.name }));
    const usersToInsert = _.differenceWith(values.users, teamMembers, _.isEqual).map(item => ({
      id: item.value,
    }));

    const usersToRemove = _.differenceWith(teamMembers, values.users, _.isEqual).map(item => ({
      id: item.value,
    }));

    if (values.name !== team.name) {
      updateTeam(team.id, { name: values.name });
    }

    if (usersToInsert.length !== 0) {
      addUsersToTeam(team.id, { users: usersToInsert });
    }

    if (usersToRemove.length !== 0) {
      removeUsersFromTeam(team.id, { users: usersToRemove });
    }

    toggleModal();
  }

  render() {
    const { deleteConfirmationIsOpen } = this.state;
    const { allUsers, toggleModal, handleSubmit } = this.props;
    const allUsersList = allUsers.map(user => {
      return {
        value: user.id,
        label: user.name,
      };
    });

    return (
      <>
        <Field label="Team Name" name="name" component={TextInput} />
        <Field
          className="away-dropdown-form version-2"
          id="teamMembers"
          label="Team members"
          name="users"
          component={Select2}
          placeholder="Select team members"
          classNamePrefix="away"
          isSearchable={true}
          isClearable={true}
          isMulti
          openMenuOnClick={false}
          onChange={this.handleSelect}
          options={allUsersList}
        />
        <Button
          className="btn-link btn-grey"
          onClick={this.toggleDeleteConfirmation}
          style={{ color: 'red' }}
        >
          <i className="fa fa-trash danger" /> Delete Team
        </Button>
        <div className="away-modal-buttons">
          <Button
            type="submit"
            className="btn btn-primary"
            onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
          >
            Save Changes
          </Button>
          <Button onClick={toggleModal} className="btn-link btn-grey">
            Cancel
          </Button>
        </div>
        <ConfirmModal
          isOpen={deleteConfirmationIsOpen}
          toggle={this.toggleDeleteConfirmation}
          onConfirm={this.handleDeleteTeam}
          header="Are you sure you want to delete this team?"
        />
      </>
    );
  }
}

const mapStoreToProps = store => ({
  allUsers: store.team.allUsers,
});

const mapDispatchToProps = dispatch => ({
  updateTeam: bindActionCreators(updateTeam, dispatch),
  deleteTeam: bindActionCreators(deleteTeam, dispatch),
  addUsersToTeam: bindActionCreators(addUsersToTeam, dispatch),
  removeUsersFromTeam: bindActionCreators(removeUsersFromTeam, dispatch),
});

const EditTeam = reduxForm({ form: 'EditTeamForm', enableReinitialize: true })(EditTeamForm);

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(EditTeam);
