import React from 'react';
import CustomLoader from '../Loader';
import { titleBasedAvatarColor, colorArray } from '../../../utils/Color';

const AvatarColor = ({ size, email, avatar, title, isChangingAvatar, children }) => {
  const avatarColorHere = !!title
    ? titleBasedAvatarColor(title)
    : colorArray[Math.floor(Math.random() * colorArray.length)];

  const avatarContent = isChangingAvatar ? (
    <div className={`avatar avatar-${size}`} title={title}>
      <CustomLoader />
    </div>
  ) : (
    <div
      className={`avatar avatar-${size} ${!avatar ? `avatar-text avatar-${avatarColorHere}` : ''}`}
      title={title}
    >
      {children}
    </div>
  );

  return <div>{avatarContent}</div>;
};

export default AvatarColor;
