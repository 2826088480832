import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Select2 } from '../../../../../../components/form';
import { generateTimesheetsReport } from '../../../../../../redux/actions/reportsActions';

import validate from './validations';
import '../../../../reportsModal.scss';

class TimesheetsReportModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleSubmitClicked(data) {
    const requestData = {
      blocked: data.blocked.value,
      studio_id: data.studio_id.value,
      year: data.year.value,
      month: data.month.value,
    };
    const reportName = `TimesheetReport_${data.studio_id.label}_${data.month.label}_${
      data.year.value
    }`;

    this.props.generateTimesheetsReport(requestData, reportName);
    this.props.toggleModal();
    this.props.reset();
  }

  handleCloseModal() {
    this.props.toggleModal();
    this.props.reset();
  }

  render() {
    const {
      showModal,
      handleSubmit,
      studios,
      availableYearStatistics,
      availableMonthStatistics,
    } = this.props;

    const availableUserStatuses = [
      {
        label: 'All Users',
        value: -1,
      },
      {
        label: 'Active Users',
        value: 0,
      },
      {
        label: 'Inactive Users',
        value: 1,
      },
    ];

    return (
      <Modal className="away-modal" isOpen={showModal} toggle={this.handleCloseModal}>
        <ModalBody className="away-modal-body">
          <Fragment>
            <div className="away-modal-line">
              <h3>Timesheets</h3>
            </div>

            <Field
              className="away-dropdown-form version-2"
              id="studio"
              label="Studio"
              name="studio_id"
              component={Select2}
              placeholder="Select a studio"
              classNamePrefix="away"
              options={[
                {
                  label: 'All Studios',
                  value: 0,
                },
                ...studios,
              ]}
            />

            <Field
              className="away-dropdown-form version-2"
              id="blocked"
              label="Status"
              name="blocked"
              component={Select2}
              placeholder="Select a status"
              classNamePrefix="away"
              options={[...availableUserStatuses]}
            />

            <Field
              className="away-dropdown-form version-2"
              id="year"
              label="Year"
              name="year"
              component={Select2}
              placeholder="Select a year"
              classNamePrefix="away"
              options={availableYearStatistics}
            />

            <Field
              className="away-dropdown-form version-2"
              id="month"
              label="Month"
              name="month"
              component={Select2}
              placeholder="Select a month"
              classNamePrefix="away"
              options={availableMonthStatistics}
            />

            <div className="away-modal-buttons">
              <Button onClick={handleSubmit(this.handleSubmitClicked)} className="btn btn-primary">
                Generate
              </Button>
              <Button onClick={this.handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
    );
  }
}

const TimesheetsReportModalForm = reduxForm({
  validate,
  form: 'TimesheetsReportModalForm',
  enableReinitialize: true,
})(TimesheetsReportModal);

const mapStoreToProps = store => {
  return {
    availableYearStatistics: store.constants.availableYearStatistics,
    availableMonthStatistics: store.constants.availableMonthStatistics,
  };
};

const mapDispatchToProps = dispatch => ({
  generateTimesheetsReport: bindActionCreators(generateTimesheetsReport, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(TimesheetsReportModalForm);
