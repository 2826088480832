import React from 'react';
import { Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

const VacationTypesListItem = ({ vacationData, onEdit, onDelete }) => {
  const editBtn = (
    <span className="edit-icon" onClick={() => onEdit(vacationData)}>
      <FontAwesome name="edit" className="fa-small" />
    </span>
  );

  /* const deleteBtn = (
    <span
      className={`delete-icon ${!!vacationData.custom ? '' : 'not-allowed'}`}
      onClick={() => (!!vacationData.custom ? onDelete(vacationData) : {})}
    >
      <FontAwesome
        name="trash"
        className="fa-small"
      />
    </span>
  ); */

  return (
    <div className="list-item">
      <Row className="li-row">
        <Col md={{ size: 4 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Name</span>
        </Col>
        <Col md={{ size: 4 }} className="li-cell">
          {vacationData.name}
        </Col>
        <Col md={{ size: 4 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Days</span>
        </Col>
        <Col md={{ size: 3 }} className="li-cell">
          {vacationData.value}
        </Col>
        <Col md={{ size: 4 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Calendar color</span>
        </Col>
        <Col md={{ size: 3 }} className="li-cell">
          <div className="li-color" style={{ backgroundColor: vacationData.color_code }} />
        </Col>
        <Col md={{ size: 2 }} className="li-cell li-cell-end">
          <div className="li-action-buttons">
            {editBtn}
            {
              //TODO: uncomment when in version 2 and api ready
              /*deleteBtn*/
            }
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default VacationTypesListItem;
