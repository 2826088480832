const validate = values => {
  const errors = [];
  if (!values.reason || (values.reason && values.reason.trim() === '')) {
    errors.reason = 'Please provide a reject reason';
  }

  return errors;
};

export default validate;
