import React, { Component } from 'react';
import { Button, Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

class TooltipItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false,
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      tooltipOpen: !prevState.tooltipOpen,
    }));
  }

  render() {
    const { button, tooltip, id } = this.props;
    return (
      <span>
        <Button className="me-1" color="primary" id={'Tooltip-' + id} onClick={button.action}>
          {button.text}
        </Button>
        <Tooltip
          placement={tooltip.placement}
          isOpen={this.state.tooltipOpen}
          target={'Tooltip-' + id}
          toggle={this.toggle}
        >
          {tooltip.text}
        </Tooltip>
      </span>
    );
  }
}

TooltipItem.propTypes = {
  id: PropTypes.string,
  button: PropTypes.object,
  tooltip: PropTypes.object,
};

export default TooltipItem;
