import {
  FETCH_COMPANY_DATA_START,
  FETCH_COMPANY_DATA_SUCCESS,
  FETCH_COMPANY_DATA_ERROR,
} from './types';
import { getData, baseURL } from './api';

export const fetchCompanyData = params => dispatch => {
  dispatch({ type: FETCH_COMPANY_DATA_START });

  return getData(
    `${baseURL}/api/settings/company`,
    params,
    FETCH_COMPANY_DATA_SUCCESS,
    FETCH_COMPANY_DATA_ERROR,
    dispatch,
  );
};
