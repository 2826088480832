import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'reactstrap';
import CustomLoader from '../../../../components/ui/Loader';

import { fetchCompanyData } from '../../../../redux/actions/companySettingsActions';

class CompanySettings extends Component {
  componentDidMount() {
    const { fetchCompanyData } = this.props;
    fetchCompanyData();
  }

  render() {
    const { companyData, isFetching } = this.props;

    if (isFetching) {
      return <CustomLoader />;
    }

    return (
      <div className="info-container">
        <Row>
          <Col md={{ size: 12 }}>
            <div className="info-label">Company name</div>
            <div className="info-text">{companyData.name}</div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <div className="info-label">Company url</div>
            <div className="info-text">{companyData.url}</div>
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <div className="info-label">Company API KEY</div>
            <div className="info-text">{companyData.api_key}</div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  companyData: store.companySettings.companyData,
  isFetching: store.companySettings.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchCompanyData: bindActionCreators(fetchCompanyData, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(CompanySettings);
