import {
  USER_CONTACTS_REPORT_START,
  USER_CONTACTS_REPORT_SUCCESS,
  USER_CONTACTS_REPORT_ERROR,
  TIMESHEETS_REPORT_START,
  TIMESHEETS_REPORT_SUCCESS,
  TIMESHEETS_REPORT_ERROR,
  VACATIONS_REPORT_START,
  VACATIONS_REPORT_ERROR,
  VACATIONS_REPORT_SUCCESS,
  WFH_REPORT_START,
  WFH_REPORT_SUCCESS,
  WFH_REPORT_ERROR,
} from '../actions/types';

import { baseURL, downloadReport } from './api';

export const generateVacationsReport = (params, reportName) => dispatch => {
  dispatch({ type: VACATIONS_REPORT_START });
  return downloadReport(
    `${baseURL}api/reports/vacations/`,
    params,
    reportName,
    VACATIONS_REPORT_SUCCESS,
    VACATIONS_REPORT_ERROR,
    dispatch,
  );
};

export const generateTimesheetsReport = (params, reportName) => dispatch => {
  dispatch({ type: TIMESHEETS_REPORT_START });
  return downloadReport(
    `${baseURL}api/reports/timesheet`,
    params,
    reportName,
    TIMESHEETS_REPORT_SUCCESS,
    TIMESHEETS_REPORT_ERROR,
    dispatch,
  );
};

export const generateRemainingVacationsReport = params => () => {
  const reportName = 'REMAINING-VACATIONS-REPORT';
  return downloadReport(`${baseURL}api/reports/vacations/remaining`, params, reportName);
};

export const generateUserContactsReport = params => dispatch => {
  const reportName = 'USER-CONTACTS-REPORT';
  dispatch({ type: USER_CONTACTS_REPORT_START });
  return downloadReport(
    `${baseURL}api/reports/contacts`,
    { ...params, studio_id: params.studio_id.value },
    reportName,
    USER_CONTACTS_REPORT_SUCCESS,
    USER_CONTACTS_REPORT_ERROR,
    dispatch,
  );
};

export const generateWFHReport = params => dispatch => {
  const reportName = 'WORKING-FROM-HOME-REPORT';
  dispatch({ type: WFH_REPORT_START });
  return downloadReport(
    `${baseURL}api/reports/working-location/1/`,
    params,
    reportName,
    WFH_REPORT_SUCCESS,
    WFH_REPORT_ERROR,
    dispatch,
  );
};
