import React from 'react';

const ApproveIcon = () => (
  <svg className="svg-approve" width="31px" height="31px" viewBox="0 0 31 31" version="1.1">
    <defs />
    <g id="chorizo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="09.-Requests_Admin" transform="translate(-1148.000000, -352.000000)">
        <g id="Group-3" transform="translate(1121.000000, 310.000000)">
          <g id="Group-2-Copy" transform="translate(27.000000, 42.000000)">
            <rect
              id="Rectangle-7-Copy-2"
              stroke="#f1f3f7"
              strokeWidth="1"
              fill="none"
              x="0"
              y="0"
              width="31"
              height="31"
              rx="15.5"
            />
            <polyline
              id="Path-4"
              stroke="#9ccc65"
              strokeWidth="2"
              points="11 14.9607585 15.1372816 19.2966307 21.9048409 12"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ApproveIcon;
