import React from 'react';

const BackArrowIcon = () => (
  <svg width="14px" height="15px" viewBox="0 0 14 15" version="1.1">
    <title>back arrow</title>
    <g id="away-back" stroke="none" fill="none">
      <g
        id="05.-Profile"
        transform="translate(-200.000000, -123.000000)"
        stroke="#FF8A5D"
        strokeWidth="2.5"
      >
        <g id="back-arrow" transform="translate(201.000000, 125.000000)">
          <path
            d="M0.823664131,0.0360383354 L5.94436113,5.37655304 C6.018677,5.4540591 6.01852783,5.57641834 5.94402318,5.65374296 L0.823664131,10.967906"
            id="Path-2"
            transform="translate(3.411832, 5.501972) scale(-1, 1) translate(-3.411832, -5.501972) "
          />
          <path d="M2.5,5.5 L11.5,5.5" id="Line-3" />
        </g>
      </g>
    </g>
  </svg>
);

export default BackArrowIcon;
