const validate = values => {
  const errors = [];
  if (!values.vacationType) {
    errors.vacationType = 'Please select a vacation type';
  }
  if (values.inLieuCheckbox && !values.inLieu) {
    errors.inLieu = 'Please select in lieu date';
  }

  return errors;
};

export default validate;
