import {
  FETCH_TEAM_LIST_START,
  FETCH_TEAM_LIST_SUCCESS,
  FETCH_TEAM_LIST_ERROR,
  FETCH_TEAM_MEMBER_START,
  FETCH_TEAM_MEMBER_SUCCESS,
  FETCH_TEAM_MEMBER_ERROR,
  FETCH_TEAM_USERS_START,
  FETCH_TEAM_USERS_SUCCESS,
  FETCH_TEAM_USERS_ERROR,
  FETCH_TEAM_LEADS_SUCCESS,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  list: [],
  listPage: 1,
  listTotal: 1,
  member: {},
  allUsers: [],
  teamLeads: [],
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_TEAM_LIST_START:
    case FETCH_TEAM_USERS_START:
      return { ...state, isFetching: true };
    case FETCH_TEAM_MEMBER_START:
      return { ...state, isFetching: true, member: {} };
    case FETCH_TEAM_LIST_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        listPage: action.payload.page,
        listTotal: action.payload.total,
        isFetching: false,
      };
    case FETCH_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        member: action.payload.user,
        isFetching: false,
      };
    case FETCH_TEAM_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
        isFetching: false,
      };
    case FETCH_TEAM_LEADS_SUCCESS:
      return {
        ...state,
        teamLeads: action.payload,
      };
    case FETCH_TEAM_LIST_ERROR:
    case FETCH_TEAM_MEMBER_ERROR:
    case FETCH_TEAM_USERS_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};
