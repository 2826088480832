import {
  FETCH_PUBLIC_HOLIDAYS_SUCCESS,
  FETCH_PUBLIC_HOLIDAYS_ERROR,
  FETCH_PUBLIC_HOLIDAYS_START,
  CHECK_IS_PUBLIC_HOLIDAY_REQUEST_START,
  CHECK_IS_PUBLIC_HOLIDAY_REQUEST_SUCCESS,
  CHECK_IS_PUBLIC_HOLIDAY_REQUEST_ERROR,
  HOLIDAY_WORK_REQUEST_START,
  HOLIDAY_WORK_REQUEST_SUCCESS,
  HOLIDAY_WORK_REQUEST_ERROR,
  HOLIDAY_WORK_REQUEST_APPROVE_START,
  HOLIDAY_WORK_REQUEST_APPROVE_SUCCESS,
  HOLIDAY_WORK_REQUEST_APPROVE_ERROR,
  HOLIDAY_WORK_REQUEST_REJECT_START,
  HOLIDAY_WORK_REQUEST_REJECT_SUCCESS,
  HOLIDAY_WORK_REQUEST_REJECT_ERROR,
  HOLIDAY_WORK_REQUEST_DELETE_START,
  HOLIDAY_WORK_REQUEST_DELETE_SUCCESS,
  HOLIDAY_WORK_REQUEST_DELETE_ERROR,
  FETCH_ALL_PUBLIC_HOLIDAYS_START,
  FETCH_ALL_PUBLIC_HOLIDAYS_SUCCESS,
  FETCH_ALL_PUBLIC_HOLIDAYS_ERROR,
  FETCH_PUBLIC_HOLIDAYS_YEAR_SUCCESS,
} from './types';
import { getData, postData, deleteData, baseURL } from './api';
import { setGeneralAlertMessage } from '../actions/uiActions';
import { fetchTeamVacations } from '../actions/teamVacationsActions';
import { fetchSignedInUserVacations } from './signedInUserVacationsActions';
import { fetchSignedInUser } from './signedInUserActions';

export const fetchPublicHolidays = year => dispatch => {
  dispatch({ type: FETCH_PUBLIC_HOLIDAYS_START });
  return getData(
    `${baseURL}api/publicHolidays/${year}`,
    {},
    FETCH_PUBLIC_HOLIDAYS_SUCCESS,
    FETCH_PUBLIC_HOLIDAYS_ERROR,
    dispatch,
    () => {
      dispatch({ type: FETCH_PUBLIC_HOLIDAYS_YEAR_SUCCESS, payload: { year } });
    },
  );
};

export const fetchExtraPublicHolidays = year => dispatch => {
  dispatch({ type: FETCH_ALL_PUBLIC_HOLIDAYS_START });
  return getData(
    `${baseURL}api/publicHolidays/extra/${year}`,
    {},
    FETCH_ALL_PUBLIC_HOLIDAYS_SUCCESS,
    FETCH_ALL_PUBLIC_HOLIDAYS_ERROR,
    dispatch,
  );
};

export const checkWorkOnHolidayRequest = (
  date,
  vacationRequestPresentCallback,
  vacationRequestAbsentCallback,
) => dispatch => {
  dispatch({ type: CHECK_IS_PUBLIC_HOLIDAY_REQUEST_START });

  return getData(
    `${baseURL}api/publicHolidays/check/${date}`,
    {},
    CHECK_IS_PUBLIC_HOLIDAY_REQUEST_SUCCESS,
    CHECK_IS_PUBLIC_HOLIDAY_REQUEST_ERROR,
    dispatch,
    response => {
      if (response.name) {
        // if the holiday exists for the logged in user, based on his country & state,
        // toggle the modal that allows them to opt to work during that day
        vacationRequestPresentCallback(response);
      } else {
        // otherwise, just show the normal request vacation modal
        vacationRequestAbsentCallback();
      }
    },
  );
};

export const submitWorkOnHolidayRequest = requestData => dispatch => {
  dispatch({ type: HOLIDAY_WORK_REQUEST_START });

  postData(
    `${baseURL}api/publicHolidays/workOnHoliday`,
    requestData,
    HOLIDAY_WORK_REQUEST_SUCCESS,
    HOLIDAY_WORK_REQUEST_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Work on holiday request added', 'info'));
    },
    () => {
      dispatch(setGeneralAlertMessage('There is already a request in that period', 'info'));
    },
  );
};

export const approveWorkOnHoliday = (requestId, userName, requestType) => dispatch => {
  dispatch({ type: HOLIDAY_WORK_REQUEST_APPROVE_START });

  postData(
    `${baseURL}api/publicHolidays/workOnHoliday/approve/${requestId}`,
    HOLIDAY_WORK_REQUEST_APPROVE_SUCCESS,
    HOLIDAY_WORK_REQUEST_APPROVE_ERROR,
    dispatch,
    () => {
      dispatch(fetchTeamVacations({ status: requestType }));
      dispatch(
        setGeneralAlertMessage(`${userName}'s work on holiday request has been approved`, 'info'),
      );
    },
  );
};

export const rejectWorkOnHoliday = (requestId, userName, requestType) => dispatch => {
  dispatch({ type: HOLIDAY_WORK_REQUEST_REJECT_START });

  postData(
    `${baseURL}api/publicHolidays/workOnHoliday/reject/${requestId}`,
    HOLIDAY_WORK_REQUEST_REJECT_SUCCESS,
    HOLIDAY_WORK_REQUEST_REJECT_ERROR,
    dispatch,
    () => {
      dispatch(fetchTeamVacations({ status: requestType }));
      dispatch(
        setGeneralAlertMessage(`${userName}'s work on holiday request has been rejected`, 'info'),
      );
    },
  );
};

export const deleteWorkOnHoliday = (
  requestId,
  currentPage,
  requestType,
  userName = '',
) => dispatch => {
  dispatch({ type: HOLIDAY_WORK_REQUEST_DELETE_START });

  deleteData(
    `${baseURL}api/publicHolidays/workOnHoliday/${requestId}`,
    HOLIDAY_WORK_REQUEST_DELETE_SUCCESS,
    HOLIDAY_WORK_REQUEST_DELETE_ERROR,
    dispatch,
    () => {
      switch (currentPage) {
        case 'history':
          dispatch(fetchSignedInUserVacations(requestType));
          dispatch(fetchSignedInUser());
          dispatch(setGeneralAlertMessage('Work on holiday request has been deleted', 'info'));
          break;
        case 'approvedRequests':
          dispatch(fetchTeamVacations({ status: requestType }));
          dispatch(
            setGeneralAlertMessage(
              `${userName}'s work on holiday request has been deleted`,
              'info',
            ),
          );
          break;
        default: {
        }
      }
    },
  );
};
