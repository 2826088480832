import React from 'react';

const DeleteIcon = () => (
  <svg className="svg-delete" width="31px" height="31px" viewBox="0 0 31 31" version="1.1">
    <defs />
    <g id="chorizo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="09.-Requests_Admin" transform="translate(-1189.000000, -353.000000)">
        <g id="Group" transform="translate(1189.000000, 353.000000)">
          <rect
            id="Rectangle-7-Copy-3"
            stroke="#f1f3f7"
            x="0.5"
            y="0.5"
            width="30"
            height="30"
            rx="15"
          />
          <path d="M12,20.0184752 L19.5259008,12" id="Path-4" stroke="#FF5252" strokeWidth="2" />
          <path
            d="M12,20.0184752 L19.5259008,12"
            id="Path-4"
            stroke="#FF5252"
            strokeWidth="2"
            transform="translate(15.762950, 16.009238) scale(-1, 1) translate(-15.762950, -16.009238) "
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DeleteIcon;
