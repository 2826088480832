import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import { Row, Col } from 'reactstrap';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import FontAwesome from 'react-fontawesome';
import CustomLoader from '../../../../components/ui/Loader';

import { setUserVacationsHistoryPage } from '../../../../redux/actions/uiActions';
import { fetchUserVacationHistory } from '../../../../redux/actions/userLogsActions';
import { deleteRequest } from '../../../../redux/actions/signedInUserVacationsActions';

class AdminVacationsHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: props.userVacationsHistoryActivePage || 1,
      vacationId: 0,
      showDeleteConfirmModal: false,
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggleDeleteConfirmModal = this.toggleDeleteConfirmModal.bind(this);
  }

  componentDidMount() {
    const { fetchUserVacationHistory, userId, userVacationsHistoryActivePage } = this.props;

    fetchUserVacationHistory(userId, {
      page: userVacationsHistoryActivePage,
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    const { userId, fetchUserVacationHistory, setUserVacationsHistoryPage } = this.props;
    setUserVacationsHistoryPage(pageNumber);

    fetchUserVacationHistory(userId, {
      page: pageNumber,
    });
  }

  handleDeleteClick(e, rowInfo) {
    e.stopPropagation();
    this.setState(
      {
        vacationId: rowInfo.id,
      },
      this.toggleDeleteConfirmModal,
    );
  }

  toggleDeleteConfirmModal() {
    this.setState({
      showDeleteConfirmModal: !this.state.showDeleteConfirmModal,
    });
  }

  onConfirmDelete = () => {
    const { userId } = this.props;
    const { vacationId } = this.state;
    this.toggleDeleteConfirmModal();
    this.props.deleteRequest(vacationId, 'admin', 'all', userId);
    this.setState({
      activePage: 1,
      vacationId: 0,
    });
  };

  render() {
    const {
      userVacationsHistoryList,
      userVacationsHistoryTotal,
      requestActionInProgress,
      isFetchingVacationHistory,
    } = this.props;

    const options = {
      page: this.state.activePage, // which page you want to show as default
      sizePerPage: 10, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      onPageChange: this.handlePageChange,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true,
      noDataText:
        !isFetchingVacationHistory && userVacationsHistoryList.length === 0
          ? 'No records found'
          : 'Loading...',
      hideSizePerPage: true,
    };

    if (requestActionInProgress) return <CustomLoader />;

    return (
      <Fragment>
        <Row>
          <Col md={{ size: 12 }}>
            <h3 className="aic-subtitle">{`Vacations History`}</h3>
            <div className="away-table-b">
              <BootstrapTable
                data={userVacationsHistoryList}
                hover
                remote={true}
                pagination={true}
                fetchInfo={{ dataTotalSize: userVacationsHistoryTotal }}
                options={options}
                trClassName="at-row"
                tableHeaderClass="at-header"
                bootstrap4
              >
                <TableHeaderColumn
                  isKey={true}
                  dataField="id"
                  dataFormat={(cell, row) => {
                    return <span>{row.id}</span>;
                  }}
                  dataAlign="center"
                  dataSort={false}
                  hidden
                />
                <TableHeaderColumn
                  dataField="period"
                  dataFormat={(cell, row) => {
                    const period = `${row.start_date} / ${row.end_date}`;
                    return <span className="at-date">{period}</span>;
                  }}
                  dataAlign="center"
                  dataSort={false}
                  width="200"
                >
                  <span className="at-th">Period</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="workingDays"
                  dataFormat={(cell, row) => {
                    const workingDays = row.work_days;
                    return <span>{workingDays}</span>;
                  }}
                  dataAlign="center"
                  dataSort={false}
                  width="200"
                >
                  <span className="at-th">Working days</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="vacationType"
                  dataFormat={(cell, row) => {
                    const vacationType = row.vacation_type;
                    return <span className="at-cap">{vacationType}</span>;
                  }}
                  dataAlign="center"
                  dataSort={false}
                  width="200"
                >
                  <span className="at-th">Vacation type</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="status"
                  dataFormat={(cell, row) => {
                    const status = row.status;
                    return <span>{status}</span>;
                  }}
                  dataAlign="center"
                  dataSort={false}
                  width="200"
                >
                  <span className="at-th">Status</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="action"
                  dataFormat={(cell, row) => {
                    return (
                      <span className="delete-icon" onClick={e => this.handleDeleteClick(e, row)}>
                        <FontAwesome name="trash" className="fa-small" />
                      </span>
                    );
                  }}
                  dataAlign="center"
                  dataSort={false}
                  width="65"
                />
              </BootstrapTable>
            </div>
          </Col>
          <div
            className="xxx"
            ref={el => {
              this.el = el;
            }}
          />
        </Row>
        <ConfirmModal
          isOpen={this.state.showDeleteConfirmModal}
          toggle={this.toggleDeleteConfirmModal}
          header="Delete vacation request"
          bodyText="Are you sure?"
          onConfirm={this.onConfirmDelete}
        />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  userVacationsHistoryList: store.userLogs.userVacationsHistoryList,
  userVacationsHistoryPage: store.userLogs.userVacationsHistoryPage,
  userVacationsHistoryTotal: store.userLogs.userVacationsHistoryTotal,
  isFetchingVacationHistory: store.userLogs.isFetchingVacationHistory,
  userVacationsHistoryActivePage: store.ui.userVacationsHistoryActivePage,
  requestActionInProgress: store.signedInUserVacations.actionRequest.isFetching,
  isAlertVisible: store.ui.generalAlert.visible,
  alertMessage: store.ui.generalAlert.message,
  alertType: store.ui.generalAlert.alertType,
});

const mapDispatchToProps = dispatch => ({
  setUserVacationsHistoryPage: bindActionCreators(setUserVacationsHistoryPage, dispatch),
  fetchUserVacationHistory: bindActionCreators(fetchUserVacationHistory, dispatch),
  deleteRequest: bindActionCreators(deleteRequest, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(AdminVacationsHistory);
