import React from 'react';
import { Row, Col } from 'reactstrap';
import Moment from 'moment';

import './UpcomingHolidaysCard.scss';

const UpcomingHolidaysCard = ({ upcomingPublicHolidays }) => {
  const upcomingHolidays =
    upcomingPublicHolidays &&
    upcomingPublicHolidays.map((holiday, i) => (
      <Row key={i}>
        <Col md="5" sm="5" xs="5">
          <span>
            {Moment(holiday.start_date).format('D MMM')}
            {holiday.start_date !== holiday.end_date &&
              ', ' + Moment(holiday.end_date).format('D MMM')}
          </span>
        </Col>
        <Col md="7" sm="7" xs="7">
          <span>{holiday.name}</span>
        </Col>
      </Row>
    ));
  return (
    <div className="card-shadow upcoming-holidays">
      {upcomingPublicHolidays.length ? (
        <div>
          <Row className="top-row">
            <Col md="12">
              <h3>Upcoming Holidays</h3>
            </Col>
          </Row>
          <Row>
            <Col md="12">{upcomingHolidays}</Col>
          </Row>
        </div>
      ) : (
        <Row className="top-row">
          <Col md="12">
            <h3>No upcoming holidays</h3>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default UpcomingHolidaysCard;
