import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Collapse, Button } from 'reactstrap';
import { TextInput } from '../../../components/form';
import { createTeam } from '../../../redux/actions/actualTeamActions';

class CreateTeamForm extends Component {
  state = {
    isCollapseOpen: false,
  };

  toggleCollapse = () => {
    this.setState({ isCollapseOpen: !this.state.isCollapseOpen });
  };

  handleSubmitClicked(formValues) {
    const { createTeam } = this.props;
    createTeam(formValues);
    this.toggleCollapse();
  }

  render() {
    const { isCollapseOpen } = this.state;
    const { handleSubmit } = this.props;

    return (
      <div className={`create-team col-md-6 ${isCollapseOpen ? 'add-shadow' : ''}`}>
        <Collapse isOpen={isCollapseOpen}>
          <Field
            onFocus={this.onFocus}
            name="name"
            type="text"
            label="Team Name"
            component={TextInput}
          />
        </Collapse>
        <Button
          className={`btn m-3 ${isCollapseOpen ? 'create-team-btn' : ''} ${
            false ? 'd-none' : 'block'
          }`}
          type="submit"
          onClick={
            isCollapseOpen ? handleSubmit(this.handleSubmitClicked.bind(this)) : this.toggleCollapse
          }
        >
          <i className="fa fa-users" /> Create team
        </Button>
        {isCollapseOpen ? (
          <Button className="btn m-3" onClick={() => this.toggleCollapse()}>
            Cancel
          </Button>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  createTeam: bindActionCreators(createTeam, dispatch),
});

const CreateTeam = reduxForm({
  form: 'CreateTeamForm',
})(CreateTeamForm);

export default connect(
  null,
  mapDispatchToProps,
)(CreateTeam);
