import React, { Component, Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, ModalBody, Row, Col, Container } from 'reactstrap';
import { TextInput } from '../../form';

class UserStatsModal extends Component {
  handleSubmitClicked(data) {
    const { updateMemberStats, year, memberId } = this.props;
    const payload = {
      year: Number(year),
      vacationDefaults: Object.entries(data).map(([prop, val]) => ({
        id: Number(prop.replace('type_', '')),
        value: Number(val),
      })),
    };
    updateMemberStats(memberId, payload);
    this.handleCloseModal();
  }

  handleCloseModal() {
    this.props.toggleModal();
  }

  renderVacationDefaultField = vacationType => {
    const { id, name } = vacationType;
    return (
      <Field
        id={name}
        labelClassName="text-capitalize"
        name={`type_${id}`}
        label={name}
        component={TextInput}
      />
    );
  };

  splitFieldsIn2Cols = () => {
    const { defaultVacationTypes } = this.props;
    const noOfFields = defaultVacationTypes.length;
    if (noOfFields > 0) {
      const halfIndex = Math.floor(noOfFields / 2);
      const col1 = defaultVacationTypes.slice(0, halfIndex);
      const col2 = defaultVacationTypes.slice(halfIndex, noOfFields);
      return [col1, col2];
    }
    return null;
  };

  render() {
    const { handleSubmit, showModal, memberName, year, pristine } = this.props;
    const fieldColumns = this.splitFieldsIn2Cols();

    return (
      <Modal
        size="lg"
        className="away-modal"
        isOpen={showModal}
        toggle={this.handleCloseModal.bind(this)}
      >
        <ModalBody className="away-modal-body">
          <div className="away-modal-line">
            <h3>
              Edit {memberName}
              's Vacation Defaults ({year})
            </h3>
          </div>
          <Container>
            <Row>
              {fieldColumns &&
                fieldColumns.length !== 0 &&
                fieldColumns.map((col, i) => (
                  <Col key={i}>
                    {col.map((vacationType, i) => (
                      <Fragment key={i}>{this.renderVacationDefaultField(vacationType)}</Fragment>
                    ))}
                  </Col>
                ))}
            </Row>
            <Row className="justify-content-center">
              <div className="away-modal-buttons">
                <Button
                  disabled={pristine}
                  onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
                  className="btn btn-primary"
                >
                  Update stats
                </Button>
                <Button onClick={this.handleCloseModal.bind(this)} className="btn-link btn-grey">
                  Cancel
                </Button>
              </div>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

const UserStatsForm = reduxForm({
  form: 'UserStatsForm',
  enableReinitialize: true,
})(UserStatsModal);

export default UserStatsForm;
