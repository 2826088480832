import React, { Fragment } from 'react';
import { Label, Input, FormGroup, Col, Row, FormText } from 'reactstrap';
import DatePicker from '../ui/DatePicker';
import { formatDate } from '../../utils/Date';

const renderDatePicker = ({
  input,
  id,
  label,
  showDatePicker,
  togglePicker,
  showMonthsYears,
  disabled,
  maxDate,
  meta: { touched, error, active },
}) => {
  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup>
            <Label className="active" for="date">
              {label ? label : 'From date'}
            </Label>
            <Input
              readOnly
              disabled={disabled}
              className={showDatePicker ? 'force-focus' : ''}
              type="text"
              value={formatDate(input.value, 'MM/DD/YYYY')}
            />
            {touched && error ? <FormText color="danger">{error}</FormText> : null}
          </FormGroup>
        </Col>
      </Row>
      <DatePicker
        id={id}
        {...input}
        showMonthsYears={!!showMonthsYears}
        handleSelect={input.onChange}
        showDatePicker={showDatePicker}
        togglePicker={togglePicker}
        maxDate={maxDate}
      />
    </Fragment>
  );
};

export default renderDatePicker;
