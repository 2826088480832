import {
  FETCH_TEAMS_START,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_ERROR,
  FETCH_TEAM_DETAILS_START,
  FETCH_TEAM_DETAILS_SUCCESS,
  FETCH_TEAM_DETAILS_ERROR,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  list: [],
  teamDetails: null,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_TEAMS_START:
      return { ...state, isFetching: true };
    case FETCH_TEAM_DETAILS_START:
      return {
        ...state,
      };
    case FETCH_TEAMS_SUCCESS:
      let teamDetails = null;
      if (action.payload.data && action.payload.data.length) {
        teamDetails = action.payload.data.reduce((acc, team) => {
          acc[team.id] = { isFetching: true };
          return acc;
        }, {});
      }
      return {
        ...state,
        isFetching: false,
        list: action.payload.data,
        teamDetails,
      };
    case FETCH_TEAM_DETAILS_SUCCESS:
      return {
        ...state,
        teamDetails: {
          ...state.teamDetails,
          [action.payload.data.id]: {
            isFetching: false,
            ...action.payload.data,
          },
        },
      };
    case FETCH_TEAMS_ERROR:
      return { ...state, isFetching: false, error: action.payload };
    case FETCH_TEAM_DETAILS_ERROR:
      return {
        ...state,
        teamDetails: {
          ...state.teamDetails,
          [action.payload.data.id]: {
            isFetching: false,
          },
        },
      };
    default:
      return state;
  }
};
