import React from 'react';
import { Row, Col } from 'reactstrap';
import StudioListItem from '../StudioListItem';

const StudiosList = ({ studiosList, onEdit, onDelete, onCreate }) => {
  const studios =
    studiosList &&
    studiosList.length &&
    studiosList.map((studio, i) => (
      <StudioListItem key={i} studioData={studio} onEdit={onEdit} onDelete={onDelete} />
    ));

  return (
    <div className="away-list">
      <div className="list-title-section">
        <h3 className="list-title">Studios</h3>
        <button className="btn btn-primary btn-small" onClick={onCreate}>
          Add
        </button>
      </div>
      <Row>
        <Col md={{ size: 12 }}>
          <div className="list-header">
            <Row>
              <Col md={{ size: 4 }} className="list-cell">
                <span className="list-cell-text">Studio name</span>
              </Col>
              <Col md={{ size: 3 }} className="list-cell">
                <span className="list-cell-text">Studio partner</span>
              </Col>
              <Col md={{ size: 3 }} className="list-cell">
                <span className="list-cell-text">Company name</span>
              </Col>
              <Col md={{ size: 2 }} className="list-cell" />
            </Row>
          </div>
        </Col>
      </Row>
      {studios}
    </div>
  );
};

export default StudiosList;
