import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import Avatar from '../../../../components/ui/Avatar';
import FontAwesome from 'react-fontawesome';
import { formatDate } from '../../../../utils/Date';
import AdminChangeLog from '../AdminChangeLog';
import AdminVacationsHistory from '../AdminVacationsHistory';
import UserProfileModal from '../../../../components/modals/UserProfileModal';
import UserStatsModal from '../../../../components/modals/UserStatsModal';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import AvatarModal from '../../../../components/modals/AvatarModal';
import CarryOverModal from '../../../../components/modals/CarryOverModal';
import { tshirt_sizes } from '../../../../constants';

import { deleteTeamAdminMemberAvatar } from '../../../../redux/actions/teamAdminActions';

import './adminUserProfileCard.scss';

class AdminUserProfileCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carryOverModalOpen: false,
      userProfileModalOpen: false,
      userStatsModalOpen: false,
      showAvatarModal: false,
      user_id: 0,
    };

    this.toggleAvatarModal = this.toggleAvatarModal.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.toggleUserProfileModal = this.toggleUserProfileModal.bind(this);
    this.toggleUserStatsModal = this.toggleUserStatsModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleCarryOverModal = this.toggleCarryOverModal.bind(this);
  }

  toggleCarryOverModal() {
    this.setState({ carryOverModalOpen: !this.state.carryOverModalOpen });
  }

  toggleUserProfileModal() {
    this.setState({ userProfileModalOpen: !this.state.userProfileModalOpen });
  }

  toggleUserStatsModal() {
    this.setState({ userStatsModalOpen: !this.state.userStatsModalOpen });
  }

  handleSubmitEditProfile = data => {
    const { updateTeamAdminMemberProfile } = this.props;
    const payload = {
      ...data,
      date_of_birth: formatDate(data.date_of_birth, 'YYYY-MM-DD'),
      company_joined_at: formatDate(data.company_joined_at, 'YYYY-MM-DD'),
      blocked: data.blocked ? 1 : 0,
      nda: data.nda ? 1 : 0,
      us_visa: data.us_visa ? 1 : 0,
      working_location: data.working_location ? 1 : 0,
    };
    updateTeamAdminMemberProfile(payload);
  };

  handleSubmitCarryOver = (memberId, data) => {
    const { updateTeamAdminMemberCarryOver, fetchTeamAdminMember, userData } = this.props;
    const { id, statsYear } = userData;
    updateTeamAdminMemberCarryOver(memberId, data, () => fetchTeamAdminMember(id, statsYear));
  };

  handleSubmitEditStats = (memberId, data) => {
    const { updateTeamAdminMemberStats, fetchTeamAdminMember, userData } = this.props;
    const { id, statsYear } = userData;
    // refetch stats after updating defaults
    updateTeamAdminMemberStats(memberId, data, () => fetchTeamAdminMember(id, statsYear));
  };

  toggleAvatarModal = () => {
    this.setState({
      showAvatarModal: !this.state.showAvatarModal,
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  handleDelete = user_id => {
    this.setState({
      ...this.state,
      user_id,
    });
    this.toggleDeleteModal();
  };

  onConfirmDelete = () => {
    this.toggleDeleteModal();
    this.props.deleteTeamAdminMemberAvatar(this.state.user_id);
  };

  render() {
    const {
      userData,
      teamAdminMemberHeaders,
      teamAdminMemberVacationDefaults,
      isChangingAvatar,
    } = this.props;
    const prevYear = Number(userData.statsYear) - 1;
    const nextYear = Number(userData.statsYear) + 1;
    const statistics = teamAdminMemberHeaders.map(header => {
      const takenProp = `taken_${header.id}`;
      const totalProp = `total_${header.id}`;

      const vacationTypeStats =
        header.type === 'loyality'
          ? `${userData[totalProp]}`
          : `${userData[takenProp]} / ${userData[totalProp]}`;

      return (
        <Col key={takenProp} md={{ size: 3 }}>
          <div className="info-label">
            <span className="info-label-title">{header.name}</span>
            {header.type !== 'loyality' && <span> (Taken / Total)</span>}
          </div>
          <div className="info-text">{vacationTypeStats}</div>
        </Col>
      );
    });

    return (
      <div className="away-info-card">
        <div className="card-shadow">
          <Row>
            <Col md={{ size: 4 }}>
              <Row className="top-row no-margin">
                <Col md="4">
                  <Avatar
                    size="xl"
                    title={userData.name}
                    avatar={userData.avatar}
                    userId={userData.id}
                    isEditable
                    onEdit={this.toggleAvatarModal}
                    onDelete={this.handleDelete}
                    isChangingAvatar={isChangingAvatar}
                  />
                </Col>
                <Col md="8">
                  <h3>{userData.name}</h3>
                  <p>{userData.job}</p>
                  {userData.studioLabel && (
                    <span className="location">
                      <FontAwesome name="map-marker" className="fa-small" />
                      {userData.studioLabel}
                    </span>
                  )}
                </Col>
              </Row>
              <Row className="top-row">
                <Col md="12">
                  <Button className="btn-link" onClick={this.toggleUserProfileModal}>
                    Edit Profile
                  </Button>
                </Col>
                <Col md="12">
                  <Button className="btn-link" onClick={this.toggleUserStatsModal}>
                    Edit Vacation Settings
                  </Button>
                </Col>
              </Row>
              {userData.shortDescription && (
                <Row className="desc-row">
                  <Col md="7">
                    <p>{userData.shortDescription}</p>
                  </Col>
                </Row>
              )}
            </Col>
            <Col md={{ size: 8 }}>
              <Row className="row-space">
                {userData.email && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">E-mail</div>
                    <div className="info-text">{userData.email}</div>
                  </Col>
                )}
                {userData.company_joined_at && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">Hiring Date</div>
                    <div className="info-text">
                      {formatDate(userData.company_joined_at, 'MMM DD, YYYY')}
                    </div>
                  </Col>
                )}
                {userData.skype_id && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">Skype ID</div>
                    <div className="info-text">{userData.skype_id}</div>
                  </Col>
                )}
                {userData.studioLabel && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">Studio</div>
                    <div className="info-text">{userData.studioLabel}</div>
                  </Col>
                )}
                {userData.phone_number && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">Phone Number</div>
                    <div className="info-text">{userData.phone_number}</div>
                  </Col>
                )}
                {userData.teamLead && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">Approver</div>
                    <div className="info-text">{userData.teamLead.name}</div>
                  </Col>
                )}
                {userData.date_of_birth && (
                  <Col md={{ size: 6 }}>
                    <div className="info-label">Date of Birth</div>
                    <div className="info-text">
                      {formatDate(userData.date_of_birth, 'MMM DD, YYYY')}
                    </div>
                  </Col>
                )}
                <Col md={{ size: 6 }}>
                  <div className="info-label">T-shirt size</div>
                  <div className="info-text">
                    {userData.tshirt_size
                      ? tshirt_sizes.find(size => size.value === userData.tshirt_size).label
                      : 'Not specified'}
                  </div>
                </Col>
                <Col md={{ size: 6 }}>
                  <div className="info-label">US Visa</div>
                  <div className="info-text">{userData.us_visa ? 'Yes' : 'No'}</div>
                </Col>
                <Col md={{ size: 6 }}>
                  <div className="info-label">NDA Signed</div>
                  <div className="info-text">{userData.nda ? 'Yes' : 'No'}</div>
                </Col>
                <Col md={{ size: 6 }}>
                  <div className="info-label">Location</div>
                  <div className="info-text">
                    {userData.city_name}, {userData.state_name}, {userData.country_name}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="aic-divider" />
          <Row>
            <Col md={{ size: 12 }}>
              <Row>
                <Link
                  className="btn btn-link stats-link"
                  to={`/team-admin/member/${userData.id}/${prevYear}`}
                >
                  ❮
                </Link>
                <h3 className="aic-subtitle">{`${userData.statsYear}'s Statistics`}</h3>
                <Link
                  className="btn btn-link stats-link"
                  to={`/team-admin/member/${userData.id}/${nextYear}`}
                >
                  ❯
                </Link>
              </Row>
              <Row>
                <Col md={{ size: 3 }}>
                  <div className="info-label">
                    <span className="info-label-title">Overall Days Available</span>
                  </div>
                  <div className="info-text">{`${userData.free_all}`}</div>
                </Col>

                {statistics}
              </Row>
              <button onClick={this.toggleCarryOverModal} className="btn btn-primary btn-small">
                Carry Over
              </button>
            </Col>
          </Row>
          {userData.id && (
            <Fragment>
              <div className="aic-divider" />
              <AdminChangeLog userId={userData.id} />
              <div className="aic-divider" />
              <AdminVacationsHistory userId={userData.id} />
            </Fragment>
          )}
        </div>
        <CarryOverModal
          showModal={this.state.carryOverModalOpen}
          toggleModal={this.toggleCarryOverModal.bind(this)}
          updateMemberCarryOver={this.handleSubmitCarryOver}
          memberId={userData.id}
          initialValues={{
            carryover_from: userData.statsYear,
            carryover_to: Number(userData.statsYear) + 1,
            carryover_days: userData.carryover_all,
          }}
        />
        <UserProfileModal
          form="AdminEditProfileForm"
          showModal={this.state.userProfileModalOpen}
          updateUserProfile={this.handleSubmitEditProfile}
          toggleModal={this.toggleUserProfileModal.bind(this)}
          initialValues={{
            id: userData.id,
            email: userData.email,
            company_id: 1,
            company_joined_at: new Date(userData.company_joined_at),
            studio_id: userData.studio_id
              ? { label: userData.studioLabel, value: userData.studio_id }
              : null,
            role: userData.role ? { label: userData.role_label, value: userData.role } : null,
            team_lead:
              userData.teamLead &&
              userData.teamLead.id &&
              typeof userData.teamLead.id === 'number' &&
              userData.teamLead.id > 0
                ? {
                    label: userData.teamLead.name,
                    value: userData.teamLead.id,
                  }
                : null,
            blocked: userData.blocked === 0 ? false : true,
            name: userData.name,
            phone_number: userData.phone_number,
            job: userData.job,
            contract: userData.contract
              ? { label: userData.contract, value: userData.contract }
              : null,
            skype_id: userData.skype_id,
            date_of_birth: new Date(userData.date_of_birth),
            working_location: userData.working_location === 1,
            nda: userData.nda === 0 ? false : true,
            us_visa: userData.us_visa === 0 ? false : true,
            tshirt_size: userData.tshirt_size,
            country_id: userData.country_id
              ? { label: userData.country_name, value: userData.country_id }
              : null,
            state_id: userData.state_id
              ? { label: userData.state_name, value: userData.state_id }
              : null,
            city_id: userData.city_id
              ? { label: userData.city_name, value: userData.city_id }
              : null,
          }}
          isAdmin={true}
        />
        <UserStatsModal
          showModal={this.state.userStatsModalOpen}
          toggleModal={this.toggleUserStatsModal.bind(this)}
          updateMemberStats={this.handleSubmitEditStats}
          memberName={userData.name}
          memberId={userData.id}
          year={userData.statsYear}
          defaultVacationTypes={teamAdminMemberVacationDefaults}
          initialValues={teamAdminMemberVacationDefaults.reduce((accumulator, defaultSetting) => {
            const { id, value } = defaultSetting;
            accumulator[`type_${id}`] = value;
            return { ...accumulator };
          }, {})}
        />
        <AvatarModal
          showModal={this.state.showAvatarModal}
          toggleModal={this.toggleAvatarModal}
          initialValues={{
            user_id: userData.id,
            avatarImg: userData.avatar,
          }}
        />
        <ConfirmModal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
          header="Delete avatar"
          bodyText="Are you sure?"
          onConfirm={this.onConfirmDelete}
        />
      </div>
    );
  }
}

AdminUserProfileCard.propTypes = {
  userData: PropTypes.object,
  updateTeamAdminMemberProfile: PropTypes.func,
  updateTeamAdminMemberStats: PropTypes.func,
  updateTeamAdminMemberCarryOver: PropTypes.func,
};

const mapStoreToProps = store => ({
  isChangingAvatar: store.teamAdmin.isChangingAvatar,
});

const mapDispatchToProps = dispatch => ({
  deleteTeamAdminMemberAvatar: bindActionCreators(deleteTeamAdminMemberAvatar, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(AdminUserProfileCard);
