import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import UserContactsReportModal from './components/UserContactsReportModal';
import CustomLoader from '../../../../components/ui/Loader';

import ReportIcon from '../../../../assets/Report.svg';

class UserContactsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showUserContactsReportModal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal = () => {
    this.setState({
      showUserContactsReportModal: !this.state.showUserContactsReportModal,
    });
  };

  render() {
    const { showUserContactsReportModal } = this.state;

    const reportIcon = (
      <div className="report-icon">
        <img alt="Report" src={ReportIcon} />
      </div>
    );

    return (
      <div className="report-card">
        <div className="card-shadow">
          {this.props.generatingReport ? (
            <CustomLoader />
          ) : (
            <Fragment>
              <h3 className="report-title">User Contacts</h3>
              <div>
                {reportIcon}
                <Button className="btn btn-primary" onClick={this.toggleModal}>
                  Generate
                </Button>
              </div>
            </Fragment>
          )}
        </div>

        <UserContactsReportModal
          showModal={showUserContactsReportModal}
          toggleModal={this.toggleModal}
          studios={this.props.studios}
          initialValues={{
            phone_number: false,
            skype_id: false,
            date_of_birth: false,
            role: false,
            company_joined_at: false,
            country_id: false,
            state_id: false,
            city_id: false,
            studio_id: {
              label: 'All Studios',
              value: 0,
            },
          }}
        />
      </div>
    );
  }
}

export default UserContactsReport;
