import {
  FETCH_TEAM_LIST_START,
  FETCH_TEAM_LIST_SUCCESS,
  FETCH_TEAM_LIST_ERROR,
  FETCH_TEAM_MEMBER_START,
  FETCH_TEAM_MEMBER_SUCCESS,
  FETCH_TEAM_MEMBER_ERROR,
  FETCH_TEAM_LEADS_START,
  FETCH_TEAM_LEADS_SUCCESS,
  FETCH_TEAM_LEADS_ERROR,
} from './types';
import { getData, baseURL } from './api';
import { PAGINATION_REQUESTS_PER_PAGE } from '../../constants/index';

export const fetchTeamList = params => dispatch => {
  dispatch({ type: FETCH_TEAM_LIST_START });

  if (params.role_slug === 'all') delete params.role_slug;
  if (params.studio_id === 0) delete params.studio_id;
  if (params.team_id === 0) delete params.team_id;
  if (!params.count) params.count = PAGINATION_REQUESTS_PER_PAGE;

  return getData(
    `${baseURL}api/user/list`,
    params,
    FETCH_TEAM_LIST_SUCCESS,
    FETCH_TEAM_LIST_ERROR,
    dispatch,
  );
};

export const fetchTeamMember = memberId => dispatch => {
  dispatch({ type: FETCH_TEAM_MEMBER_START });

  const params = {};

  return getData(
    `${baseURL}api/user/profile/${memberId}`,
    params,
    FETCH_TEAM_MEMBER_SUCCESS,
    FETCH_TEAM_MEMBER_ERROR,
    dispatch,
  );
};

export const fetchTeamLeads = params => dispatch => {
  dispatch({ type: FETCH_TEAM_LEADS_START });

  return getData(
    `${baseURL}api/team/members`,
    params,
    FETCH_TEAM_LEADS_SUCCESS,
    FETCH_TEAM_LEADS_ERROR,
    dispatch,
  );
};
