const validate = values => {
  const errors = [];

  if (!values.password) {
    errors.password = 'Please enter your new password';
  } else if (values.password.length < 8) {
    errors.password = 'New password must have at least 8 characters';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Please confirm your new password';
  } else if (values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords must match';
  }
  return errors;
};

export default validate;
