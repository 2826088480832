import {
  FETCH_TEAMS_START,
  FETCH_TEAMS_SUCCESS,
  FETCH_TEAMS_ERROR,
  FETCH_TEAM_DETAILS_START,
  FETCH_TEAM_DETAILS_SUCCESS,
  FETCH_TEAM_DETAILS_ERROR,
  CREATE_TEAM_START,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_ERROR,
  UPDATE_TEAM_START,
  UPDATE_TEAM_SUCCESS,
  UPDATE_TEAM_ERROR,
  ADD_USERS_TO_TEAM_START,
  ADD_USERS_TO_TEAM_SUCCESS,
  ADD_USERS_TO_TEAM_ERROR,
  REMOVE_USERS_FROM_TEAM_START,
  REMOVE_USERS_FROM_TEAM_SUCCESS,
  REMOVE_USERS_FROM_TEAM_ERROR,
  DELETE_TEAM_START,
  DELETE_TEAM_SUCCESS,
  DELETE_TEAM_ERROR,
} from './types';
import { setGeneralAlertMessage } from './uiActions';
import { fetchConstants } from './authActions';
import { getData, postData, deleteData, putData, baseURL } from './api';

export const fetchTeams = () => dispatch => {
  dispatch({ type: FETCH_TEAMS_START });
  const params = {};

  return getData(
    `${baseURL}api/settings/teams`,
    params,
    FETCH_TEAMS_SUCCESS,
    FETCH_TEAMS_ERROR,
    dispatch,
    () => {
      dispatch(fetchConstants());
    },
  );
};

export const fetchTeamDetails = teamId => dispatch => {
  dispatch({ type: FETCH_TEAM_DETAILS_START });

  const params = {};

  return getData(
    `${baseURL}api/settings/team/${teamId}`,
    params,
    FETCH_TEAM_DETAILS_SUCCESS,
    FETCH_TEAM_DETAILS_ERROR,
    dispatch,
  );
};

export const createTeam = payload => dispatch => {
  dispatch({ type: CREATE_TEAM_START });

  return postData(
    `${baseURL}api/settings/team`,
    payload,
    CREATE_TEAM_SUCCESS,
    CREATE_TEAM_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Team created successfully!', 'info'));
      dispatch(fetchTeams());
    },
  );
};

export const updateTeam = (teamId, payload) => dispatch => {
  dispatch({ type: UPDATE_TEAM_START });

  return putData(
    `${baseURL}api/settings/team/${teamId}`,
    payload,
    UPDATE_TEAM_SUCCESS,
    UPDATE_TEAM_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Team edited successfully!', 'info'));
      dispatch(fetchTeams());
    },
  );
};

export const deleteTeam = teamId => dispatch => {
  dispatch({ type: DELETE_TEAM_START });

  return deleteData(
    `${baseURL}api/settings/team/${teamId}`,
    DELETE_TEAM_SUCCESS,
    DELETE_TEAM_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Team deleted successfully!', 'info'));
      dispatch(fetchTeams());
    },
  );
};

export const addUsersToTeam = (teamId, payload) => dispatch => {
  dispatch({ type: ADD_USERS_TO_TEAM_START });

  return postData(
    `${baseURL}api/settings/team/${teamId}/add-users`,
    payload,
    ADD_USERS_TO_TEAM_SUCCESS,
    ADD_USERS_TO_TEAM_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Team edited successfully!', 'info'));
      dispatch(fetchTeams());
    },
  );
};

export const removeUsersFromTeam = (teamId, payload) => dispatch => {
  dispatch({ type: REMOVE_USERS_FROM_TEAM_START });

  return postData(
    `${baseURL}api/settings/team/${teamId}/remove-users`,
    payload,
    REMOVE_USERS_FROM_TEAM_SUCCESS,
    REMOVE_USERS_FROM_TEAM_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Team edited successfully!', 'info'));
      dispatch(fetchTeams());
    },
  );
};
