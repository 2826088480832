import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink, withRouter } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Collapse, Navbar, Alert } from 'reactstrap';
import Avatar from '../Avatar';
import Logout from '../Logout';
import ChangePasswordModal from '../../modals/ChangePasswordModal';
import logo from '../../../assets/logo.svg';
import { updateSignedInUserPassword } from '../../../redux/actions/signedInUserActions';
import { toggleGeneralAlert } from '../../../redux/actions/uiActions';
import './navbar.scss';
import './hamburgerMenu.scss';

class NavbarAway extends Component {
  constructor(props) {
    super(props);

    this.toggleChangePasswordModal = this.toggleChangePasswordModal.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggle = this.toggle.bind(this);

    this.state = {
      changePasswordModalOpen: false,
      dropdownOpen: false,
      isOpen: false,
      activeMenu: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      activeMenu: !this.state.activeMenu,
    });
  }

  toggleDropdown() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  componentDidMount() {
    //dismiss alert modal
    this.dismissAlertModal();
  }

  toggleChangePasswordModal() {
    this.setState({ changePasswordModalOpen: !this.state.changePasswordModalOpen });
  }

  dismissAlertModal = () => {
    this.props.toggleGeneralAlert(false);
  };

  render() {
    const { user, userRights, isAlertVisible, alertMessage, alertType } = this.props;
    return (
      <Fragment>
        <div className="away-navbar">
          <Navbar className="navbar navbar-expand-lg navbar-light">
            <div className="container">
              <NavLink
                exact
                to="/"
                className="logo navbar-brand d-block d-sm-block d-md-block d-lg-none"
              >
                <img src={logo} alt="Logo" />
              </NavLink>
              <button
                onClick={this.toggle}
                className={
                  this.state.activeMenu
                    ? 'hamburger hamburger--collapse is-active'
                    : 'hamburger hamburger--collapse'
                }
                type="button"
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
              <Collapse isOpen={this.state.isOpen} navbar>
                <NavLink exact to="/" className="logo navbar-brand d-none d-lg-block d-md-none">
                  <img src={logo} alt="Logo" />
                </NavLink>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink exact to="/" className="nav-link">
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/people" className="nav-link">
                      People
                    </NavLink>
                  </li>
                  {(userRights.includes('hasApproverRights') ||
                    userRights.includes('hasManagementRights') ||
                    userRights.includes('hasStudioManagementRights')) && (
                    <li className="nav-item">
                      <NavLink to="/teams" className="nav-link">
                        Teams
                      </NavLink>
                    </li>
                  )}
                  {userRights.includes('hasApproverRights') && (
                    <li className="nav-item">
                      <NavLink to="/vacation-requests" className="nav-link">
                        Requests
                      </NavLink>
                    </li>
                  )}
                  <li className="nav-item">
                    <NavLink to="/history" className="nav-link">
                      My History
                    </NavLink>
                  </li>
                </ul>
                <div className="away-nav-dropdown">
                  <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle caret>
                      <Avatar size="xs" title={user.name} avatar={user.avatar} isSignedInUser />
                      <span>{user.name}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <div className="dropdown-item">
                        <NavLink to="/profile" className="nav-link">
                          Profile
                        </NavLink>
                      </div>
                      {(userRights.includes('hasManagementRights') ||
                        userRights.includes('hasStudioManagementRights')) && (
                        <div className="dropdown-item">
                          <NavLink to="/team-admin" className="nav-link">
                            Team Admin
                          </NavLink>
                        </div>
                      )}
                      {(userRights.includes('hasManagementRights') ||
                        userRights.includes('hasStudioManagementRights')) && (
                        <div>
                          <div className="dropdown-item">
                            <NavLink to="/settings" className="nav-link">
                              Settings
                            </NavLink>
                          </div>
                          <div className="dropdown-item">
                            <NavLink to="/reports" className="nav-link">
                              Reports
                            </NavLink>
                          </div>
                        </div>
                      )}
                      <div className="dropdown-item" onClick={this.toggleChangePasswordModal}>
                        <button className="btn btn-link">Change password</button>
                      </div>
                      <div className="dropdown-item">
                        <Logout />
                      </div>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </Collapse>
            </div>
          </Navbar>
        </div>
        <div className="alert-wrapper">
          <Alert color={alertType} isOpen={isAlertVisible} toggle={this.dismissAlertModal}>
            {alertMessage}
          </Alert>
        </div>
        <ChangePasswordModal
          toggleModal={this.toggleChangePasswordModal}
          showModal={this.state.changePasswordModalOpen}
          updateSignedInUserPassword={this.props.updateSignedInUserPassword}
          userId={user.id}
        />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  userRights: store.signedInUser.userRights,
  user: store.signedInUser.user,
  isAlertVisible: store.ui.generalAlert.visible,
  alertMessage: store.ui.generalAlert.message,
  alertType: store.ui.generalAlert.alertType,
});

const mapDispatchToProps = dispatch => ({
  updateSignedInUserPassword: bindActionCreators(updateSignedInUserPassword, dispatch),
  toggleGeneralAlert: bindActionCreators(toggleGeneralAlert, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(withRouter(NavbarAway));
