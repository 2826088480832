import React from 'react';
import Select from 'react-select';
import { FormGroup, FormText, Label } from 'reactstrap';
import cx from 'classnames';

const renderSelect2Input = ({
  input: { value, onChange, onBlur },
  meta: { touched, error, active },
  label,
  labelClassName,
  id,
  name,
  options,
  ...custom
}) => {
  const labelActive = active || value || value === 0 || value !== '';
  const labelClass = labelActive ? cx(labelClassName, 'active') : labelClassName;

  return (
    <FormGroup>
      {value ? (
        <Label className={labelClass} for={id}>
          {label}
        </Label>
      ) : null}
      <Select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={e => {
          if (Array.isArray(value)) {
            onBlur(value);
          } else {
            e.preventDefault();
            onBlur(value);
          }
        }}
        blurInputOnSelect={false}
        options={options}
        {...custom}
      />
      {touched && error ? (
        <FormText style={{ marginTop: -35 }} color="danger">
          {error}
        </FormText>
      ) : null}
    </FormGroup>
  );
};

export default renderSelect2Input;
