import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from '../redux/store';
import AuthInit from '../components/AuthInit';
import Routes from './Routes';
import '../styles/theme.scss';

const App = () => (
  <Provider store={store}>
    <AuthInit>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AuthInit>
  </Provider>
);

export default App;
