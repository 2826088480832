import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody, Container } from 'reactstrap';
import { TextInput } from '../../form';
import AvatarEditorFieldComponent from './components/AvatarEditorFieldComponent';
import { updateAvatar } from '../../../redux/actions/signedInUserActions';
import { updateTeamAdminMemberAvatar } from '../../../redux/actions/teamAdminActions';

import { resetAvatarModalForm } from '../../../redux/actions/uiActions';

import validate from './validations';

import './avatarModal.scss';

class AvatarModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleSubmitClicked(data) {
    const { updateAvatar, updateTeamAdminMemberAvatar, isSignedInUser } = this.props;

    const block = data.avatarImg.split(';');
    // Get the content type of the image
    const contentType = block[0].split(':')[1];
    // get the real base64 content of the file
    const realData = block[1].split(',')[1];
    // get content encoding
    const contentEncoding = block[1].split(',')[0];

    const requestData = {
      avatar: realData,
      contentEncoding,
      contentType,
    };

    if (isSignedInUser) {
      updateAvatar(requestData, data.user_id);
    } else {
      updateTeamAdminMemberAvatar(requestData, data.user_id);
    }
    this.props.toggleModal();
  }

  handleCloseModal() {
    this.props.toggleModal();
    this.props.resetAvatarModalForm();
  }

  render() {
    const { showModal, handleSubmit } = this.props;

    return (
      <Modal size="lg" className="away-modal" isOpen={showModal} toggle={this.handleCloseModal}>
        <ModalBody className="away-modal-body">
          <Container>
            <div className="away-modal-line">
              <h3>Update Avatar</h3>
            </div>

            <Field id="avatarImg" name="avatarImg" component={AvatarEditorFieldComponent} />

            <Field
              id="user_id"
              name="user_id"
              label="User Id"
              type="hidden"
              component={TextInput}
            />

            <div className="away-modal-buttons">
              <Button onClick={handleSubmit(this.handleSubmitClicked)} className="btn btn-primary">
                Submit
              </Button>
              <Button onClick={this.handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

const selector = formValueSelector('AvatarModalForm');
const AvatarModalForm = reduxForm({
  validate,
  form: 'AvatarModalForm',
  enableReinitialize: true,
})(AvatarModal);

const mapStoreToProps = (store, ownProps) => {
  return {
    user_id: selector(store, 'user_id'),
    avatarImg: selector(store, 'avatarImg'),
    initialValues: { ...ownProps.initialValues },
  };
};

const mapDispatchToProps = dispatch => ({
  updateAvatar: bindActionCreators(updateAvatar, dispatch),
  updateTeamAdminMemberAvatar: bindActionCreators(updateTeamAdminMemberAvatar, dispatch),
  resetAvatarModalForm: bindActionCreators(resetAvatarModalForm, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(AvatarModalForm);
