import {
  FETCH_VACATION_TYPES_LIST_START,
  FETCH_VACATION_TYPES_LIST_SUCCESS,
  FETCH_VACATION_TYPES_LIST_ERROR,
  ADD_VACATION_TYPE_START,
  ADD_VACATION_TYPE_SUCCESS,
  ADD_VACATION_TYPE_ERROR,
  UPDATE_VACATION_TYPE_START,
  UPDATE_VACATION_TYPE_SUCCESS,
  UPDATE_VACATION_TYPE_ERROR,
  DELETE_VACATION_TYPE_START,
  DELETE_VACATION_TYPE_SUCCESS,
  DELETE_VACATION_TYPE_ERROR,
  REMOVE_VACATION_TYPE,
  CLEAN_REQUEST_ERRORS,
  POPULATE_USERS_VACATION_DEFAULTS_START,
  POPULATE_USERS_VACATION_DEFAULTS_SUCCESS,
  POPULATE_USERS_VACATION_DEFAULTS_ERROR,
} from './types';
import { getData, postData, putData, deleteData, baseURL } from './api';
import { setGeneralAlertMessage } from '../actions/uiActions';
import { reset } from 'redux-form';

export const fetchVacationTypesList = params => dispatch => {
  dispatch({ type: FETCH_VACATION_TYPES_LIST_START });

  return getData(
    `${baseURL}/api/settings/vacationTypes`,
    params,
    FETCH_VACATION_TYPES_LIST_SUCCESS,
    FETCH_VACATION_TYPES_LIST_ERROR,
    dispatch,
  );
};

export const addVacationType = params => dispatch => {
  dispatch({ type: ADD_VACATION_TYPE_START });
  postData(
    `${baseURL}api/settings/vacationType`,
    params,
    ADD_VACATION_TYPE_SUCCESS,
    ADD_VACATION_TYPE_ERROR,
    dispatch,
    () => {
      dispatch({ type: CLEAN_REQUEST_ERRORS });
      dispatch(setGeneralAlertMessage('New vacation type added', 'info'));
      dispatch(reset('VacationTypeModalForm'));
    },
  );
};

export const updateVacationType = data => dispatch => {
  dispatch({ type: UPDATE_VACATION_TYPE_START });
  return putData(
    `${baseURL}api/settings/vacationType/${data.id}`,
    data,
    UPDATE_VACATION_TYPE_SUCCESS,
    UPDATE_VACATION_TYPE_ERROR,
    dispatch,
    () => {
      dispatch({ type: CLEAN_REQUEST_ERRORS });
      dispatch(setGeneralAlertMessage('Vacation type updated', 'info'));
      dispatch(reset('VacationTypeModalForm'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const deleteVacationType = vacationTypeId => dispatch => {
  dispatch({ type: DELETE_VACATION_TYPE_START, payload: vacationTypeId });
  deleteData(
    `${baseURL}api/settings/vacationType/${vacationTypeId}`,
    DELETE_VACATION_TYPE_SUCCESS,
    DELETE_VACATION_TYPE_ERROR,
    dispatch,
    () => {
      dispatch({ type: REMOVE_VACATION_TYPE, payload: vacationTypeId });
      dispatch(setGeneralAlertMessage('Vacation type deleted', 'info'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const populateUsersDefaults = params => dispatch => {
  dispatch({ type: POPULATE_USERS_VACATION_DEFAULTS_START });

  postData(
    `${baseURL}api/scripts/startYear`,
    params,
    POPULATE_USERS_VACATION_DEFAULTS_SUCCESS,
    POPULATE_USERS_VACATION_DEFAULTS_ERROR,
    dispatch,
    data => {
      dispatch(setGeneralAlertMessage(data));
    },
  );
};
