import React, { Fragment } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, ModalBody } from 'reactstrap';
import Moment from 'moment';
import { TextInput } from '../../form';
import validate from './validations';

const RejectVacationRequestModal = ({
  showModal,
  onSubmit,
  toggleModal,
  handleSubmit,
  pristine,
  vacation,
}) => {
  return (
    <Modal className="away-modal" isOpen={showModal} toggle={toggleModal}>
      <ModalBody className="away-modal-body">
        <Fragment>
          <div className="away-modal-line">
            <h3>Rejection reason</h3>
          </div>
          {vacation && (
            <div className="reject-person-info">
              <p>{vacation.name}</p>
              <p>
                {`${Moment(vacation.startDate).format('D MMM Y')}
                 -
                 ${Moment(vacation.endDate).format('D MMM Y')}`}
              </p>
              <p className="vacation-type">{vacation.vacationType}</p>
            </div>
          )}
          <Field
            id="rejectReason"
            name="reason"
            placeholder="Reason"
            component={TextInput}
            type="textarea"
          />
          <div className="away-modal-buttons">
            <Button
              disabled={pristine}
              onClick={handleSubmit(onSubmit)}
              className="btn btn-primary"
            >
              Reject
            </Button>
            <Button onClick={toggleModal} className="btn-link btn-grey">
              Cancel
            </Button>
          </div>
        </Fragment>
      </ModalBody>
    </Modal>
  );
};

const RejectVacationRequestForm = reduxForm({
  validate,
  form: 'RejectVacationRequestForm',
  enableReinitialize: true,
})(RejectVacationRequestModal);

export default RejectVacationRequestForm;
