import { format } from 'date-fns';

export function formatDate(date, formatString) {
  if (!date) return '';
  return format(date, formatString);
}

export function firstDayOfAMonth(date) {
  const month = date.getMonth(),
    year = date.getFullYear();
  return new Date(year, month, 1);
}

export function firstDayOfTheNextYear(date) {
  const year = date.getFullYear();
  return new Date(year + 1, 0, 1);
}

export function lastDayOfAMonth(date) {
  const month = date.getMonth(),
    year = date.getFullYear();
  return new Date(year, month + 1, 0);
}
