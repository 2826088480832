import {
  FETCH_USER_LOGS_START,
  FETCH_USER_LOGS_SUCCESS,
  FETCH_USER_LOGS_ERROR,
  FETCH_USER_VACATIONS_HISTORY_START,
  FETCH_USER_VACATIONS_HISTORY_SUCCESS,
  FETCH_USER_VACATIONS_HISTORY_ERROR,
} from '../actions/types';

const initialValues = {
  isFetchingUserLogs: false,
  isFetchingVacationHistory: false,
  userLogsList: [],
  userLogsPage: 1,
  userLogsTotal: 1,
  userVacationsHistoryList: [],
  userVacationsHistoryPage: 1,
  userVacationsHistoryTotal: 1,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_USER_LOGS_START:
      return { ...state, isFetchingUserLogs: true };
    case FETCH_USER_VACATIONS_HISTORY_START:
      return { ...state, isFetchingVacationHistory: true };
    case FETCH_USER_LOGS_SUCCESS:
      return {
        ...state,
        userLogsList: action.payload.list,
        userLogsPage: action.payload.page,
        userLogsTotal: action.payload.total,
        isFetchingUserLogs: false,
      };
    case FETCH_USER_VACATIONS_HISTORY_SUCCESS:
      return {
        ...state,
        userVacationsHistoryList: action.payload.list,
        userVacationsHistoryPage: action.payload.page,
        userVacationsHistoryTotal: action.payload.total,
        isFetchingVacationHistory: false,
      };
    case FETCH_USER_LOGS_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetchingUserLogs: false,
      };
    case FETCH_USER_VACATIONS_HISTORY_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetchingVacationHistory: false,
      };
    default:
      return state;
  }
};
