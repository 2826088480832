import React from 'react';
import debounce from 'lodash/debounce';

const withIsMobile = Component => {
  class ResponsiveComponent extends React.Component {
    constructor(...props) {
      super(...props);
      this.state = {
        width: window.innerWidth,
      };
      this.handleWindowSizeChange = debounce(this.handleWindowSizeChange.bind(this), 400);
    }

    componentWillMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange() {
      this.setState({ width: window.innerWidth });
    }

    render() {
      const { width } = this.state;
      const isMobile = width < 768;
      return <Component {...this.props} isMobile={isMobile} />;
    }
  }

  return ResponsiveComponent;
};

export default withIsMobile;
