import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';

const middleware = [thunk];

const createStoreWithMiddleware = composeWithDevTools(
  applyMiddleware(...middleware)
)(createStore);
const store = createStoreWithMiddleware(reducers);

export default store;
