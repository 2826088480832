import {
  FETCH_SIGNEDIN_USER_START,
  FETCH_SIGNEDIN_USER_SUCCESS,
  FETCH_SIGNEDIN_USER_ERROR,
  UPDATE_USER_PROFILE_START,
  UPDATE_USER_PROFILE_SUCCESS,
  UPDATE_USER_PROFILE_ERROR,
  UPDATE_USER_PASSWORD_START,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_ERROR,
  UPDATE_USER_WORKING_LOCATION_START,
  UPDATE_USER_WORKING_LOCATION_SUCCESS,
  UPDATE_USER_WORKING_LOCATION_ERROR,
  UPDATE_AVATAR_START,
  UPDATE_AVATAR_SUCCESS,
  UPDATE_AVATAR_ERROR,
  DELETE_AVATAR_START,
  DELETE_AVATAR_SUCCESS,
  DELETE_AVATAR_ERROR,
  LOGOUT_USER,
  SET_USER_RIGHTS,
} from '../actions/types';

const initialValues = {
  user: {},
  userRights: [],
  userDataFetched: false,
  isChangingAvatar: false,
  isUpdatingWorkingLocation: false,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      return {
        ...state,
        userDataFetched: false,
      };
    case FETCH_SIGNEDIN_USER_START:
    case UPDATE_USER_PROFILE_START:
    case UPDATE_USER_PASSWORD_START:
      return { ...state, isFetching: true };
    case UPDATE_AVATAR_START:
    case DELETE_AVATAR_START:
      return { ...state, isChangingAvatar: true };
    case UPDATE_USER_WORKING_LOCATION_START:
      return { ...state, isUpdatingWorkingLocation: true };
    case SET_USER_RIGHTS: {
      return {
        ...state,
        userRights: action.payload,
        userDataFetched: true,
      };
    }
    case FETCH_SIGNEDIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isFetching: false,
      };
    case UPDATE_USER_PROFILE_ERROR:
    case UPDATE_USER_PASSWORD_ERROR:
    case FETCH_SIGNEDIN_USER_ERROR:
    case UPDATE_AVATAR_ERROR:
    case DELETE_AVATAR_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isChangingAvatar: false,
      };
    case UPDATE_USER_WORKING_LOCATION_ERROR: {
      return { ...state, isUpdatingWorkingLocation: false };
    }
    case UPDATE_AVATAR_SUCCESS:
    case DELETE_AVATAR_SUCCESS:
      const teamMembersList = state.user.teamMembers ? state.user.teamMembers : [];
      const teamMembersUpdatedList = teamMembersList.map(item => {
        if (item.id === action.payload.user.id) {
          item = {
            ...item,
            avatar: action.payload.user.avatar,
          };
        }
        return item;
      });

      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
          teamMembers: teamMembersUpdatedList,
        },
        isChangingAvatar: false,
      };
    case UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
        isFetching: false,
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_USER_WORKING_LOCATION_SUCCESS:
      return {
        ...state,
        isUpdatingWorkingLocation: false,
        user: { ...state.user, ...action.payload.user },
      };
    default:
      return state;
  }
};
