import {
  FETCH_SIGNED_IN_USER_VACATIONS_START,
  FETCH_SIGNED_IN_USER_VACATIONS_SUCCESS,
  FETCH_SIGNED_IN_USER_VACATIONS_ERROR,
  FETCH_USER_AVAILABLE_VACATIONS_START,
  FETCH_USER_AVAILABLE_VACATIONS_SUCCESS,
  FETCH_USER_AVAILABLE_VACATIONS_ERROR,
  VACATION_REQUEST_START,
  VACATION_REQUEST_SUCCESS,
  VACATION_REQUEST_ERROR,
  CLEAN_REQUEST_ERRORS,
  ACTION_VACATION_REQUEST_START,
  ACTION_VACATION_REQUEST_SUCCESS,
  ACTION_VACATION_REQUEST_ERROR,
  EDIT_SIGNED_IN_USER_PENDING_VACATIONS,
  FETCH_VACATION_REQUEST_START,
  RESET_FETCH_REQUEST,
} from '../actions/types';
const initialValues = {
  isFetching: false,
  list: [],
  listPage: 1,
  listTotal: 0,
  availableVacations: {
    vacations: [],
    isFetching: false,
  },
  addRequest: {
    isFetching: false,
    error: '',
  },
  actionRequest: {
    isFetching: false,
    requestId: 0,
  },
  fetchRequest: {
    isFetching: false,
  },
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case CLEAN_REQUEST_ERRORS: {
      return {
        ...state,
        addRequest: { ...state.addRequest, error: '' },
      };
    }
    case FETCH_VACATION_REQUEST_START: {
      return {
        ...state,
        fetchRequest: { isFetching: true },
      };
    }
    case RESET_FETCH_REQUEST: {
      return {
        ...state,
        fetchRequest: { isFetching: false },
      };
    }
    case VACATION_REQUEST_START:
      return {
        ...state,
        addRequest: { isFetching: true },
      };
    case VACATION_REQUEST_SUCCESS:
      return {
        ...state,
        addRequest: { isFetching: false },
      };
    case VACATION_REQUEST_ERROR:
      return {
        ...state,
        addRequest: {
          isFetching: false,
          error: action.payload.response.data.message,
        },
      };
    case ACTION_VACATION_REQUEST_START: {
      return {
        ...state,
        actionRequest: {
          isFetching: true,
          requestId: action.payload,
        },
      };
    }
    case ACTION_VACATION_REQUEST_SUCCESS: {
      return {
        ...state,
        actionRequest: {
          isFetching: false,
        },
        fetchRequest: { isFetching: false },
      };
    }
    case ACTION_VACATION_REQUEST_ERROR: {
      return {
        ...state,
        actionRequest: {
          isFetching: false,
        },
        fetchRequest: { isFetching: false },
      };
    }
    case FETCH_SIGNED_IN_USER_VACATIONS_START:
      return { ...state, isFetching: true };

    case FETCH_SIGNED_IN_USER_VACATIONS_SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        listPage: action.payload.page,
        listTotal: action.payload.total,
        isFetching: false,
      };

    case EDIT_SIGNED_IN_USER_PENDING_VACATIONS: {
      return {
        ...state,
        list: state.list.map(v =>
          v.id === action.payload.data.vacation.id ? action.payload.data.vacation : v,
        ),
      };
    }
    case FETCH_SIGNED_IN_USER_VACATIONS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_USER_AVAILABLE_VACATIONS_START: {
      return {
        ...state,
        availableVacations: { isFetching: true },
      };
    }
    case FETCH_USER_AVAILABLE_VACATIONS_ERROR: {
      return {
        ...state,
        availableVacations: { isFetching: false },
      };
    }
    case FETCH_USER_AVAILABLE_VACATIONS_SUCCESS: {
      const inLieu = action.payload.stats.inLieu
        ? action.payload.stats.inLieu.map(vacation => {
            return {
              value: vacation.id,
              label: `${vacation.name} - ${vacation.work_days} day`,
            };
          })
        : [];
      const vacations = action.payload.stats.vacationsByType.map(vacation => {
        const daysLeft = vacation.daysLeft !== '' ? `(${vacation.daysLeft} days left)` : '';
        return {
          value: vacation.id,
          label: `${vacation.slug} ${daysLeft}`,
        };
      });
      return {
        ...state,
        availableVacations: {
          isFetching: false,
          inLieu,
          vacations,
        },
      };
    }
    default:
      return state;
  }
};
