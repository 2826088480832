import {
  FETCH_VACATION_TYPES_LIST_START,
  FETCH_VACATION_TYPES_LIST_SUCCESS,
  FETCH_VACATION_TYPES_LIST_ERROR,
  ADD_VACATION_TYPE_START,
  ADD_VACATION_TYPE_SUCCESS,
  ADD_VACATION_TYPE_ERROR,
  UPDATE_VACATION_TYPE_START,
  UPDATE_VACATION_TYPE_SUCCESS,
  UPDATE_VACATION_TYPE_ERROR,
  DELETE_VACATION_TYPE_START,
  DELETE_VACATION_TYPE_SUCCESS,
  DELETE_VACATION_TYPE_ERROR,
  REMOVE_VACATION_TYPE,
  CLEAN_REQUEST_ERRORS,
  POPULATE_USERS_VACATION_DEFAULTS_START,
  POPULATE_USERS_VACATION_DEFAULTS_ERROR,
  POPULATE_USERS_VACATION_DEFAULTS_SUCCESS,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  vacationTypesList: [],
  addVacationType: {
    isFetching: false,
  },
  deleteVacationType: {
    isFetching: false,
    vacationTypeId: 0,
  },
  updateVacationType: {
    isFetching: false,
    vacationTypeId: 0,
  },
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case CLEAN_REQUEST_ERRORS: {
      return {
        ...state,
        addVacationType: { ...state.addVacationType, error: '' },
        updateVacationType: { ...state.updateVacationType, error: '' },
      };
    }

    case FETCH_VACATION_TYPES_LIST_START:
      return { ...state, isFetching: true };
    case FETCH_VACATION_TYPES_LIST_SUCCESS:
      return {
        ...state,
        vacationTypesList: action.payload.data,
        isFetching: false,
      };
    case FETCH_VACATION_TYPES_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case ADD_VACATION_TYPE_START:
      return {
        ...state,
        addVacationType: { isFetching: true },
      };
    case ADD_VACATION_TYPE_SUCCESS:
      const vtListToUpdate = state.vacationTypesList;
      const vtUdatedList = [...vtListToUpdate];
      vtUdatedList.unshift(action.payload.data);

      return {
        ...state,
        addVacationType: { isFetching: false },
        vacationTypesList: vtUdatedList,
      };
    case ADD_VACATION_TYPE_ERROR:
      return {
        ...state,
        addVacationType: {
          isFetching: false,
          error: action.payload.response.data.message,
        },
      };
    case DELETE_VACATION_TYPE_START: {
      return {
        ...state,
        deleteVacationType: {
          isFetching: true,
          vacationTypeId: action.payload,
        },
      };
    }
    case DELETE_VACATION_TYPE_SUCCESS: {
      return {
        ...state,
        deleteVacationType: {
          isFetching: false,
        },
      };
    }
    case DELETE_VACATION_TYPE_ERROR: {
      return {
        ...state,
        deleteVacationType: {
          isFetching: false,
        },
      };
    }
    case REMOVE_VACATION_TYPE: {
      const list = state.vacationTypesList.filter(v => v.id !== action.payload);
      return {
        ...state,
        vacationTypesList: list,
      };
    }
    case UPDATE_VACATION_TYPE_START: {
      return {
        ...state,
        updateVacationType: {
          isFetching: true,
          vacationTypeId: action.payload,
        },
      };
    }
    case UPDATE_VACATION_TYPE_SUCCESS: {
      const vtListToUpdate = state.vacationTypesList;
      const vtUpdatedList = vtListToUpdate.map(item => {
        if (item.id === action.payload.data.id) {
          item = action.payload.data;
        }
        return item;
      });

      return {
        ...state,
        updateVacationType: {
          isFetching: false,
        },
        vacationTypesList: vtUpdatedList,
      };
    }
    case UPDATE_VACATION_TYPE_ERROR: {
      return {
        ...state,
        updateVacationType: {
          isFetching: false,
        },
      };
    }
    case POPULATE_USERS_VACATION_DEFAULTS_START:
      return { ...state, isFetching: true };
    case POPULATE_USERS_VACATION_DEFAULTS_SUCCESS:
    case POPULATE_USERS_VACATION_DEFAULTS_ERROR:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
