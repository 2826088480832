import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';

import Header from '../../components/ui/Header';
import VacationsReport from './components/VacationsReport';
import TimesheetsReport from './components/TimesheetsReport';
import UserContactsReport from './components/UserContactsReport';
import WFHReport from './components/WFHReport';

import './reports.scss';

class Reports extends Component {
  render() {
    return (
      <Fragment>
        <Header title="Reports" />
        <div className="reports-row">
          <VacationsReport
            generatingReport={this.props.generatingVacationsReport}
            studios={this.props.studios}
          />
          <TimesheetsReport
            generatingReport={this.props.generatingTimeSheetsReport}
            studios={this.props.studios}
          />
          <UserContactsReport
            generatingReport={this.props.generatingUserContactsReport}
            studios={this.props.studios}
          />
          <WFHReport
            generatingReport={this.props.generatingWFHReport}
            studios={this.props.studios}
          />
        </div>
      </Fragment>
    );
  }
}
const mapStoreToProps = store => ({
  generatingUserContactsReport: store.reports.generatingUserContactsReport,
  generatingTimeSheetsReport: store.reports.generatingTimeSheetsReport,
  generatingVacationsReport: store.reports.generatingVacationsReport,
  generatingWFHReport: store.reports.generatingWFHReport,
  studios: store.constants.studios,
});

export default connect(
  mapStoreToProps,
  null,
)(Reports);
