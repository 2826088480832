import {
  FETCH_DASHBOARD_VACATIONS_START,
  FETCH_DASHBOARD_VACATIONS_SUCCESS,
  FETCH_DASHBOARD_VACATIONS_ERROR,
  FETCH_DASHBOARD_ANNIVERSARIES_START,
  FETCH_DASHBOARD_ANNIVERSARIES_SUCCESS,
  FETCH_DASHBOARD_ANNIVERSARIES_ERROR,
  FETCH_DASHBOARD_WFH_START,
  FETCH_DASHBOARD_WFH_SUCCESS,
  FETCH_DASHBOARD_WFH_ERROR,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  list: [],
  anniversaries: [],
  todayVacations: [],
  tomorrowVacations: [],
  workingFromHome: [],
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_VACATIONS_START:
    case FETCH_DASHBOARD_ANNIVERSARIES_START:
    case FETCH_DASHBOARD_WFH_START:
      return { ...state, isFetching: true };
    case FETCH_DASHBOARD_VACATIONS_SUCCESS:
      return {
        ...state,
        todayVacations: action.payload.upcoming.today,
        tomorrowVacations: action.payload.upcoming.tomorrow,
        list: action.payload.vacations,
        isFetching: false,
      };
    case FETCH_DASHBOARD_ANNIVERSARIES_SUCCESS:
      return {
        ...state,
        anniversaries: action.payload.map((anniversary, index) => ({
          ...anniversary,
          popoverId: `Popover-${anniversary.type}-${index}`,
        })),
      };
    case FETCH_DASHBOARD_WFH_SUCCESS:
      return {
        ...state,
        workingFromHome: action.payload.users,
        isFetching: false,
      };
    case FETCH_DASHBOARD_VACATIONS_ERROR:
    case FETCH_DASHBOARD_ANNIVERSARIES_ERROR:
    case FETCH_DASHBOARD_WFH_ERROR:
      return { ...state, error: action.payload, isFetching: false };
    default:
      return state;
  }
};
