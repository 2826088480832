import React, { Fragment } from 'react';

const PageNotFound = () => (
  <Fragment>
    <h1>Page not found</h1>
    <p>
      Sorry! We can’t seem to find the page you’re looking for.
      <br /> Please check the address, or use the navigation bar to continue your search.
    </p>
  </Fragment>
);

export default PageNotFound;
