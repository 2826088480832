import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  sendResetPasswordEmail,
  resetForgotPasswordFlow,
} from '../../redux/actions/passwordActions';
import { Container, Row, Col, Button } from 'reactstrap';
import { TextInput } from '../../components/form';
import validate from './ForgotPasswordValidation';
import logo from '../../assets/logo.svg';
import './password.scss';
import '../../styles/theme.scss';

class ForgotPassword extends Component {
  componentDidMount() {
    this.props.resetForgotPasswordFlow();
  }

  handleSubmitClicked(formValues) {
    const { sendResetPasswordEmail } = this.props;
    sendResetPasswordEmail(formValues);
  }

  handleBackClicked() {
    const { history } = this.props;
    history.push('/login');
  }

  render() {
    const { handleSubmit, resetPasswordEmailSent, email } = this.props;

    return (
      <Container>
        <Row className="password-wrap">
          <Col md={{ size: 4 }}>
            <div className="card-shadow">
              <img src={logo} alt="Logo" />
              {!resetPasswordEmailSent ? (
                <form>
                  <Field
                    onFocus={this.onFocus}
                    name="email"
                    type="email"
                    label="E-mail"
                    component={TextInput}
                  />
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
                  >
                    Continue
                  </Button>
                  <Button onClick={this.handleBackClicked.bind(this)} className="btn-link btn-grey">
                    Back
                  </Button>
                </form>
              ) : (
                <div className="greeting">
                  <p>A reset password email has been sent to {email}!</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const selector = formValueSelector('ForgotPasswordForm');
const mapStoreToProps = store => ({
  email: selector(store, 'email'),
  resetPasswordEmailSent: store.password.resetPasswordEmailSent,
});

const mapDispatchToProps = dispatch => ({
  sendResetPasswordEmail: bindActionCreators(sendResetPasswordEmail, dispatch),
  resetForgotPasswordFlow: bindActionCreators(resetForgotPasswordFlow, dispatch),
});

const ForgotPasswordForm = reduxForm({
  form: 'ForgotPasswordForm',
  validate,
})(ForgotPassword);

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(ForgotPasswordForm);
