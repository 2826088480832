import {
  FETCH_STUDIOS_LIST_START,
  FETCH_STUDIOS_LIST_SUCCESS,
  FETCH_STUDIOS_LIST_ERROR,
  ADD_STUDIO_START,
  ADD_STUDIO_SUCCESS,
  ADD_STUDIO_ERROR,
  UPDATE_STUDIO_START,
  UPDATE_STUDIO_SUCCESS,
  UPDATE_STUDIO_ERROR,
  DELETE_STUDIO_START,
  DELETE_STUDIO_SUCCESS,
  DELETE_STUDIO_ERROR,
  REMOVE_STUDIO,
  CLEAN_REQUEST_ERRORS,
} from './types';
import { getData, postData, putData, deleteData, baseURL } from './api';
import { setGeneralAlertMessage } from '../actions/uiActions';
import { reset } from 'redux-form';

export const fetchStudiosList = params => dispatch => {
  dispatch({ type: FETCH_STUDIOS_LIST_START });

  return getData(
    `${baseURL}/api/settings/studios`,
    params,
    FETCH_STUDIOS_LIST_SUCCESS,
    FETCH_STUDIOS_LIST_ERROR,
    dispatch,
  );
};

export const addStudio = params => dispatch => {
  dispatch({ type: ADD_STUDIO_START });
  postData(
    `${baseURL}api/settings/studio`,
    params,
    ADD_STUDIO_SUCCESS,
    ADD_STUDIO_ERROR,
    dispatch,
    () => {
      dispatch({ type: CLEAN_REQUEST_ERRORS });
      dispatch(setGeneralAlertMessage('New studio added', 'info'));
      dispatch(reset('StudioModalForm'));
    },
  );
};

export const updateStudio = data => dispatch => {
  dispatch({ type: UPDATE_STUDIO_START });
  return putData(
    `${baseURL}api/settings/studio/${data.id}`,
    data,
    UPDATE_STUDIO_SUCCESS,
    UPDATE_STUDIO_ERROR,
    dispatch,
    () => {
      dispatch({ type: CLEAN_REQUEST_ERRORS });
      dispatch(setGeneralAlertMessage('Studio updated', 'info'));
      dispatch(reset('StudioModalForm'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const deleteStudio = studioId => dispatch => {
  dispatch({ type: DELETE_STUDIO_START, payload: studioId });
  deleteData(
    `${baseURL}api/settings/studio/${studioId}`,
    DELETE_STUDIO_SUCCESS,
    DELETE_STUDIO_ERROR,
    dispatch,
    () => {
      dispatch({ type: REMOVE_STUDIO, payload: studioId });
      dispatch(setGeneralAlertMessage('Studio deleted', 'info'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};
