const validate = values => {
  const errors = [];
  if (!values.carryover_days) {
    errors.carryover_days = 'Please provide number of days to carry over';
  }
  if (!values.carryover_from) {
    errors.carryover_from = 'Please provide the source year';
  }
  if (!values.carryover_to) {
    errors.carryover_to = 'Please provide the target year';
  }
  if (!values.carryover_reason) {
    errors.carryover_reason = 'Please provide the reason for your actions';
  }
  return errors;
};

export default validate;
