export function shortenName(data) {
  return data.split(' ').slice(0, 2);
}

export function getShortName(data) {
  const nameArray = shortenName(data);
  const secondNameChar = nameArray[1] && nameArray[1].slice(0, 1);
  return nameArray[0].concat(' ', secondNameChar, '.');
}

export function getAlias(data) {
  const nameArray = shortenName(data);
  const firstNameChar = nameArray[0].slice(0, 1);
  const secondNameChar = nameArray[1] ? nameArray[1].slice(0, 1) : '';
  return firstNameChar.concat(secondNameChar);
}
