import React, { Fragment } from 'react';
import { Label, Input, FormGroup, Col, Row } from 'reactstrap';
import ColorPicker from '../ui/ColorPicker';

const renderColorPicker = ({ input, showColorPicker, togglePicker }) => {
  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup>
            <Label className={!input.value || input.value.hex !== '' ? 'active' : ''} for="color">
              Calendar color
            </Label>
            <div className="color-input">
              <Input
                readOnly
                className={`${input.value.hex !== '' ? 'has-color' : ''} ${
                  showColorPicker ? 'force-focus' : ''
                }`}
                style={{
                  textTransform: 'uppercase',
                }}
                value={input.value.hex}
              />
              {!input.value || input.value.hex !== '' ? (
                <div className="color-badge" style={{ backgroundColor: input.value.hex }} />
              ) : null}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <ColorPicker
        {...input}
        handleSelect={input.onChange}
        showColorPicker={showColorPicker}
        togglePicker={togglePicker}
      />
    </Fragment>
  );
};

export default renderColorPicker;
