import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import VacationsReportModal from './components/VacationsReportModal';

import ReportIcon from '../../../../assets/Report.svg';
import CustomLoader from '../../../../components/ui/Loader';

class VacationsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVacationsReportModal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal = () => {
    this.setState({
      showVacationsReportModal: !this.state.showVacationsReportModal,
    });
  };

  render() {
    const { showVacationsReportModal } = this.state;

    const reportIcon = (
      <div className="report-icon">
        <img alt="Report" src={ReportIcon} />
      </div>
    );

    return (
      <div className="report-card">
        <div className="card-shadow">
          {this.props.generatingReport ? (
            <CustomLoader />
          ) : (
            <Fragment>
              <h3 className="report-title">Vacations Report</h3>
              <div>
                {reportIcon}
                <Button className="btn btn-primary" onClick={this.toggleModal}>
                  Generate
                </Button>
              </div>
            </Fragment>
          )}
        </div>
        <VacationsReportModal
          showModal={showVacationsReportModal}
          toggleModal={this.toggleModal}
          studios={this.props.studios}
          initialValues={{
            studio_id: {
              label: 'All Studios',
              value: 0,
            },
            range: {
              range1: {
                startDate: new Date(),
                endDate: new Date(),
              },
            },
          }}
        />
      </div>
    );
  }
}

export default VacationsReport;
