import React from 'react';
import { Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

import Avatar from '../../../../components/ui/Avatar';
import Progressbar from '../../../../components/ui/Progressbar';

import './teamGridCard.scss';

const TeamGridCard = ({ studios, teamMemberData, userRights, redirectUrl }) => {
  const studio = studios.filter(s => s.value === teamMemberData.studio_id);
  return (
    <Col md={{ size: 3 }} className="away-teamGridCard">
      <Link to={redirectUrl}>
        <div
          className={`card-shadow team-grid ${
            userRights.includes('hasApproverRights') ? 'large-grid' : 'small-grid'
          }`}
        >
          <div>
            <div className="team-grid-avatar">
              <Avatar
                size="xxxl"
                title={teamMemberData.name}
                avatar={teamMemberData.avatar}
                isCenter
              />
            </div>
            <div>
              <div className="team-grid-name">{teamMemberData.name}</div>
              <div className="team-grid-text">{teamMemberData.job}</div>
            </div>
          </div>
          {studio && studio.length !== 0 && (
            <div>
              <div className="team-grid-label">
                <FontAwesome name="map-marker" className="fa-small" />
                {studio[0].label}
              </div>
            </div>
          )}
          {userRights.includes('hasApproverRights') && (
            <div>
              <Progressbar
                totalDays={teamMemberData.total_all}
                daysLeft={teamMemberData.free_all}
                unpaidDaysTaken={teamMemberData.taken_unpaid}
                isContractor={teamMemberData.contract === 'self-employed'}
                hasLabel
                isCenter
                isSmall
              />
            </div>
          )}
        </div>
      </Link>
    </Col>
  );
};

export default connect(withRouter)(TeamGridCard);
