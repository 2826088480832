import React, { Component } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { DateRangePicker } from 'react-date-range';
import './daterange.scss';

class DateRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  render() {
    const {
      showDatePicker,
      handleSelect,
      value: {
        range1: { startDate, endDate },
      },
    } = this.props;

    return (
      <div className="away-daterange-line">
        <Popover
          placement="bottom"
          isOpen={showDatePicker}
          target="daterange"
          hideArrow={true}
          offset={0}
          className="away-daterange"
          toggle={this.props.togglePicker}
        >
          <PopoverBody>
            <div className="away-daterange-content">
              <DateRangePicker
                showMonthAndYearPickers={false}
                moveRangeOnFirstSelection={false}
                monthDisplayFormat="MMMM"
                months={this.state.windowWidth >= 992 ? 2 : 1}
                direction="horizontal"
                ranges={[{ startDate, endDate }]}
                rangeColors={['#ff8a5d']}
                onChange={handleSelect}
              />
              <Button className="btn btn-primary" onClick={this.props.togglePicker}>
                Confirm
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default DateRange;
