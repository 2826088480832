import React, { Fragment } from 'react';
import { Input, FormGroup, Col, Row } from 'reactstrap';
import AvatarEditor from './AvatarEditor';

const renderAvatarEditor = ({ input }) => {
  return (
    <Fragment>
      <Row>
        <Col>
          <FormGroup style={{ margin: 0 }}>
            <Input value={input.value} onChange={input.onChange} className="d-none" />
          </FormGroup>
        </Col>
      </Row>
      <AvatarEditor {...input} handleSelect={input.onChange} />
    </Fragment>
  );
};

export default renderAvatarEditor;
