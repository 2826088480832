import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import Pagination from 'react-js-pagination';
import FontAwesome from 'react-fontawesome';
import { PAGINATION_REQUESTS_PER_PAGE } from '../../constants/index';
import withIsMobile from '../../components/hoc/withIsMobile';

import { fetchTeamList } from '../../redux/actions/teamActions';
import {
  setTeamSelectedRole,
  setTeamSelectedStudio,
  setPeopleSelectedTeam,
  setTeamGrid,
  setTeamPage,
} from '../../redux/actions/uiActions';

import CustomLoader from '../../components/ui/Loader';
import Header from '../../components/ui/Header';
import TeamListCard from './components/TeamListCard';
import TeamGridCard from './components/TeamGridCard';

import './people.scss';

class People extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isGrid: props.teamListIsGrid || false,
      activePage: props.teamListActivePage || 1,
    };

    this.toggleGrid = this.toggleGrid.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const {
      fetchTeam,
      selectedStudio,
      selectedRole,
      selectedPeopleTeam,
      teamListActivePage,
    } = this.props;
    fetchTeam({
      studio_id: selectedStudio.value,
      team_id: selectedPeopleTeam.value,
      role_slug: selectedRole.slug,
      page: teamListActivePage,
    });
  }

  toggleGrid() {
    this.setState({
      isGrid: !this.state.isGrid,
    });
    this.props.setTeamGrid(!this.state.isGrid);
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    const { fetchTeam, setTeamPage, selectedStudio, selectedRole, selectedPeopleTeam } = this.props;
    setTeamPage(pageNumber);

    fetchTeam({
      studio_id: selectedStudio.value,
      team_id: selectedPeopleTeam.value,
      role_slug: selectedRole.slug,
      page: pageNumber,
    });
  }

  handleSelectRole = selectedOption => {
    const { selectedStudio, selectedPeopleTeam, setTeamSelectedRole, setTeamPage } = this.props;
    this.setState({ activePage: 1 });
    setTeamSelectedRole(selectedStudio, selectedOption, selectedPeopleTeam);
    setTeamPage(1);
  };

  handleSelectStudio = selectedOption => {
    const { selectedRole, selectedPeopleTeam, setTeamSelectedStudio, setTeamPage } = this.props;
    this.setState({ activePage: 1 });
    setTeamSelectedStudio(selectedOption, selectedRole, selectedPeopleTeam);
    setTeamPage(1);
  };

  handleSelectTeam = selectedOption => {
    const { selectedRole, selectedStudio, setPeopleSelectedTeam, setTeamPage } = this.props;
    this.setState({ activePage: 1 });
    setPeopleSelectedTeam(selectedOption, selectedRole, selectedStudio);
    setTeamPage(1);
  };

  renderHeader = (roleSelect, studioSelect, teamSelect, viewType) => {
    return (
      <Fragment>
        <Header title="People" hasRequestDay />
        <Row>
          <Col md={{ size: 12 }}>
            <div className="filter-area">
              <div>
                {roleSelect}
                {studioSelect}
                {teamSelect}
              </div>
              {viewType}
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  };

  renderContent = (teamMembersList, pager) => {
    const { isGrid } = this.state;
    const { userRights, studios } = this.props;

    if (teamMembersList.length === 0) {
      return (
        <Row>
          <Col md={{ size: 12 }}>
            <h3>No data available for selected filters!</h3>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          {teamMembersList.map((member, i) => {
            const teamRender = isGrid ? (
              <TeamGridCard
                key={i}
                userRights={userRights}
                teamMemberData={member}
                studios={studios}
                redirectUrl={`/people/${member && member.id}`}
              />
            ) : (
              <TeamListCard
                key={i}
                userRights={userRights}
                teamMemberData={member}
                studios={studios}
                redirectUrl={`/people/${member && member.id}`}
              />
            );
            return teamRender;
          })}
          <Col md={{ size: 12 }}>{pager}</Col>
        </Row>
      );
    }
  };

  render() {
    const { isGrid, activePage } = this.state;
    const {
      teamMembersList,
      teamMembersListTotal,
      isFetching,
      roles,
      studios,
      teams,
      selectedRole,
      selectedStudio,
      selectedPeopleTeam,
      isMobile,
    } = this.props;

    const roleSelect = (
      <Select
        className="away-dropdown"
        classNamePrefix="away"
        placeholder="Roles"
        value={selectedRole}
        onChange={this.handleSelectRole}
        options={[
          {
            label: 'All Roles',
            value: -1,
            slug: 'all',
          },
          ...roles,
        ]}
        isSearchable={false}
      />
    );
    const studioSelect = (
      <Select
        className="away-dropdown"
        classNamePrefix="away"
        placeholder="Studios"
        value={selectedStudio}
        onChange={this.handleSelectStudio}
        options={[
          {
            label: 'All Studios',
            value: 0,
          },
          ...studios,
        ]}
        isSearchable={false}
      />
    );
    const teamSelect = (
      <Select
        className="away-dropdown"
        classNamePrefix="away"
        placeholder="Teams"
        value={selectedPeopleTeam}
        onChange={this.handleSelectTeam}
        options={[
          {
            label: 'All Teams',
            value: 0,
          },
          ...teams,
        ]}
        isSearchable={false}
      />
    );
    const viewType = !isMobile ? (
      <div className="filter-grid">
        <FontAwesome
          name="th-large"
          className={`fa-small ${isGrid ? 'fl-active' : 'fl-notActive'}`}
          onClick={this.toggleGrid}
        />
        <FontAwesome
          name="th-list"
          className={`fa-small ${!isGrid ? 'fl-active' : 'fl-notActive'}`}
          onClick={this.toggleGrid}
        />
      </div>
    ) : null;
    const pager = teamMembersListTotal && teamMembersListTotal > PAGINATION_REQUESTS_PER_PAGE && (
      <div className="away-pagination">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={PAGINATION_REQUESTS_PER_PAGE}
          totalItemsCount={teamMembersListTotal}
          pageRangeDisplayed={5}
          onChange={this.handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );

    return (
      <Fragment>
        {this.renderHeader(roleSelect, studioSelect, teamSelect, viewType)}
        {isFetching ? <CustomLoader /> : this.renderContent(teamMembersList, pager)}
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  teamMembersList: store.team.list,
  teamMembersListPage: store.team.listPage,
  teamMembersListTotal: store.team.listTotal,
  user: store.signedInUser.user,
  userRights: store.signedInUser.userRights,
  isFetching: store.team.isFetching,
  roles: store.constants.roles,
  studios: store.constants.studios,
  teams: store.constants.teams,
  selectedRole: store.ui.selectedTeamRole,
  selectedStudio: store.ui.selectedTeamStudio,
  selectedPeopleTeam: store.ui.selectedPeopleTeam,
  teamListIsGrid: store.ui.teamListIsGrid,
  teamListActivePage: store.ui.teamListActivePage,
});

const mapDispatchToProps = dispatch => ({
  setTeamSelectedStudio: bindActionCreators(setTeamSelectedStudio, dispatch),
  setTeamSelectedRole: bindActionCreators(setTeamSelectedRole, dispatch),
  setPeopleSelectedTeam: bindActionCreators(setPeopleSelectedTeam, dispatch),
  setTeamGrid: bindActionCreators(setTeamGrid, dispatch),
  setTeamPage: bindActionCreators(setTeamPage, dispatch),
  fetchTeam: bindActionCreators(fetchTeamList, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(withIsMobile(People));
