import React, { Component } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { ChromePicker } from 'react-color';
import './colorpicker.scss';

class ColorPicker extends Component {
  render() {
    const { showColorPicker, handleSelect, value, togglePicker } = this.props;

    return (
      <div className="away-colorpicker-line">
        <Popover
          placement="bottom"
          isOpen={showColorPicker}
          target="colorpicker"
          hideArrow={true}
          offset={0}
          className="away-colorpicker"
          toggle={togglePicker}
        >
          <PopoverBody>
            <div className="away-colorpicker-content">
              <ChromePicker disableAlpha color={value} onChange={handleSelect} />
              <Button className="btn btn-primary" onClick={togglePicker}>
                Confirm
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default ColorPicker;
