import isEmail from 'sane-email-validation';

const validate = values => {
  const errors = [];
  if (!values.email) {
    errors.email = 'Required';
  } else if (!isEmail(values.email)) {
    errors.email = 'Please enter a valid email address';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

export default validate;
