import { reset } from 'redux-form';
import {
  CREATE_NEW_USER_ERROR,
  CREATE_NEW_USER_START,
  CREATE_NEW_USER_SUCCESS,
  DELETE_TEAM_ADMIN_MEMBER_AVATAR_ERROR,
  DELETE_TEAM_ADMIN_MEMBER_AVATAR_START,
  DELETE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS,
  FETCH_MEMBER_VACATION_DEFAULTS_ERROR,
  FETCH_MEMBER_VACATION_DEFAULTS_START,
  FETCH_MEMBER_VACATION_DEFAULTS_SUCCESS,
  FETCH_TEAM_ADMIN_LIST_ERROR,
  FETCH_TEAM_ADMIN_LIST_START,
  FETCH_TEAM_ADMIN_LIST_SUCCESS,
  FETCH_TEAM_ADMIN_MEMBER_ERROR,
  FETCH_TEAM_ADMIN_MEMBER_START,
  FETCH_TEAM_ADMIN_MEMBER_SUCCESS,
  UPDATE_TEAM_ADMIN_MEMBER_AVATAR_ERROR,
  UPDATE_TEAM_ADMIN_MEMBER_AVATAR_START,
  UPDATE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS,
  UPDATE_TEAM_ADMIN_MEMBER_PROFILE_ERROR,
  UPDATE_TEAM_ADMIN_MEMBER_PROFILE_START,
  UPDATE_TEAM_ADMIN_MEMBER_PROFILE_SUCCESS,
  UPDATE_TEAM_ADMIN_MEMBER_STATS_ERROR,
  UPDATE_TEAM_ADMIN_MEMBER_STATS_START,
  UPDATE_TEAM_ADMIN_MEMBER_STATS_SUCCESS,
  UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_ERROR,
  UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_START,
  UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_SUCCESS,
  RESET_TEAM_ADMIN_MEMBER,
} from './types';
import { baseURL, deleteData, getData, postData, putData } from './api';
import { setGeneralAlertMessage } from './uiActions';
import { processApiError } from '../../utils/ErrorHandler';

export const fetchTeamAdminList = params => dispatch => {
  dispatch({ type: FETCH_TEAM_ADMIN_LIST_START });

  if (params.studio_id === 0) delete params.studio_id;
  params.stats = 1;

  return getData(
    `${baseURL}api/user/list`,
    params,
    FETCH_TEAM_ADMIN_LIST_SUCCESS,
    FETCH_TEAM_ADMIN_LIST_ERROR,
    dispatch,
  );
};

export const fetchTeamAdminMember = (memberId, year) => dispatch => {
  dispatch({ type: FETCH_TEAM_ADMIN_MEMBER_START });

  const params = {
    year,
    stats: 1,
  };

  if (!params.year) params.year = 2018;

  return getData(
    `${baseURL}api/user/profile/${memberId}`,
    params,
    FETCH_TEAM_ADMIN_MEMBER_SUCCESS,
    FETCH_TEAM_ADMIN_MEMBER_ERROR,
    dispatch,
  );
};

export const fetchMemberVacationDefaults = (memberId, year) => dispatch => {
  dispatch({ type: FETCH_MEMBER_VACATION_DEFAULTS_START });

  const params = {
    year,
  };

  if (!params.year) params.year = 2018;

  return getData(
    `${baseURL}api/user/profile/${memberId}/defaultSettings`,
    params,
    FETCH_MEMBER_VACATION_DEFAULTS_SUCCESS,
    FETCH_MEMBER_VACATION_DEFAULTS_ERROR,
    dispatch,
  );
};

export const updateTeamAdminMemberProfile = data => dispatch => {
  dispatch({ type: UPDATE_TEAM_ADMIN_MEMBER_PROFILE_START });
  return putData(
    `${baseURL}api/user/profile/${data.id}`,
    data,
    UPDATE_TEAM_ADMIN_MEMBER_PROFILE_SUCCESS,
    UPDATE_TEAM_ADMIN_MEMBER_PROFILE_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Profile updated', 'success'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const updateTeamAdminMemberCarryOver = (userId, data, successCallback) => dispatch => {
  dispatch({ type: UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_START });
  return postData(
    `${baseURL}api/user/profile/${userId}/carryOver`,
    data,
    UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_SUCCESS,
    UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_ERROR,
    dispatch,
    response => {
      successCallback();
      dispatch(setGeneralAlertMessage(response.message, 'success'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const updateTeamAdminMemberStats = (userId, data, successCallback) => dispatch => {
  dispatch({ type: UPDATE_TEAM_ADMIN_MEMBER_STATS_START });
  return postData(
    `${baseURL}api/user/profile/${userId}/defaultSettings`,
    data,
    UPDATE_TEAM_ADMIN_MEMBER_STATS_SUCCESS,
    UPDATE_TEAM_ADMIN_MEMBER_STATS_ERROR,
    dispatch,
    () => {
      successCallback();
      dispatch(setGeneralAlertMessage('Vacation settings updated', 'success'));
    },
    error => {
      dispatch(setGeneralAlertMessage(processApiError(error), 'danger'));
    },
  );
};

export const updateTeamAdminMemberAvatar = (data, userId) => dispatch => {
  dispatch({ type: UPDATE_TEAM_ADMIN_MEMBER_AVATAR_START });
  return postData(
    `${baseURL}api/user/profile/${userId}/avatar`,
    data,
    UPDATE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS,
    UPDATE_TEAM_ADMIN_MEMBER_AVATAR_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Avatar updated', 'info'));

      // todo - reset from in component, immediately after submitting
      dispatch(reset('AvatarModalForm'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const deleteTeamAdminMemberAvatar = userId => dispatch => {
  dispatch({ type: DELETE_TEAM_ADMIN_MEMBER_AVATAR_START, payload: userId });
  deleteData(
    `${baseURL}api/user/profile/${userId}/avatar`,
    DELETE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS,
    DELETE_TEAM_ADMIN_MEMBER_AVATAR_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('Avatar deleted', 'info'));
    },
    error => {
      dispatch(setGeneralAlertMessage(error.data.message, 'danger'));
    },
  );
};

export const createNewUser = (data, successCallback) => dispatch => {
  dispatch({ type: CREATE_NEW_USER_START });
  return postData(
    `${baseURL}/api/user/profile`,
    data,
    CREATE_NEW_USER_SUCCESS,
    CREATE_NEW_USER_ERROR,
    dispatch,
    () => {
      dispatch(setGeneralAlertMessage('New user created', 'success'));
      successCallback();
    },
    error => dispatch(setGeneralAlertMessage(error.data.message, 'danger')),
  );
};

export const resetTeamAdminMember = () => dispatch => {
  dispatch({ type: RESET_TEAM_ADMIN_MEMBER });
};
