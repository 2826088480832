import React from 'react';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import Avatar from '../../../../components/ui/Avatar';
import Progressbar from '../../../../components/ui/Progressbar';
import './teamListCard.scss';

const TeamListCard = ({ studios, teamMemberData, userRights, redirectUrl }) => {
  const studio = studios.filter(s => s.value === teamMemberData.studio_id);
  return (
    <Col md={{ size: 12 }} className="away-teamListCard">
      <Link to={redirectUrl}>
        <Row
          className={`card-shadow team-list ${userRights.includes('hasApproverRights') &&
            'team-list-full'}`}
        >
          <Col md={{ size: 1 }}>
            <div className="team-list-avatar">
              <Avatar size="lg" title={teamMemberData.name} avatar={teamMemberData.avatar} />
            </div>
          </Col>
          <Col md={{ size: 3 }}>
            <div className="team-list-name">{teamMemberData.name}</div>
            <div className="team-list-text">{teamMemberData.job}</div>
          </Col>
          <Col
            md={{
              size: userRights.includes('hasApproverRights') ? 3 : 4,
            }}
          >
            <div className="team-list-label">Contact</div>
            <div className="team-list-text">{teamMemberData.email}</div>
          </Col>
          <Col md={{ size: 2 }}>
            {studio && studio.length !== 0 && (
              <div>
                <div className="team-list-label">Studio</div>
                <div className="team-list-text">{studio[0].label}</div>
              </div>
            )}
          </Col>
          {userRights.includes('hasApproverRights') && (
            <Col md={{ size: 3 }}>
              <Progressbar
                totalDays={teamMemberData.total_all}
                daysLeft={teamMemberData.free_all}
                unpaidDaysTaken={teamMemberData.taken_unpaid}
                isContractor={teamMemberData.contract === 'self-employed'}
                hasLabel
                isSmall
              />
            </Col>
          )}
        </Row>
      </Link>
    </Col>
  );
};

export default connect(withRouter)(TeamListCard);
