import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import team from './teamReducer';
import actualTeams from './actualTeamsReducer';
import teamAdmin from './teamAdminReducer';
import signedInUser from './signedInUserReducer';
import auth from './authReducer';
import password from './passwordReducer';
import dashboardVacations from './dashboardVacationsReducer';
import publicHolidays from './publicHolidaysReducer';
import signedInUserVacations from './signedInUserVacationsReducer';
import teamVacations from './teamVacationsReducer';
import userLogs from './userLogsReducer';
import vacationTypeSettings from './vacationTypeSettingsReducer';
import studiosSettings from './studiosSettingsReducer';
import companySettings from './companySettingsReducer';
import constants from './constantsReducer';
import reports from './reportsReducer';
import ui from './uiReducer';

const appReducer = combineReducers({
  auth,
  password,
  team,
  actualTeams,
  teamAdmin,
  signedInUser,
  dashboardVacations,
  publicHolidays,
  signedInUserVacations,
  teamVacations,
  userLogs,
  vacationTypeSettings,
  studiosSettings,
  companySettings,
  constants,
  ui,
  reports,
  form: formReducer,
});

export default appReducer;
