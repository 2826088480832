import React from 'react';
import { Modal, ModalBody, Button, Row, Col } from 'reactstrap';
import '../RequestModal/requestModal.scss';

const WorkOnPublicHolidayModal = ({
  showModal,
  onSubmit,
  toggle,
  publicHolidayName,
  publicHolidayDate,
}) => {
  return (
    <Modal className="away-modal" isOpen={showModal} toggle={toggle}>
      <ModalBody className="away-modal-body">
        <div className="away-modal-line">
          <h3>Public Holiday</h3>
        </div>
        <Row className="margin-bot-30">
          <Col md="6">
            <p className="away-label">Holiday Name</p>
            <p className="public-holiday-content">{publicHolidayName}</p>
          </Col>
          <Col md="6">
            <p className="away-label">Period</p>
            <p className="public-holiday-content">{publicHolidayDate}</p>
          </Col>
        </Row>
        <div className="away-modal-buttons">
          <Button onClick={onSubmit} className="btn btn-primary">
            I am working on this day
          </Button>
          <Button onClick={toggle} className="btn-link btn-grey">
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WorkOnPublicHolidayModal;
