import React, { Fragment, Component } from 'react';
import { bindActionCreators } from 'redux';
import cx from 'classnames';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import ProfileCard from './components/ProfileCard';
import withIsMobile from '../../components/hoc/withIsMobile';
import Header from '../../components/ui/Header';
import VacationRequestsCard from './components/VacationRequestsCard';
import UpcomingVacationsCard from './components/UpcomingVacationsCard';
import WorkingFromHomeCard from './components/WorkingFromHomeCard';
import UpcomingHolidaysCard from './components/UpcomingHolidaysCard';
import Calendar from './components/Calendar/Calendar';
import { fetchSignedInUser } from '../../redux/actions/signedInUserActions';
import { fetchTeamVacations } from '../../redux/actions/teamVacationsActions';
import { setSelectedStudio } from '../../redux/actions/uiActions';
import './components/Calendar/calendar.scss';
import {
  fetchExtraPublicHolidays,
  fetchPublicHolidays,
} from '../../redux/actions/publicHolidaysActions';

class DashboardContainer extends Component {
  componentDidMount() {
    const { calendarDate, userRights, publicHolidaysYearsFetched } = this.props;
    const userStudio = this.props.studios.find(s => s.value === this.props.user.studio_id);
    this.props.setSelectedStudio(
      userStudio,
      0,
      null,
      calendarDate,
      userRights.includes('hasApproverRights'),
    );
    this.props.fetchSignedInUser();
    userRights.includes('hasApproverRights') &&
      this.props.fetchTeamVacations({
        status: 'pending',
      });

    const year = calendarDate.getFullYear();
    if (!publicHolidaysYearsFetched.includes(year)) {
      this.props.fetchPublicHolidays(year);
      this.props.fetchExtraPublicHolidays(year);
    }
  }

  handleManage() {
    const { history } = this.props;
    history.push('/vacation-requests');
  }

  render() {
    const {
      teamVacations,
      user,
      userRights,
      upcomingPublicHolidays,
      isMobile,
      todayVacations,
      tomorrowVacations,
      isFetching,
      workingFromHome,
    } = this.props;

    return (
      <Fragment>
        <Header title="Dashboard" hasRequestDay />
        <Row>
          <Col md="8">
            <div
              className={cx(
                'card-shadow calendar',
                userRights.includes('hasApproverRights') && isMobile && 'admin-mobile',
              )}
            >
              <Calendar />
            </div>
          </Col>
          <Col md="4">
            <ProfileCard user={user} />
            {userRights.includes('hasApproverRights') && teamVacations && (
              <VacationRequestsCard
                pendingRequests={teamVacations}
                vacationRequesters={teamVacations.filter(
                  (e, i) => teamVacations.findIndex(a => a.name === e.name) === i,
                )}
                handleManage={this.handleManage.bind(this)}
              />
            )}
            <UpcomingVacationsCard
              todayVacations={todayVacations}
              tomorrowVacations={tomorrowVacations}
              isFetching={isFetching}
            />
            <WorkingFromHomeCard workingFromHome={workingFromHome} isFetching={isFetching} />
            <UpcomingHolidaysCard upcomingPublicHolidays={upcomingPublicHolidays} />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  studios: store.constants.studios,
  user: store.signedInUser.user,
  dashboardVacations: store.dashboardVacations.list,
  todayVacations: store.dashboardVacations.todayVacations,
  tomorrowVacations: store.dashboardVacations.tomorrowVacations,
  workingFromHome: store.dashboardVacations.workingFromHome,
  isFetching: store.dashboardVacations.isFetching,
  teamVacations: store.teamVacations.list,
  upcomingPublicHolidays: store.constants.upcomingPublicHolidays,
  calendarDate: store.ui.calendarDate,
  selectedVacationType: store.ui.selectedVacationType,
  selectedStudio: store.ui.selectedStudio,
  selectedTeam: store.ui.selectedTeam,
  userRights: store.signedInUser.userRights,
  publicHolidaysYearsFetched: store.publicHolidays.yearsFetched,
});

const mapDispatchToProps = dispatch => ({
  fetchPublicHolidays: bindActionCreators(fetchPublicHolidays, dispatch),
  fetchExtraPublicHolidays: bindActionCreators(fetchExtraPublicHolidays, dispatch),
  fetchTeamVacations: bindActionCreators(fetchTeamVacations, dispatch),
  fetchSignedInUser: bindActionCreators(fetchSignedInUser, dispatch),
  setSelectedStudio: bindActionCreators(setSelectedStudio, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(withIsMobile(DashboardContainer));
