import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, Alert } from 'reactstrap';
import { DateRange, TextInput, Select2, Checkbox } from '../../form';
import CustomLoader from '../../ui/Loader';
import {
  addRequest,
  editRequest,
  cleanRequestErrors,
  fetchAvailableVacations,
} from '../../../redux/actions/signedInUserVacationsActions';

import { toggleRequestModal } from '../../../redux/actions/uiActions';
import { formatDate } from '../../../utils/Date';
import validate from './validations';
import './requestModal.scss';

class RequestModal extends Component {
  state = {
    toggleDatePicker: false,
  };

  handleSubmitClicked(data) {
    const { vacationId, addRequest, editRequest } = this.props;

    const requestData = {
      start: formatDate(data.range.range1.startDate, 'YYYY-MM-DD'),
      end: formatDate(data.range.range1.endDate, 'YYYY-MM-DD'),
      vacation_type: data.vacationType.value,
      user_id: data.user ? data.user.value : this.props.signedInUser.id,
      holiday_work_request_id: this.props.inLieuChecked ? data.inLieu.value : 0,
      note: data.note ? data.note : '',
    };

    if (vacationId === 0) {
      addRequest(requestData);
    } else {
      editRequest(requestData, vacationId);
    }
  }

  handleCloseModal() {
    this.props.initialize();
    this.props.toggleRequestModal();
  }

  togglePicker = () => {
    this.setState({
      showDatePicker: !this.state.showDatePicker,
    });
  };

  onUserChange = user => {
    const { dateRange } = this.props;
    let year = new Date().getFullYear();
    if (dateRange && dateRange.range1 && dateRange.range1.startDate) {
      year = dateRange.range1.startDate.getFullYear();
    }
    this.props.fetchAvailableVacations(user.value, { year });
    this.props.reset();
  };

  onRangeChange = newRange => {
    const { startDate } = newRange;
    const {
      dateRange,
      selectedUser,
      fetchAvailableVacations,
      userRights,
      signedInUser,
    } = this.props;

    const newYear = startDate.getFullYear();
    let prevYear = null;
    if (dateRange && dateRange.range1 && dateRange.range1.startDate) {
      prevYear = dateRange.range1.startDate.getFullYear();
    }
    // only refetch available vacations if year differs from previous value?
    if (newYear !== prevYear) {
      if (userRights.includes('hasAdministrativeRights')) {
        fetchAvailableVacations(
          selectedUser.value,
          { year: newYear },
          this.updateVacationTypeAvailability,
        );
      } else {
        fetchAvailableVacations(
          signedInUser.id,
          { year: newYear },
          this.updateVacationTypeAvailability,
        );
      }
    }
  };

  updateVacationTypeAvailability = () => {
    const { vacationTypes, selectedVacationType } = this.props;
    if (selectedVacationType) {
      const vacationTypeId = selectedVacationType.value;
      const vacationType = vacationTypes.find(v => v.value === vacationTypeId);
      this.props.change('vacationType', vacationType.value ? vacationType : null);
    }
  };

  toggleErrorAlert = () => {
    this.props.cleanRequestErrors();
  };

  render() {
    const {
      pristine,
      showModal,
      vacationTypes,
      selectedVacationType,
      handleSubmit,
      allUsers,
      signedInUser,
      inLieuChecked,
      isFetchingAvailableVacations,
      isFetchingRequest,
      inLieu,
      vacationId,
      errorMessage,
      toggleRequestModal,
      userRights,
    } = this.props;

    const isEditMode = !!vacationId;
    const signedInUserIsContractor = signedInUser.contract === 'self-employed';

    return (
      <Modal className="away-modal" isOpen={showModal} toggle={toggleRequestModal}>
        <ModalBody className="away-modal-body">
          {isFetchingRequest && (
            <div className="away-calendar">
              <CustomLoader />
            </div>
          )}

          {!isFetchingRequest && (
            <Fragment>
              <div className="away-modal-line">
                <h3>{isEditMode ? 'Edit Request' : 'Request Day(s) Off'}</h3>
              </div>
              <Alert
                color="danger"
                isOpen={(errorMessage && errorMessage.length) > 0}
                toggle={this.toggleErrorAlert}
              >
                {errorMessage}
              </Alert>
              {userRights.includes('hasAdministrativeRights') && !isEditMode && (
                <Field
                  className="away-dropdown-form version-2"
                  id="user"
                  label="User"
                  name="user"
                  component={Select2}
                  placeholder="User"
                  classNamePrefix="away"
                  onChange={user => this.onUserChange(user)}
                  options={[
                    {
                      value: signedInUser.id,
                      label: signedInUser.name,
                    },
                    ...allUsers.map(user => {
                      return {
                        value: user.id,
                        label: user.name,
                      };
                    }),
                  ]}
                />
              )}
              <Field
                className="away-dropdown-form version-2"
                id="vacationType"
                label="Request type"
                disabled={isFetchingAvailableVacations}
                name="vacationType"
                component={Select2}
                placeholder={
                  isFetchingAvailableVacations
                    ? 'Fetching vacation availability...'
                    : 'Request type'
                }
                classNamePrefix="away"
                options={vacationTypes}
              />
              <div
                className="away-daterange-inputs"
                id="daterange"
                onClick={this.togglePicker.bind(this)}
              >
                <Field
                  name="range"
                  component={DateRange}
                  onChange={range => this.onRangeChange(range.range1)}
                  showDatePicker={this.state.showDatePicker}
                  togglePicker={this.togglePicker}
                />
              </div>
              <div>
                <Field id="note" name="note" label="Add note" component={TextInput} />
              </div>
              {selectedVacationType &&
                selectedVacationType.value === 25 &&
                !signedInUserIsContractor && (
                  <Fragment>
                    <Field
                      id="customCheck"
                      name="inLieuCheckbox"
                      label="In lieu of official holiday"
                      component={Checkbox}
                    />
                    {inLieuChecked && (
                      <Field
                        className="away-dropdown-form version-2 no-capitalize"
                        id="inLieu"
                        label="In Lieu"
                        name="inLieu"
                        component={Select2}
                        placeholder="In lieu"
                        classNamePrefix="away"
                        options={inLieu}
                      />
                    )}
                  </Fragment>
                )}
              <div className="away-modal-buttons">
                <Button
                  id="submit-button"
                  onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
                  className="btn btn-primary"
                  disabled={pristine}
                >
                  {isEditMode ? 'Update Request' : 'Submit Request'}
                </Button>
                <Button
                  id="cancel-button"
                  onClick={toggleRequestModal}
                  className="btn-link btn-grey"
                >
                  Cancel
                </Button>
              </div>
            </Fragment>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

RequestModal.propTypes = {
  requestData: PropTypes.object,
};

const selector = formValueSelector('RequestVacationForm');
const RequestVacationForm = reduxForm({
  validate,
  form: 'RequestVacationForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(RequestModal);

const mapStoreToProps = (store, ownProps) => {
  const userRights = store.signedInUser.userRights;
  const initialValuesWithUser = {
    value: store.signedInUser.user.id,
    label: store.signedInUser.user.name,
  };

  return {
    userRights: userRights,
    isFetchingAvailableVacations: store.signedInUserVacations.availableVacations.isFetching,
    isFetchingRequest: store.signedInUserVacations.addRequest.isFetching,
    errorMessage: store.signedInUserVacations.addRequest.error,
    vacationTypes: store.signedInUserVacations.availableVacations.vacations,
    inLieu: store.signedInUserVacations.availableVacations.inLieu,
    signedInUser: store.signedInUser.user,
    allUsers: store.team.allUsers,
    inLieuChecked: selector(store, 'inLieuCheckbox'),
    selectedVacationType: selector(store, 'vacationType'),
    selectedUser: selector(store, 'user'),
    dateRange: selector(store, 'range'),
    initialValues: userRights.includes('hasAdministrativeRights')
      ? { ...ownProps.initialValues, user: initialValuesWithUser }
      : { ...ownProps.initialValues },
    vacationId: store.ui.requestModal.vacationId,
  };
};

const mapDispatchToProps = dispatch => ({
  addRequest: bindActionCreators(addRequest, dispatch),
  editRequest: bindActionCreators(editRequest, dispatch),
  fetchAvailableVacations: bindActionCreators(fetchAvailableVacations, dispatch),
  toggleRequestModal: bindActionCreators(toggleRequestModal, dispatch),
  cleanRequestErrors: bindActionCreators(cleanRequestErrors, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(RequestVacationForm);
