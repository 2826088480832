import React, { Component } from 'react';
import { Button, Modal, ModalBody, Alert } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { PasswordInput } from '../../../components/form';
import validate from './validations';

class ChangePasswordModal extends Component {
  state = {
    error: null,
    showApiError: false,
  };

  handleSubmitClicked(data) {
    const { updateSignedInUserPassword, userId, toggleModal, reset } = this.props;

    this.clearApiError();

    const payload = {
      oldPassword: data.currentPassword,
      newPassword: data.newPassword,
    };

    updateSignedInUserPassword(
      payload,
      userId,
      () => {
        reset();
        toggleModal();
      },
      error => {
        this.setState({ error, showApiError: true });
      },
    );
  }

  clearApiError = () => this.setState({ showApiError: false, error: null });

  handleCloseModal() {
    const { reset, toggleModal } = this.props;
    reset();
    toggleModal();
  }

  render() {
    const { showModal, handleSubmit } = this.props;
    return (
      <Modal
        className="away-modal"
        isOpen={showModal}
        toggle={() => this.handleCloseModal.bind(this)}
      >
        <ModalBody className="away-modal-body">
          <div className="away-modal-line">
            <h3>Change password</h3>
          </div>
          <Alert color="danger" isOpen={this.state.showApiError} toggle={this.clearApiError}>
            {this.state.error}
          </Alert>
          <Field
            id="currentPassword"
            name="currentPassword"
            label="Current password"
            component={PasswordInput}
          />
          <Field
            id="newPassword"
            name="newPassword"
            label="New password"
            component={PasswordInput}
          />
          <Field
            id="confirmNewPassword"
            name="confirmNewPassword"
            label="Confirm new password"
            component={PasswordInput}
          />
          <div className="away-modal-buttons">
            <Button
              onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
              className="btn btn-primary"
            >
              Change password
            </Button>
            <Button onClick={this.handleCloseModal.bind(this)} className="btn-link btn-grey">
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const ChangePasswordForm = reduxForm({
  form: 'ChangePasswordForm',
  validate,
})(ChangePasswordModal);

export default ChangePasswordForm;
