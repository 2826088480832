import axios from 'axios';
export { baseURL } from '../../constants';

export const getData = async (
  url,
  params,
  successAction,
  errorAction,
  dispatch,
  successCallback,
  errorCallback,
) => {
  try {
    const response = await axios.get(url, { params: params, ...getRequestHeaders() });
    dispatch({ type: successAction, payload: response.data });
    if (successCallback && typeof successCallback === 'function') {
      successCallback(response.data);
    }
  } catch (error) {
    dispatch({ type: errorAction, payload: error });
    if (errorCallback && typeof errorCallback === 'function') {
      errorCallback(error);
    }
  }
};

export const postData = async (
  url,
  payload,
  successAction,
  errorAction,
  dispatch,
  successCallback,
  errorCallback,
) => {
  try {
    const response = await axios.post(url, payload, getRequestHeaders());
    dispatch({ type: successAction, payload: response.data });
    if (successCallback && typeof successCallback === 'function') {
      successCallback(response.data);
    }
  } catch (error) {
    dispatch({ type: errorAction, payload: error });
    if (errorCallback && typeof errorCallback === 'function') {
      errorCallback(error.response);
    }
  }
};

export const putData = async (
  url,
  payload,
  successAction,
  errorAction,
  dispatch,
  successCallback,
  errorCallback,
) => {
  try {
    const response = await axios.put(url, payload, getRequestHeaders());
    dispatch({ type: successAction, payload: response.data });
    if (successCallback && typeof successCallback === 'function') {
      successCallback(response.data);
    }
  } catch (error) {
    dispatch({ type: errorAction, payload: error });
    if (errorCallback && typeof errorCallback === 'function') {
      errorCallback(error);
    }
  }
};

export const deleteData = async (
  url,
  successAction,
  errorAction,
  dispatch,
  successCallback,
  errorCallback,
) => {
  try {
    const response = await axios.delete(url, getRequestHeaders());
    dispatch({ type: successAction, payload: response.data });
    if (successCallback && typeof successCallback === 'function') {
      successCallback(response.data);
    }
  } catch (error) {
    dispatch({ type: errorAction, payload: error });
    if (errorCallback && typeof errorCallback === 'function') {
      errorCallback(error.response);
    }
  }
};

export const downloadReport = async (
  url,
  params,
  reportName,
  successAction,
  errorAction,
  dispatch,
) => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios({
      url,
      method: 'GET',
      responseType: 'arraybuffer',
      params,
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });

    // perform the automatic report download operation
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', `${reportName}.xlsx`);
    document.body.appendChild(link);
    link.click();
    dispatch({ type: successAction });
  } catch (error) {
    console.log(error);
    dispatch({ type: errorAction });
  }
};

export const getRequestHeaders = () => {
  const token = localStorage.getItem('token');
  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    headers.headers['Authorization'] = 'Bearer ' + token;
  }
  return headers;
};
