import {
  RESET_FORGOT_PASSWORD_FLOW,
  SEND_NEW_PASSWORD_START,
  SEND_NEW_PASSWORD_SUCCESS,
  SEND_NEW_PASSWORD_ERROR,
  FETCH_RESET_PASSWORD_START,
  FETCH_RESET_PASSWORD_SUCCESS,
  FETCH_RESET_PASSWORD_ERROR,
  SEND_RESET_PASSWORD_EMAIL_START,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_ERROR,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  resetPasswordEmailSent: false,
  newPasswordSaved: false,
  user: {},
  token: {},
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_RESET_PASSWORD_START:
      return { ...state, isFetching: true };
    case FETCH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isFetching: false,
      };
    case FETCH_RESET_PASSWORD_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    case SEND_RESET_PASSWORD_EMAIL_START:
      return {
        ...state,
        resetPasswordEmailSent: false,
      };
    case SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return {
        ...state,
        resetPasswordEmailSent: true,
      };
    case SEND_RESET_PASSWORD_EMAIL_ERROR:
      return {
        ...state,
        resetPasswordEmailSent: false,
      };

    case SEND_NEW_PASSWORD_START:
      return {
        ...state,
        newPasswordSaved: false,
      };
    case SEND_NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPasswordSaved: true,
      };
    case SEND_NEW_PASSWORD_ERROR:
      return {
        ...state,
        newPasswordSaved: false,
      };
    case RESET_FORGOT_PASSWORD_FLOW:
      return {
        isFetching: false,
        resetPasswordEmailSent: false,
        newPasswordSaved: false,
      };
    default:
      return state;
  }
};
