import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  VALIDATE_TOKEN_START,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_ERROR,
  SKIP_TOKEN_VALIDATION,
} from '../actions/types';

const initialValues = {
  isAuthenticated: false,
  isValidatingToken: true,
  isLoggingIn: false,
  loginAttemptFailed: false,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case VALIDATE_TOKEN_START:
      return { ...state, isValidatingToken: true };
    case LOGIN_USER_START:
      return { ...state, isLoggingIn: true, loginAttemptFailed: false };
    case LOGIN_USER_SUCCESS:
    case VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isValidatingToken: false,
      };
    case VALIDATE_TOKEN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isValidatingToken: false,
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        isLoggingIn: false,
        loginAttemptFailed: true,
      };
    case SKIP_TOKEN_VALIDATION:
      return { ...state, isAuthenticated: false, isValidatingToken: false };
    case LOGOUT_USER:
      return { ...state, isAuthenticated: false, loginAttemptFailed: false };
    default:
      return state;
  }
};
