import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { TextInput, TextArea } from '../../form';
import { Modal, ModalBody, Button, Row, Col } from 'reactstrap';
import '../RequestModal/requestModal.scss';
import validate from './validations';

class CarryOverModal extends Component {
  handleSubmitClicked(data) {
    const { updateMemberCarryOver, memberId } = this.props;
    updateMemberCarryOver(memberId, data);
    this.handleCloseModal();
  }

  handleCloseModal() {
    this.props.toggleModal();
  }

  render() {
    const { handleSubmit, showModal, toggleModal } = this.props;

    return (
      <Modal className="away-modal" isOpen={showModal} toggle={toggleModal}>
        <ModalBody className="away-modal-body">
          <div className="away-modal-line">
            <h3>Carry Over</h3>
          </div>
          <Row>
            <Col sm="12" md="4">
              <Field id="carryOverFrom" name="carryover_from" label="From" component={TextInput} />
            </Col>
            <Col sm="12" md="4">
              <Field id="carryOverTo" name="carryover_to" label="To" component={TextInput} />
            </Col>
            <Col sm="12" md="4">
              <Field id="carryOverDays" name="carryover_days" label="Days" component={TextInput} />
            </Col>
            <Col sm="12">
              <Field
                id="carryOverReason"
                name="carryover_reason"
                label="Reason"
                component={TextArea}
                type="textarea"
                rows="3"
              />
            </Col>
          </Row>
          <div className="away-modal-buttons">
            <Button
              className="btn btn-primary"
              onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
            >
              Proceed
            </Button>
            <Button onClick={toggleModal} className="btn-link btn-grey">
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

const CarryOverForm = reduxForm({
  form: 'CarryOverForm',
  enableReinitialize: true,
  validate,
})(CarryOverModal);

export default CarryOverForm;
