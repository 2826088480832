import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import NavbarAway from '../ui/Navbar';

const requireAuth = (Component, allowedRights) => {
  const AuthenticatedComponent = props =>
    props.isAuthenticated ? (
      props.constantsFetched && props.userDataFetched ? (
        !allowedRights ||
        (allowedRights && props.userRights.some(v => allowedRights.indexOf(v) !== -1)) ? (
          <Fragment>
            <NavbarAway />
            <div className="container">
              <Component {...props} />
            </div>
          </Fragment>
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      ) : null
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            from: props.location.pathname,
            params: props.location.search,
          },
        }}
      />
    );

  const mapStoreToProps = store => ({
    userRights: store.signedInUser.userRights,
    isAuthenticated: store.auth.isAuthenticated,
    constantsFetched: store.constants.constantsFetched,
    userDataFetched: store.signedInUser.userDataFetched,
  });

  return connect(mapStoreToProps)(AuthenticatedComponent);
};

export default requireAuth;
