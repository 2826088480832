// configure API endpoint based on .env
const getApiURL = () => {
  const PRODUCTION_API_URL = 'https://api-away.lateral-inc.com/';
  const STAGING_API_URL = 'https://api-staging-away.lateral-inc.com/';
  // const LOCAL_API_URL = 'http://localhost:8000/';

  if (process.env.REACT_APP_API_URL === 'production') {
    return PRODUCTION_API_URL;
  }
  return STAGING_API_URL;
};

export const baseURL = getApiURL();

export const PAGINATION_REQUESTS_PER_PAGE = 20;

export const CALENDARS = {
  vacations: 'vacations',
  anniversaries: 'anniversaries',
};

export const userPermissions = {
  regular: [],
  lead: ['hasApproverRights'],
  partner: ['hasApproverRights', 'hasStudioManagementRights'],
  hr: ['hasApproverRights', 'hasManagementRights'],
  admin: ['hasApproverRights', 'hasManagementRights', 'hasAdministrativeRights'],
};

export const CALENDAR_COLORS = {
  defaultEvent: '#F6F8FC',
  firstYearAnniversary: '#8978CE',
  anniversary: '#2176DC',
  birthday: '#ED6E68',
};

export const tshirt_sizes = [
  { value: '', label: 'Not selected' },
  { value: 'xs_w', label: 'XS Women' },
  { value: 'xs_m', label: 'XS Man' },
  { value: 's_w', label: 'S Women' },
  { value: 's_m', label: 'S Man' },
  { value: 'm_w', label: 'M Women' },
  { value: 'm_m', label: 'M Man' },
  { value: 'l_w', label: 'L Women' },
  { value: 'l_m', label: 'L Man' },
  { value: 'xl_w', label: 'XL Women' },
  { value: 'xl_m', label: 'XL Man' },
  { value: 'xxl_w', label: 'XXL Women' },
  { value: 'xxl_m', label: 'XXL Man' },
];
