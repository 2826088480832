import React from 'react';
import PropTypes from 'prop-types';
import './avatar.scss';
import { getShortName, getAlias } from '../../../utils/Avatar';

import { Link } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import AvatarColor from '../AvatarColor';

const Avatar = ({
  size,
  title,
  avatar,
  userId,
  hasLabel,
  isSignedInUser,
  isCenter,
  isEditable,
  isLink,
  onEdit,
  onDelete,
  isChangingAvatar,
}) => {
  let avatarCircle;
  const aliasHere = title && getAlias(title);
  const shortTitleHere = title && getShortName(title);

  if (avatar) {
    avatarCircle = <img src={avatar} alt={aliasHere} title={title} />;
  } else {
    avatarCircle = aliasHere && (
      <div className="avatar-placeholder-text" title={title}>
        {aliasHere}
      </div>
    );
  }

  const editBtn = (
    <span className="edit-icon" onClick={onEdit}>
      <FontAwesome name="edit" className="fa-small" />
    </span>
  );

  const deleteBtn = (
    <span
      className={`delete-icon ${!!avatar ? '' : 'not-allowed'}`}
      onClick={!!avatar && userId ? () => onDelete(userId) : () => {}}
    >
      <FontAwesome name="trash" className="fa-small" />
    </span>
  );

  const editAvatarCircle = isEditable && (
    <div className="avatar-edit-wrapper">
      <div className="avatar-edit-flag">
        <FontAwesome name="camera" className="fa-small" />
      </div>
      <div className="avatar-edit-container">
        {editBtn}
        {deleteBtn}
      </div>
    </div>
  );

  let avatarContainer;
  const avatarContent = (
    <div>
      <AvatarColor size={size} avatar={avatar} title={title} isChangingAvatar={isChangingAvatar}>
        {avatarCircle}
        {isEditable && editAvatarCircle}
      </AvatarColor>
      {hasLabel && shortTitleHere && <div className="avatar-label">{shortTitleHere}</div>}
    </div>
  );

  if (isLink) {
    avatarContainer = (
      <Link to={isSignedInUser ? `/profile` : `/people/${userId}`}>{avatarContent}</Link>
    );
  } else {
    avatarContainer = <div>{avatarContent}</div>;
  }

  return (
    <div
      className={`away-avatar ${isCenter ? 'avatar-center' : ''} ${
        isLink ? 'away-avatar-link' : ''
      }`}
    >
      {avatarContainer}
    </div>
  );
};

Avatar.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']),
  title: PropTypes.string,
  avatar: PropTypes.string,
  hasLabel: PropTypes.bool,
  isCenter: PropTypes.bool,
};

Avatar.defaultProps = {
  size: 'md',
  isCenter: false,
};

export default Avatar;
