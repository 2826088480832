import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'reactstrap';
import { fetchTeams } from '../../redux/actions/actualTeamActions';
import TeamItem from './components/TeamItem';
import EditTeamModal from './components/EditTeamModal';
import CreateTeamForm from './components/CreateTeamForm';
import CustomLoader from '../../components/ui/Loader';
import './teams.scss';

class Teams extends Component {
  state = {
    selectedTeamToEdit: null,
    editIsOpen: false,
  };

  componentDidMount() {
    const { fetchTeams } = this.props;
    fetchTeams();
  }

  toggleEditModal = () => {
    this.setState({ editIsOpen: !this.state.editIsOpen });
  };

  handleEditTeam = async selectedTeam => {
    await this.setState({ selectedTeamToEdit: selectedTeam });
    await this.toggleEditModal();
  };

  renderTeams = () => {
    const { teams } = this.props;
    if (teams.length === 0)
      return (
        <Row>
          <Col md={{ size: 12 }}>
            <h3>No teams have been defined yet!</h3>
          </Col>
        </Row>
      );
    return (
      <Row>
        {teams.map(team => (
          <TeamItem key={team.id} teamId={team.id} edit={this.handleEditTeam} />
        ))}
      </Row>
    );
  };

  render() {
    const { isFetching } = this.props;
    const { editIsOpen, selectedTeamToEdit } = this.state;
    return (
      <div className="away-manage-team">
        <h1>Manage Teams</h1>
        <CreateTeamForm />
        {isFetching ? <CustomLoader /> : this.renderTeams()}
        <EditTeamModal
          showModal={editIsOpen}
          toggleModal={this.toggleEditModal}
          team={selectedTeamToEdit}
        />
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  teams: store.actualTeams.list,
  isFetching: store.actualTeams.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchTeams: bindActionCreators(fetchTeams, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(Teams);
