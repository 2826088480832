import React, { Fragment, Component } from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomLoader from '../../components/ui/Loader';
import Header from '../../components/ui/Header';
import UserProfileCard from '../../components/ui/UserProfileCard';

import { fetchTeamMember } from '../../redux/actions/teamActions';

class Person extends Component {
  constructor(props) {
    super(props);

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const { match, fetchTeamMember } = this.props;
    fetchTeamMember(match.params.id);
  }

  handleBack() {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { teamMember, studios, isFetching } = this.props;
    if (!teamMember.id && !isFetching)
      return <Alert type="danger">Whoops, looks like something went wrong.</Alert>;
    const teamMemberStudio =
      teamMember && studios && studios.filter(studio => studio.value === teamMember.studio_id);
    const studioLabel = teamMemberStudio && teamMemberStudio[0] && teamMemberStudio[0].label;

    const userData = {
      ...teamMember,
      studioLabel,
    };

    if (isFetching) {
      return <CustomLoader />;
    }

    return (
      <Fragment>
        <Header title={userData.name} hasRequestDay hasBack onBack={this.handleBack} />
        <UserProfileCard userData={userData} />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  studios: store.constants.studios,
  teamMember: store.team.member,
  isFetching: store.team.isFetching,
});

const mapDispatchToProps = dispatch => ({
  fetchTeamMember: bindActionCreators(fetchTeamMember, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(Person);
