import React, { Children } from 'react';

const DayWrapper = ({ children, value }) =>
  React.cloneElement(Children.only(children), {
    style: {
      ...children.style,
      backgroundColor: 'lightgreen',
    },
  });

export default DayWrapper;
