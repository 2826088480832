export const LOGIN_USER_START = 'login_user_start';
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_ERROR = 'login_user_error';
export const LOGOUT_USER = 'logout_user';

export const VALIDATE_TOKEN_START = 'validate_token_start';
export const VALIDATE_TOKEN_SUCCESS = 'validate_token_success';
export const VALIDATE_TOKEN_ERROR = 'validate_token_error';
export const SKIP_TOKEN_VALIDATION = 'skip_token_validation';

export const FETCH_TEAMS_START = 'fetch_teams_start';
export const FETCH_TEAMS_SUCCESS = 'fetch_teams_success';
export const FETCH_TEAMS_ERROR = 'fetch_teams_error';

export const CREATE_TEAM_START = 'create_team_start';
export const CREATE_TEAM_SUCCESS = 'create_team_success';
export const CREATE_TEAM_ERROR = 'create_team_error';

export const FETCH_TEAM_DETAILS_START = 'fetch_team_details_start';
export const FETCH_TEAM_DETAILS_SUCCESS = 'fetch_team_details_success';
export const FETCH_TEAM_DETAILS_ERROR = 'fetch_team_details_error';

export const UPDATE_TEAM_START = 'update_team_start';
export const UPDATE_TEAM_SUCCESS = 'update_team_success';
export const UPDATE_TEAM_ERROR = 'update_team_error';

export const DELETE_TEAM_START = 'delete_team_start';
export const DELETE_TEAM_SUCCESS = 'delete_team_success';
export const DELETE_TEAM_ERROR = 'delete_team_error';

export const ADD_USERS_TO_TEAM_START = 'add_users_to_team_start';
export const ADD_USERS_TO_TEAM_SUCCESS = 'add_users_to_team_success';
export const ADD_USERS_TO_TEAM_ERROR = 'add_users_to_team_error';

export const REMOVE_USERS_FROM_TEAM_START = 'remove_users_from_team_start';
export const REMOVE_USERS_FROM_TEAM_SUCCESS = 'remove_users_from_team_success';
export const REMOVE_USERS_FROM_TEAM_ERROR = 'remove_users_from_team_error';

export const FETCH_TEAM_LIST_START = 'fetch_team_list_start';
export const FETCH_TEAM_LIST_SUCCESS = 'fetch_team_list_success';
export const FETCH_TEAM_LIST_ERROR = 'fetch_team_list_error';

export const FETCH_TEAM_ADMIN_LIST_START = 'fetch_team_admin_list_start';
export const FETCH_TEAM_ADMIN_LIST_SUCCESS = 'fetch_team_admin_list_success';
export const FETCH_TEAM_ADMIN_LIST_ERROR = 'fetch_team_admin_list_error';

export const FETCH_TEAM_USERS_START = 'fetch_team_users_start';
export const FETCH_TEAM_USERS_SUCCESS = 'fetch_team_users_success';
export const FETCH_TEAM_USERS_ERROR = 'fetch_team_users_error';

export const FETCH_TEAM_MEMBER_START = 'fetch_team_member_start';
export const FETCH_TEAM_MEMBER_SUCCESS = 'fetch_team_member_success';
export const FETCH_TEAM_MEMBER_ERROR = 'fetch_team_member_error';

export const RESET_TEAM_ADMIN_MEMBER = 'reset_team_admin_member';

export const FETCH_TEAM_ADMIN_MEMBER_START = 'fetch_admin_team_member_start';
export const FETCH_TEAM_ADMIN_MEMBER_SUCCESS = 'fetch_admin_team_member_success';
export const FETCH_TEAM_ADMIN_MEMBER_ERROR = 'fetch_admin_team_member_error';

export const FETCH_SIGNEDIN_USER_START = 'fetch_signedin_user_start';
export const FETCH_SIGNEDIN_USER_SUCCESS = 'fetch_signedin_user_success';
export const FETCH_SIGNEDIN_USER_ERROR = 'fetch_signedin_user_error';

export const SET_TEAM_GRID = 'set_team_grid';
export const SET_TEAM_PAGE = 'set_team_page';

export const SET_TEAM_SELECTED_ROLE = 'set_team_selected_role';
export const SET_TEAM_SELECTED_STUDIO = 'set_team_selected_studio';
export const SET_PEOPLE_SELECTED_TEAM = 'set_people_selected_team';
export const SET_TEAM_SELECTED_SORTBY = 'set_team_selected_sortby';

export const SET_TEAM_ADMIN_PAGE = 'set_team_admin_page';
export const SET_TEAM_ADMIN_PAGE_SIZE = 'set_team_admin_page_size';
export const SET_TEAM_ADMIN_SELECTED_STUDIO = 'set_team_admin_selected_studio';
export const SET_TEAM_ADMIN_SELECTED_YEAR = 'set_team_admin_selected_year';
export const SET_TEAM_ADMIN_SELECTED_FIELD = 'set_team_admin_selected_field';
export const SET_TEAM_ADMIN_SELECTED_ORDER = 'set_team_admin_selected_order';
export const SET_TEAM_ADMIN_SELECTED_STATUS = 'set_team_admin_selected_status';

export const FETCH_DASHBOARD_VACATIONS_START = 'fetch_dashboard_vacations_start';
export const FETCH_DASHBOARD_VACATIONS_SUCCESS = 'fetch_dashboard_vacations_success';
export const FETCH_DASHBOARD_VACATIONS_ERROR = 'fetch_dashboard_vacations_error';

export const FETCH_PUBLIC_HOLIDAYS_START = 'fetch_public_holidays_start';
export const FETCH_PUBLIC_HOLIDAYS_SUCCESS = 'fetch_public_holidays_success';
export const FETCH_PUBLIC_HOLIDAYS_ERROR = 'fetch_public_holidays_error';
export const FETCH_PUBLIC_HOLIDAYS_YEAR_SUCCESS = 'fetch_public_holidays_year_success';

export const FETCH_ALL_PUBLIC_HOLIDAYS_START = 'fetch_all_public_holidays_start';
export const FETCH_ALL_PUBLIC_HOLIDAYS_SUCCESS = 'fetch_all_public_holidays_success';
export const FETCH_ALL_PUBLIC_HOLIDAYS_ERROR = 'fetch_all_public_holidays_error';

export const HOLIDAY_WORK_REQUEST_START = 'holiday_work_request_start';
export const HOLIDAY_WORK_REQUEST_SUCCESS = 'holiday_work_request_success';
export const HOLIDAY_WORK_REQUEST_ERROR = 'holiday_work_request_error';

export const HOLIDAY_WORK_REQUEST_APPROVE_START = 'holiday_work_request_approve_start';
export const HOLIDAY_WORK_REQUEST_APPROVE_SUCCESS = 'holiday_work_request_approve_success';
export const HOLIDAY_WORK_REQUEST_APPROVE_ERROR = 'holiday_work_request_approve_error';

export const HOLIDAY_WORK_REQUEST_REJECT_START = 'holiday_work_request_reject_start';
export const HOLIDAY_WORK_REQUEST_REJECT_SUCCESS = 'holiday_work_request_reject_success';
export const HOLIDAY_WORK_REQUEST_REJECT_ERROR = 'holiday_work_request_reject_error';

export const HOLIDAY_WORK_REQUEST_DELETE_START = 'holiday_work_request_delete_start';
export const HOLIDAY_WORK_REQUEST_DELETE_SUCCESS = 'holiday_work_request_delete_success';
export const HOLIDAY_WORK_REQUEST_DELETE_ERROR = 'holiday_work_request_delete_error';

export const FETCH_DASHBOARD_WFH_START = 'fetch_dashboard_wfh_start';
export const FETCH_DASHBOARD_WFH_SUCCESS = 'fetch_dashboard_wfh_success';
export const FETCH_DASHBOARD_WFH_ERROR = 'fetch_dashboard_wfh_error';

export const VACATION_REQUEST_START = 'vacation_request_start';
export const VACATION_REQUEST_SUCCESS = 'vacation_request_success';
export const VACATION_REQUEST_ERROR = 'vacation_request_error';

export const ACTION_VACATION_REQUEST_START = 'action_vacation_request_start';
export const ACTION_VACATION_REQUEST_SUCCESS = 'action_vacation_request_success';
export const ACTION_VACATION_REQUEST_ERROR = 'action_vacation_request_error';

export const FETCH_VACATION_REQUEST_START = 'fetch_vacation_request_start';
export const FETCH_VACATION_REQUEST_SUCCESS = 'fetch_vacation_request_success';
export const FETCH_VACATION_REQUEST_ERROR = 'fetch_vacation_request_error';
export const RESET_FETCH_REQUEST = 'fetch_vacation_request';

export const VACATION_REQUEST_ANSWER_START = 'vacation_request_answer_start';
export const VACATION_REQUEST_ANSWER_SUCCESS = 'vacation_request_answer_success';
export const VACATION_REQUEST_ANSWER_ERROR = 'vacation_request_answer_error';

export const CHECK_IS_PUBLIC_HOLIDAY_REQUEST_START = 'check_is_public_holiday_request_start';
export const CHECK_IS_PUBLIC_HOLIDAY_REQUEST_SUCCESS = 'check_is_public_holiday_request_success';
export const CHECK_IS_PUBLIC_HOLIDAY_REQUEST_ERROR = 'check_is_public_holiday_request_error';

export const CLEAN_REQUEST_ERRORS = 'clean_request_errors';

export const FETCH_CONSTANTS_START = 'fetch_constants_start';
export const FETCH_CONSTANTS_SUCCESS = 'fetch_constants_success';
export const FETCH_CONSTANTS_ERROR = 'fetch_constants_error';

export const SET_SELECTED_VACATION_TYPE = 'set_selected_vacation_type';
export const SET_SELECTED_STUDIO = 'set_selected_studio';
export const SET_SELECTED_TEAM = 'set_selected_team';

export const FETCH_SIGNED_IN_USER_VACATIONS_START = 'fetch_signed_in_user_vacations_start';
export const FETCH_SIGNED_IN_USER_VACATIONS_SUCCESS = 'fetch_signed_in_user_vacations_success';
export const FETCH_SIGNED_IN_USER_VACATIONS_ERROR = 'fetch_signed_in_user_vacations_error';
export const EDIT_SIGNED_IN_USER_PENDING_VACATIONS = 'edit_signedin_user_pending_vacations';

export const FETCH_TEAM_VACATIONS_START = 'fetch_team_vacations_start';
export const FETCH_TEAM_VACATIONS_SUCCESS = 'fetch_team_vacations_success';
export const FETCH_TEAM_VACATIONS_ERROR = 'fetch_team_vacations_error';

export const FETCH_USER_AVAILABLE_VACATIONS_START = 'fetch_user_available_vacations_start';
export const FETCH_USER_AVAILABLE_VACATIONS_SUCCESS = 'fetch_user_available_vacations_success';
export const FETCH_USER_AVAILABLE_VACATIONS_ERROR = 'fetch_user_available_vacations_error';

export const UPDATE_USER_PROFILE_START = 'update_user_profile_start';
export const UPDATE_USER_PROFILE_SUCCESS = 'update_user_profile_success';
export const UPDATE_USER_PROFILE_ERROR = 'update_user_profile_error';

export const UPDATE_USER_WORKING_LOCATION_START = 'update_user_working_location_start';
export const UPDATE_USER_WORKING_LOCATION_SUCCESS = 'update_user_working_location_success';
export const UPDATE_USER_WORKING_LOCATION_ERROR = 'update_user_working_location_error';

export const UPDATE_USER_PASSWORD_START = 'update_user_password_start';
export const UPDATE_USER_PASSWORD_SUCCESS = 'update_user_password_success';
export const UPDATE_USER_PASSWORD_ERROR = 'update_user_password_error';

export const FETCH_TEAM_LEADS_START = 'fetch_team_leads_from_studio_start';
export const FETCH_TEAM_LEADS_SUCCESS = 'fetch_team_leads_from_studio_success';
export const FETCH_TEAM_LEADS_ERROR = 'fetch_team_leads_from_studio_error';

export const FETCH_USER_LOGS_START = 'fetch_user_logs_start';
export const FETCH_USER_LOGS_SUCCESS = 'fetch_user_logs_success';
export const FETCH_USER_LOGS_ERROR = 'fetch_user_logs_error';

export const SET_USER_LOGS_PAGE = 'set_user_logs_page';

export const FETCH_USER_VACATIONS_HISTORY_START = 'fetch_user_vacations_history_start';
export const FETCH_USER_VACATIONS_HISTORY_SUCCESS = 'fetch_user_vacations_history_success';
export const FETCH_USER_VACATIONS_HISTORY_ERROR = 'fetch_user_vacations_history_error';

export const SET_USER_VACATIONS_HISTORY_PAGE = 'set_user_vacations_history_page';

export const TOGGLE_REQUEST_MODAL = 'toggle_request_modal';
export const SET_REQUEST_MODAL_INITIAL_STATE = 'set_request_modal_initial_state';
export const SET_REQUEST_MODAL_VACATION_ID = 'set_request_modal_vacation_id';

export const TOGGLE_GENERAL_ALERT = 'toggle_general_alert';
export const SET_GENERAL_ALERT_MESSAGE = 'set_general_alert_message';

export const UPDATE_TEAM_ADMIN_MEMBER_PROFILE_START = 'update_team_admin_member_profile_start';
export const UPDATE_TEAM_ADMIN_MEMBER_PROFILE_SUCCESS = 'update_team_admin_member_profile_success';
export const UPDATE_TEAM_ADMIN_MEMBER_PROFILE_ERROR = 'update_team_admin_member_profile_error';

export const UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_START = 'update_team_admin_member_carryover_start';
export const UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_SUCCESS =
  'update_team_admin_member_carryover_success';
export const UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_ERROR = 'update_team_admin_member_carryover_error';

export const FETCH_VACATION_TYPES_LIST_START = 'fetch_vacation_types_list_start';
export const FETCH_VACATION_TYPES_LIST_SUCCESS = 'fetch_vacation_types_list_success';
export const FETCH_VACATION_TYPES_LIST_ERROR = 'fetch_vacation_types_list_error';

export const ADD_VACATION_TYPE_START = 'add_vacation_type_start';
export const ADD_VACATION_TYPE_SUCCESS = 'add_vacation_type_success';
export const ADD_VACATION_TYPE_ERROR = 'add_vacation_type_error';

export const UPDATE_VACATION_TYPE_START = 'update_vacation_type_start';
export const UPDATE_VACATION_TYPE_SUCCESS = 'update_vacation_type_success';
export const UPDATE_VACATION_TYPE_ERROR = 'update_vacation_type_error';

export const DELETE_VACATION_TYPE_START = 'delete_vacation_type_start';
export const DELETE_VACATION_TYPE_SUCCESS = 'delete_vacation_type_success';
export const DELETE_VACATION_TYPE_ERROR = 'delete_vacation_type_error';

export const REMOVE_VACATION_TYPE = 'remove_vacation_type';

export const UPDATE_TEAM_ADMIN_MEMBER_STATS_START = 'update_team_admin_member_stats_start';
export const UPDATE_TEAM_ADMIN_MEMBER_STATS_SUCCESS = 'update_team_admin_member_stats_success';
export const UPDATE_TEAM_ADMIN_MEMBER_STATS_ERROR = 'update_team_admin_member_stats_error';

export const FETCH_STUDIOS_LIST_START = 'fetch_studios_list_start';
export const FETCH_STUDIOS_LIST_SUCCESS = 'fetch_studios_list_success';
export const FETCH_STUDIOS_LIST_ERROR = 'fetch_studios_list_error';

export const ADD_STUDIO_START = 'add_studio_start';
export const ADD_STUDIO_SUCCESS = 'add_studio_success';
export const ADD_STUDIO_ERROR = 'add_studio_error';

export const UPDATE_STUDIO_START = 'update_studio_start';
export const UPDATE_STUDIO_SUCCESS = 'update_studio_success';
export const UPDATE_STUDIO_ERROR = 'update_studio_error';

export const DELETE_STUDIO_START = 'delete_studio_start';
export const DELETE_STUDIO_SUCCESS = 'delete_studio_success';
export const DELETE_STUDIO_ERROR = 'delete_studio_error';

export const REMOVE_STUDIO = 'remove_studio';

export const RESET_UI = 'reset_ui';

export const FETCH_COMPANY_DATA_START = 'fetch_company_data_start';
export const FETCH_COMPANY_DATA_SUCCESS = 'fetch_company_data_success';
export const FETCH_COMPANY_DATA_ERROR = 'fetch_company_data_error';

export const FETCH_MEMBER_VACATION_DEFAULTS_START = 'fetch_member_vacation_defaults_start';
export const FETCH_MEMBER_VACATION_DEFAULTS_SUCCESS = 'fetch_member_vacation_defaults_success';
export const FETCH_MEMBER_VACATION_DEFAULTS_ERROR = 'fetch_member_vacation_defaults_error';

export const UPDATE_AVATAR_START = 'update_avatar_start';
export const UPDATE_AVATAR_SUCCESS = 'update_avatar_success';
export const UPDATE_AVATAR_ERROR = 'update_avatar_error';

export const DELETE_AVATAR_START = 'delete_avatar_start';
export const DELETE_AVATAR_SUCCESS = 'delete_avatar_success';
export const DELETE_AVATAR_ERROR = 'delete_avatar_error';

export const UPDATE_TEAM_ADMIN_MEMBER_AVATAR_START = 'update_team_admin_member_avatar_start';
export const UPDATE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS = 'update_team_admin_member_avatar_success';
export const UPDATE_TEAM_ADMIN_MEMBER_AVATAR_ERROR = 'update_team_admin_member_avatar_error';

export const DELETE_TEAM_ADMIN_MEMBER_AVATAR_START = 'delete_team_admin_member_avatar_start';
export const DELETE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS = 'delete_team_admin_member_avatar_success';
export const DELETE_TEAM_ADMIN_MEMBER_AVATAR_ERROR = 'delete_team_admin_member_avatar_error';

export const CREATE_NEW_USER_START = 'create_new_user_start';
export const CREATE_NEW_USER_SUCCESS = 'create_new_user_success';
export const CREATE_NEW_USER_ERROR = 'create_new_user_error';

export const SEND_NEW_PASSWORD_START = 'send_new_password_start';
export const SEND_NEW_PASSWORD_SUCCESS = 'send_new_password_success';
export const SEND_NEW_PASSWORD_ERROR = 'send_new_password_error';

export const FETCH_RESET_PASSWORD_START = 'fetch_reset_password_start';
export const FETCH_RESET_PASSWORD_SUCCESS = 'fetch_reset_password_success';
export const FETCH_RESET_PASSWORD_ERROR = 'fetch_reset_password_error';

export const SEND_RESET_PASSWORD_EMAIL_START = 'send_reset_password_email_start';
export const SEND_RESET_PASSWORD_EMAIL_SUCCESS = 'send_reset_password_email_success';
export const SEND_RESET_PASSWORD_EMAIL_ERROR = 'send_reset_password_email_error';

export const RESET_FORGOT_PASSWORD_FLOW = 'reset_forgot_password_flow';

export const FETCH_DASHBOARD_ANNIVERSARIES_START = 'fetch_dashboard_anniversaries_start';
export const FETCH_DASHBOARD_ANNIVERSARIES_SUCCESS = 'fetch_dashboard_anniversaries_success';
export const FETCH_DASHBOARD_ANNIVERSARIES_ERROR = 'fetch_dashboard_anniversaries_error';

export const TOGGLE_SELECTED_CALENDAR = 'toggle_selected_calendar';
export const TOGGLE_SHOW_ALL_PUBLIC_HOLIDAYS = 'toggle_show_all_public_holidays';

export const USER_CONTACTS_REPORT_START = 'user_contacts_report_start';
export const USER_CONTACTS_REPORT_SUCCESS = 'user_contacts_report_success';
export const USER_CONTACTS_REPORT_ERROR = 'user_contacts_report_error';

export const TIMESHEETS_REPORT_START = 'timesheets_report_start';
export const TIMESHEETS_REPORT_SUCCESS = 'timesheets_report_success';
export const TIMESHEETS_REPORT_ERROR = 'timesheets_report_error';

export const VACATIONS_REPORT_START = 'vacations_report_start';
export const VACATIONS_REPORT_SUCCESS = 'vacations_report_success';
export const VACATIONS_REPORT_ERROR = 'vacations_report_error';

export const WFH_REPORT_START = 'wfh_report_start';
export const WFH_REPORT_SUCCESS = 'wfh_report_success';
export const WFH_REPORT_ERROR = 'wfh_report_error';

export const SET_USER_RIGHTS = 'set_user_rights';

export const FETCH_STATES_FOR_COUNTRY_SUCCESS = 'fetch_states_for_country_success';
export const FETCH_STATES_FOR_COUNTRY_ERROR = 'fetch_states_for_country_error';

export const POPULATE_USERS_VACATION_DEFAULTS_START = 'populate_users_vacation_defaults_start';
export const POPULATE_USERS_VACATION_DEFAULTS_SUCCESS = 'populate_users_vacation_defaults_success';
export const POPULATE_USERS_VACATION_DEFAULTS_ERROR = 'populate_users_vacation_defaults_error';
