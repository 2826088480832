const validate = values => {
  const errors = [];
  if (!values.currentPassword) {
    errors.currentPassword = 'Please enter your current password';
  }
  if (!values.newPassword) {
    errors.newPassword = 'Please enter your new password';
  } else if (values.newPassword.length < 8) {
    errors.newPassword = 'Password must be at least 8 characters';
  }
  if (!values.confirmNewPassword) {
    errors.confirmNewPassword = 'Please confirm your new password';
  } else if (values.newPassword !== values.confirmNewPassword) {
    errors.confirmNewPassword = 'Passwords must match';
  }

  return errors;
};

export default validate;
