import React, { Fragment } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';

const ConfirmModal = ({ isOpen, toggle, onConfirm, header, bodyText }) => {
  return (
    <Modal className="away-modal" isOpen={isOpen} toggle={toggle}>
      <ModalBody className="away-modal-body">
        <Fragment>
          <div className="away-modal-line">
            <h3>{header}</h3>
          </div>
        </Fragment>
        <div className="away-modal-buttons">
          <Button color="primary" onClick={onConfirm}>
            Yes
          </Button>
          <Button className="btn-link btn-grey" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmModal;
