import React from 'react';
import { Row, Col } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

const StudioListItem = ({ studioData, onEdit, onDelete }) => {
  const editBtn = (
    <span className="edit-icon" onClick={() => onEdit(studioData)}>
      <FontAwesome name="edit" className="fa-small" />
    </span>
  );

  const deleteBtn = (
    <span className="delete-icon" onClick={() => onDelete(studioData)}>
      <FontAwesome name="trash" className="fa-small" />
    </span>
  );

  return (
    <div className="list-item">
      <Row className="li-row">
        <Col md={{ size: 4 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Studio name</span>
        </Col>
        <Col md={{ size: 4 }} className="li-cell">
          {studioData.name}
        </Col>
        <Col md={{ size: 3 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Studio partner</span>
        </Col>
        <Col md={{ size: 3 }} className="li-cell">
          {studioData.partners && studioData.partners.length
            ? studioData.partners.map(partner => partner.name).join(', ')
            : '-'}
        </Col>
        <Col md={{ size: 3 }} className="li-cell-mobile">
          <span className="li-cell-mobile-text">Company name</span>
        </Col>
        <Col md={{ size: 3 }} className="li-cell">
          {studioData.company_name ? studioData.company_name : '-'}
        </Col>
        <Col md={{ size: 2 }} className="li-cell li-cell-end">
          <div className="li-action-buttons">
            {editBtn}
            {deleteBtn}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StudioListItem;
