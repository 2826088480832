import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomLoader from '../../components/ui/Loader';
import Header from '../../components/ui/Header';
import AdminUserProfileCard from './components/AdminUserProfileCard';

import {
  fetchTeamAdminMember,
  fetchMemberVacationDefaults,
  updateTeamAdminMemberProfile,
  updateTeamAdminMemberStats,
  updateTeamAdminMemberCarryOver,
  resetTeamAdminMember,
} from '../../redux/actions/teamAdminActions';

class TeamAdminMember extends Component {
  constructor(props) {
    super(props);

    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    const { match, fetchTeamAdminMember, fetchMemberVacationDefaults } = this.props;
    fetchTeamAdminMember(match.params.id, match.params.year);
    fetchMemberVacationDefaults(match.params.id, match.params.year);
  }

  componentWillUnmount() {
    this.props.resetTeamAdminMember();
  }

  handleBack() {
    const { history } = this.props;
    history.push('/team-admin');
  }

  render() {
    const {
      teamAdminMember,
      teamLeads,
      roles,
      teamAdminMemberVacationDefaults,
      studios,
      isFetching,
      match,
      teamAdminMemberHeaders,
      updateTeamAdminMemberProfile,
      updateTeamAdminMemberStats,
      fetchTeamAdminMember,
      updateTeamAdminMemberCarryOver,
    } = this.props;

    if (isFetching) {
      return <CustomLoader />;
    }

    const teamAdminMemberStudio =
      teamAdminMember &&
      studios &&
      studios.filter(studio => studio.value === teamAdminMember.studio_id);
    const studioLabel =
      teamAdminMemberStudio && teamAdminMemberStudio[0] && teamAdminMemberStudio[0].label;

    const userTeamLead =
      teamAdminMember &&
      teamLeads &&
      teamLeads.filter(teamLead => teamLead.id === teamAdminMember.team_lead);
    const userTeamLeadLabel = userTeamLead && userTeamLead[0] && userTeamLead[0].name;

    const userRole =
      teamAdminMember &&
      teamAdminMember.role &&
      roles.filter(role => role.value === teamAdminMember.role);
    const userRoleLabel = userRole && userRole[0] && userRole[0].label;

    const userData = {
      ...teamAdminMember,
      studioLabel,
      role_label: userRoleLabel,
      teamLead: {
        id: teamAdminMember.team_lead,
        name: userTeamLeadLabel
          ? userTeamLeadLabel
          : teamAdminMember && teamAdminMember.teamLead && teamAdminMember.teamLead.name,
        avatar: teamAdminMember && teamAdminMember.teamLead && teamAdminMember.teamLead.avatar,
      },
      statsYear: match.params.year,
    };

    return (
      <Fragment>
        <Header title={userData.name} hasBack onBack={this.handleBack} />
        <AdminUserProfileCard
          userData={userData}
          teamAdminMemberVacationDefaults={teamAdminMemberVacationDefaults}
          updateTeamAdminMemberProfile={updateTeamAdminMemberProfile}
          updateTeamAdminMemberStats={updateTeamAdminMemberStats}
          fetchTeamAdminMember={fetchTeamAdminMember}
          teamAdminMemberHeaders={teamAdminMemberHeaders}
          updateTeamAdminMemberCarryOver={updateTeamAdminMemberCarryOver}
        />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  studios: store.constants.studios,
  roles: store.constants.roles,
  vacationTypes: store.constants.vacationTypes,
  teamLeads: store.team.teamLeads,
  teamAdminMember: store.teamAdmin.adminMember,
  teamAdminMemberHeaders: store.teamAdmin.adminMemberHeaders,
  teamAdminMemberVacationDefaults: store.teamAdmin.adminMemberVacationDefaults,
  isFetching: store.teamAdmin.isFetching,
});

const mapDispatchToProps = dispatch => ({
  resetTeamAdminMember: bindActionCreators(resetTeamAdminMember, dispatch),
  fetchTeamAdminMember: bindActionCreators(fetchTeamAdminMember, dispatch),
  fetchMemberVacationDefaults: bindActionCreators(fetchMemberVacationDefaults, dispatch),
  updateTeamAdminMemberProfile: bindActionCreators(updateTeamAdminMemberProfile, dispatch),
  updateTeamAdminMemberStats: bindActionCreators(updateTeamAdminMemberStats, dispatch),
  updateTeamAdminMemberCarryOver: bindActionCreators(updateTeamAdminMemberCarryOver, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(TeamAdminMember);
