import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomLoader from '../../../../components/ui/Loader';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import StudiosList from './components/StudiosList';
import StudioModal from './components/StudioModal';

import { fetchStudiosList, deleteStudio } from '../../../../redux/actions/studiosSettingsActions';

class StudiosSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showStudioModal: false,
      showDeleteModal: false,
      isEditMode: false,
      selectedStudio: {
        id: 0,
        name: '',
        company_name: '',
        company_address: '',
        company_registration_code: '',
        partners: [],
      },
      studioId: 0,
    };

    this.toggleModal = this.toggleModal.bind(this);

    this.handleCreate = this.handleCreate.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const { fetchStudiosList } = this.props;
    fetchStudiosList();
  }

  toggleModal = () => {
    this.setState({
      showStudioModal: !this.state.showStudioModal,
    });
  };

  toggleDeleteModal = () => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
    });
  };

  handleCreate = () => {
    this.setState({
      ...this.state,
      isEditMode: false,
      selectedStudio: {
        id: 0,
        name: '',
        company_name: '',
        company_address: '',
        company_registration_code: '',
        partners: [],
      },
    });
    this.toggleModal();
  };

  handleEdit = data => {
    const partners = data.partners.map(partner => {
      return {
        value: partner.id,
        label: partner.name,
      };
    });

    this.setState({
      ...this.state,
      isEditMode: true,
      selectedStudio: {
        id: data.id,
        name: data.name,
        company_name: data.company_name,
        company_address: data.company_address,
        company_registration_code: data.company_registration_code,
        partners,
      },
    });
    this.toggleModal();
  };

  handleDelete = data => {
    this.setState({
      ...this.state,
      studioId: data.id,
    });
    this.toggleDeleteModal();
  };

  onConfirmDelete = () => {
    this.toggleDeleteModal();
    this.props.deleteStudio(this.state.studioId);
  };

  render() {
    const { isEditMode, showStudioModal, selectedStudio } = this.state;
    const { studiosList, isFetching } = this.props;

    if (isFetching) {
      return <CustomLoader />;
    }

    return (
      <div>
        <StudiosList
          studiosList={studiosList}
          onCreate={this.handleCreate}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
        />
        <StudioModal
          showModal={showStudioModal}
          toggleModal={this.toggleModal}
          edit={isEditMode}
          initialValues={{
            id: selectedStudio.id,
            name: selectedStudio.name,
            company_name: selectedStudio.company_name,
            company_address: selectedStudio.company_address,
            company_registration_code: selectedStudio.company_registration_code,
            partners: selectedStudio.partners,
          }}
        />
        <ConfirmModal
          isOpen={this.state.showDeleteModal}
          toggle={this.toggleDeleteModal}
          header="Delete studio"
          bodyText="Are you sure?"
          onConfirm={this.onConfirmDelete}
        />
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  studiosList: store.studiosSettings.studiosList,
  isFetching: store.studiosSettings.isFetching,
});

const mapDispatchToProps = dispatch => ({
  deleteStudio: bindActionCreators(deleteStudio, dispatch),
  fetchStudiosList: bindActionCreators(fetchStudiosList, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(StudiosSettings);
