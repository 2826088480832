import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { TextInput, ColorPicker } from '../../../../../../components/form';
import {
  addVacationType,
  updateVacationType,
} from '../../../../../../redux/actions/vacationTypeSettingsActions';
import { resetVacationTypeModalForm } from '../../../../../../redux/actions/uiActions';

import validate from './validations';

class VacationTypeModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showColorPicker: false,
    };

    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
  }

  handleSubmitClicked(data) {
    const { edit, addVacationType, updateVacationType } = this.props;

    const requestData = {
      id: data.id,
      name: data.name,
      timesheet_label: data.timesheet_label,
      value: data.value,
      color_code: data.color_code.hex.toUpperCase(),
    };

    if (edit) {
      updateVacationType(requestData);
    } else {
      addVacationType(requestData);
    }

    this.props.toggleModal();
  }

  handleCloseModal() {
    this.props.toggleModal();
    this.props.resetVacationTypeModalForm();
  }

  togglePicker = () => {
    this.setState({
      showColorPicker: !this.state.showColorPicker,
    });
  };

  render() {
    const { showModal, handleSubmit, edit } = this.props;

    return (
      <Modal className="away-modal" isOpen={showModal} toggle={this.handleCloseModal}>
        <ModalBody className="away-modal-body">
          <Fragment>
            <div className="away-modal-line">
              <h3>{edit ? 'Edit Vacation Type' : 'Add Vacation Type'}</h3>
            </div>

            <div>
              <Field id="name" name="name" label="Vacation Type Name" component={TextInput} />
            </div>

            <div>
              <Field
                id="timesheet_label"
                name="timesheet_label"
                label="Timesheet Label"
                component={TextInput}
              />
            </div>

            <div>
              <Field id="days" name="value" label="Days" type="number" component={TextInput} />
            </div>

            <div className="away-color-picker" id="colorpicker" onClick={this.togglePicker}>
              <Field
                name="color_code"
                component={ColorPicker}
                showColorPicker={this.state.showColorPicker}
                togglePicker={this.togglePicker}
              />
            </div>

            <div>
              <Field
                id="id"
                name="id"
                label="Vacation Type Id"
                type="hidden"
                component={TextInput}
              />
            </div>

            <div className="away-modal-buttons">
              <Button onClick={handleSubmit(this.handleSubmitClicked)} className="btn btn-primary">
                Submit
              </Button>
              <Button onClick={this.handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
    );
  }
}

const selector = formValueSelector('VacationTypeModalForm');
const VacationTypeModalForm = reduxForm({
  validate,
  form: 'VacationTypeModalForm',
  enableReinitialize: true,
})(VacationTypeModal);

const mapStoreToProps = (store, ownProps) => {
  return {
    color_code: selector(store, 'color_code'),
    initialValues: { ...ownProps.initialValues },
  };
};

const mapDispatchToProps = dispatch => ({
  addVacationType: bindActionCreators(addVacationType, dispatch),
  updateVacationType: bindActionCreators(updateVacationType, dispatch),
  resetVacationTypeModalForm: bindActionCreators(resetVacationTypeModalForm, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(VacationTypeModalForm);
