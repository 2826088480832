import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Pagination from 'react-js-pagination';
import { PAGINATION_REQUESTS_PER_PAGE } from '../../../../constants';
import HistoryRequestsListItem from '../HistoryRequestsListItem';
import CustomLoader from '../../../../components/ui/Loader';

class HistoryRequestsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVacation: {},
    };
  }

  hasDelete = request => {
    return request.status === 'Pending';
  };

  hasEdit = request => {
    return request.status === 'Pending';
  };

  render() {
    const {
      currentTab,
      requestsList,
      requestsTotal,
      handleCurrentTabChange,
      activePage,
      handlePageChange,
      onDelete,
      onEdit,
      isFetching,
      isFetchingRequest,
    } = this.props;

    const pager = requestsList.length && requestsTotal > PAGINATION_REQUESTS_PER_PAGE && (
      <div className="away-pagination">
        <Pagination
          activePage={activePage}
          itemsCountPerPage={PAGINATION_REQUESTS_PER_PAGE}
          totalItemsCount={requestsTotal}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
          prevPageText="Prev"
          nextPageText="Next"
          itemClassNext="away-pagination-next"
          itemClassPrev="away-pagination-prev"
        />
      </div>
    );

    const requestsToShow =
      requestsList.length &&
      requestsList.map((request, i) => (
        <HistoryRequestsListItem
          key={i}
          hasEdit={this.hasEdit(request)}
          hasDelete={this.hasDelete(request)}
          requestData={request}
          onEdit={() => onEdit(request)}
          onDelete={() => onDelete(request)}
        />
      ));

    return (
      <div className="away-tabs">
        <div className="card-shadow">
          <div className="at-header">
            <button
              className={`at-link ${currentTab === 'all' && 'active'}`}
              onClick={() => handleCurrentTabChange('all')}
            >
              All
            </button>
            <button
              className={`at-link ${currentTab === 'pending' && 'active'}`}
              onClick={() => handleCurrentTabChange('pending')}
            >
              Pending
            </button>
            <button
              className={`at-link ${currentTab === 'approved' && 'active'}`}
              onClick={() => handleCurrentTabChange('approved')}
            >
              Approved
            </button>
          </div>
          <div className="at-body at-body-big">
            {isFetching || isFetchingRequest ? (
              <CustomLoader />
            ) : requestsList && requestsList.length ? (
              <div className="away-list">
                <Row>
                  <Col md={{ size: 12 }}>
                    <div className="list-header">
                      <Row>
                        <Col md={{ size: 3 }} className="list-cell">
                          <span className="list-cell-text">Period</span>
                        </Col>
                        <Col md={{ size: 2 }} className="list-cell">
                          <span className="list-cell-text">Request Type</span>
                        </Col>
                        <Col md={{ size: 2 }} className="list-cell">
                          <span className="list-cell-text">Number Of Days</span>
                        </Col>
                        <Col md={{ size: 2 }} className="list-cell">
                          <span className="list-cell-text">Status</span>
                        </Col>
                        <Col md={{ size: 2 }} className="list-cell">
                          <span className="list-cell-text">Approved By</span>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
                {requestsToShow}
                {pager}
              </div>
            ) : (
              <h3 className="at-no-requests">
                There are no {currentTab === 'all' ? 'requests!' : `${currentTab} requests!`}
              </h3>
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStoreToProps = store => ({
  showRequestModal: store.ui.showRequestModal,
  isFetchingRequest: store.signedInUserVacations.fetchRequest.isFetching,
});

export default connect(mapStoreToProps)(HistoryRequestsList);
