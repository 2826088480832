import {
  FETCH_USER_LOGS_START,
  FETCH_USER_LOGS_SUCCESS,
  FETCH_USER_LOGS_ERROR,
  FETCH_USER_VACATIONS_HISTORY_START,
  FETCH_USER_VACATIONS_HISTORY_SUCCESS,
  FETCH_USER_VACATIONS_HISTORY_ERROR,
} from './types';
import { getData, baseURL } from './api';

export const fetchUserLogs = (userId, params) => dispatch => {
  dispatch({ type: FETCH_USER_LOGS_START });
  params.count = 10;

  return getData(
    `${baseURL}api/user/profile/${userId}/logs`,
    params,
    FETCH_USER_LOGS_SUCCESS,
    FETCH_USER_LOGS_ERROR,
    dispatch,
  );
};

export const fetchUserVacationHistory = (userId, params) => dispatch => {
  dispatch({ type: FETCH_USER_VACATIONS_HISTORY_START });
  params.count = 10;

  return getData(
    `${baseURL}api/user/profile/${userId}/vacationHistory`,
    params,
    FETCH_USER_VACATIONS_HISTORY_SUCCESS,
    FETCH_USER_VACATIONS_HISTORY_ERROR,
    dispatch,
  );
};
