import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

const renderCheckboxInput = ({
  input,
  placeholder,
  meta: { touched, error, active },
  id,
  label,
  children,
  ...custom
}) => (
  <FormGroup check className="custom-control custom-checkbox">
    <Input
      checked={input.value === true}
      invalid={touched && !!error}
      {...input}
      className="custom-control-input"
      id={id}
      type="checkbox"
      {...custom}
    />
    <Label className="custom-control-label" for={id} check>
      {label}
    </Label>
  </FormGroup>
);

export default renderCheckboxInput;
