import React, { Component } from 'react';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { Calendar } from 'react-date-range';
import './datepicker.scss';

class DatePicker extends Component {
  render() {
    const { showDatePicker, maxDate, handleSelect, value, id, showMonthsYears } = this.props;

    return (
      <div className="away-daterange-line">
        <Popover
          placement="bottom"
          isOpen={showDatePicker}
          target={id}
          hideArrow={true}
          offset={0}
          className="away-daterange"
          toggle={this.props.togglePicker}
        >
          <PopoverBody>
            <div className="away-daterange-content">
              <Calendar
                showMonthAndYearPickers={showMonthsYears}
                moveRangeOnFirstSelection={false}
                monthDisplayFormat="MMMM"
                date={value}
                color="#ff8a5d"
                onChange={handleSelect}
                maxDate={maxDate}
              />
              <Button className="btn btn-primary" onClick={this.props.togglePicker}>
                Confirm
              </Button>
            </div>
          </PopoverBody>
        </Popover>
      </div>
    );
  }
}

export default DatePicker;
