import {
  FETCH_COMPANY_DATA_START,
  FETCH_COMPANY_DATA_SUCCESS,
  FETCH_COMPANY_DATA_ERROR,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  companyData: {},
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_COMPANY_DATA_START:
      return { ...state, isFetching: true };
    case FETCH_COMPANY_DATA_SUCCESS:
      return {
        ...state,
        companyData: action.payload.data,
        isFetching: false,
      };
    case FETCH_COMPANY_DATA_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    default:
      return state;
  }
};
