import { FETCH_CONSTANTS_SUCCESS } from '../actions/types';
import moment from 'moment';

const availableMonths = moment.months().map((month, i) => ({ id: ++i, name: month }));

const initialValues = {
  vacationTypes: [],
  studios: [],
  teams: [],
  roles: [],
  availableYearStatistics: [],
  availableMonthStatistics: [],
  upcomingPublicHolidays: [],
  constantsFetched: false,
  countries: [],
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_CONSTANTS_SUCCESS:
      const today = moment();
      const sixMonthsFromToday = moment().add(6, 'months');
      let filteredHolidays = action.payload.upcomingHolidays
        .filter(holiday => {
          const holidayDate = moment(holiday.date);
          return holidayDate.isBetween(today, sixMonthsFromToday, null, '[]');
        })
        .map(holiday => ({
          name: holiday.localName,
          start_date: holiday.date,
          end_date: holiday.date,
          workDays: 1,
        }));

      let mergedHolidays = [];
      for (let i = 0; i < filteredHolidays.length; i++) {
        const currentHoliday = filteredHolidays[i];
        const nextHoliday = filteredHolidays[i + 1];

        if (nextHoliday && currentHoliday.name === nextHoliday.name) {
          mergedHolidays.push({
            name: currentHoliday.name,
            start_date: currentHoliday.start_date,
            end_date: nextHoliday.end_date,
            workDays: currentHoliday.workDays + 1,
          });
          i++;
        } else {
          mergedHolidays.push(currentHoliday);
        }
      }

      return {
        ...state,
        vacationTypes: action.payload.vacationTypes.map(type => {
          return { value: type.id, label: type.name, color: type.color };
        }),
        studios: action.payload.studios.map(type => {
          return { value: type.id, label: type.name };
        }),
        teams: action.payload.teams.map(team => {
          return { value: team.id, label: team.name };
        }),
        roles: action.payload.roles.map(type => {
          return { value: type.id, label: type.description, slug: type.slug };
        }),
        availableYearStatistics: action.payload.availableYearStatistics.map(type => {
          return { value: type, label: type };
        }),
        availableMonthStatistics: availableMonths.map(type => {
          return { value: type.id, label: type.name };
        }),
        upcomingPublicHolidays: mergedHolidays,
        constantsFetched: true,
        countries: action.payload.countries.map(country => {
          return { value: country.id, label: country.name, shortName: country.iso2 };
        }),
      };
    default:
      return state;
  }
};
