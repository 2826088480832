import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSignedInUserProfile } from '../../redux/actions/signedInUserActions';
import Header from '../../components/ui/Header';
import UserProfileCard from '../../components/ui/UserProfileCard';

class Profile extends Component {
  render() {
    const { user, studios, roles, teamLeads, updateSignedInUserProfile } = this.props;

    // fill up userData with information already present in other reducers :
    // studioLabel
    // teamLeadLabel (name) - necessary after updating a user's team lead
    // role_label - necessary after updating a user's role
    const userStudio = user && studios && studios.filter(studio => studio.value === user.studio_id);
    const studioLabel = userStudio && userStudio[0] && userStudio[0].label;

    const userTeamLead =
      user && teamLeads && teamLeads.filter(teamLead => teamLead.id === user.team_lead);
    const userTeamLeadLabel = userTeamLead && userTeamLead[0] && userTeamLead[0].name;

    const userRole = user && user.role && roles.filter(role => role.value === user.role);
    const userRoleLabel = userRole && userRole[0] && userRole[0].label;

    const userData = {
      ...user,
      studioLabel,
      teamLead: {
        id: user.team_lead,
        name: userTeamLeadLabel ? userTeamLeadLabel : user && user.teamLead && user.teamLead.name,
        avatar: user && user.teamLead && user.teamLead.avatar,
      },
      role_label: userRoleLabel,
    };

    return (
      <Fragment>
        <Header title="My Profile" hasRequestDay />
        <UserProfileCard
          isSignedInUser
          userData={userData}
          updateSignedInUserProfile={updateSignedInUserProfile}
        />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  studios: store.constants.studios,
  user: store.signedInUser.user,
  teamLeads: store.team.teamLeads,
  roles: store.constants.roles,
});

const mapDispatchToProps = dispatch => ({
  updateSignedInUserProfile: bindActionCreators(updateSignedInUserProfile, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(Profile);
