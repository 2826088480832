import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Checkbox, Select2 } from '../../../../../../components/form';
import { generateUserContactsReport } from '../../../../../../redux/actions/reportsActions';
import validate from './validations';
import '../../../../reportsModal.scss';

class UserContactsReportModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleSubmitClicked(data) {
    this.props.generateUserContactsReport(data);
    this.props.toggleModal();
    this.props.reset();
  }

  handleCloseModal() {
    this.props.toggleModal();
    this.props.reset();
  }

  render() {
    const { showModal, handleSubmit } = this.props;

    return (
      <Modal className="away-modal" isOpen={showModal} toggle={this.handleCloseModal}>
        <ModalBody className="away-modal-body">
          <Fragment>
            <div className="away-modal-line">
              <h3>User Contacts</h3>
            </div>

            <Field
              className="away-dropdown-form version-2"
              id="studio_id"
              label="Studio"
              name="studio_id"
              component={Select2}
              placeholder="Studio"
              classNamePrefix="away"
              options={[
                {
                  label: 'All Studios',
                  value: 0,
                },
                ...this.props.studios,
              ]}
            />

            <Field
              id="phone_number"
              name="phone_number"
              label="Phone Number"
              component={Checkbox}
            />

            <Field id="skype_id" name="skype_id" label="Skype ID" component={Checkbox} />

            <Field
              id="date_of_birth"
              name="date_of_birth"
              label="Date of Birth"
              component={Checkbox}
            />

            <Field id="role" name="role" label="Role" component={Checkbox} />

            <Field
              id="company_joined_at"
              name="company_joined_at"
              label="Hire Date"
              component={Checkbox}
            />

            <Field id="country_id" name="country_id" label="Country" component={Checkbox} />

            <Field id="state_id" name="state_id" label="State" component={Checkbox} />

            <Field id="city_id" name="city_id" label="City" component={Checkbox} />

            <Field id="tshirt_size" name="tshirt_size" label="T-Shirt Size" component={Checkbox} />

            <div className="away-modal-buttons">
              <Button onClick={handleSubmit(this.handleSubmitClicked)} className="btn btn-primary">
                Generate
              </Button>
              <Button onClick={this.handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
    );
  }
}

const UserContactsReportModalForm = reduxForm({
  validate,
  form: 'UserContactsReportModalForm',
  enableReinitialize: true,
})(UserContactsReportModal);

const mapDispatchToProps = dispatch => ({
  generateUserContactsReport: bindActionCreators(generateUserContactsReport, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(UserContactsReportModalForm);
