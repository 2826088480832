import {
  FETCH_STUDIOS_LIST_START,
  FETCH_STUDIOS_LIST_SUCCESS,
  FETCH_STUDIOS_LIST_ERROR,
  ADD_STUDIO_START,
  ADD_STUDIO_SUCCESS,
  ADD_STUDIO_ERROR,
  UPDATE_STUDIO_START,
  UPDATE_STUDIO_SUCCESS,
  UPDATE_STUDIO_ERROR,
  DELETE_STUDIO_START,
  DELETE_STUDIO_SUCCESS,
  DELETE_STUDIO_ERROR,
  REMOVE_STUDIO,
  CLEAN_REQUEST_ERRORS,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  studiosList: [],
  addStudio: {
    isFetching: false,
  },
  deleteStudio: {
    isFetching: false,
    studioId: 0,
  },
  updateStudio: {
    isFetching: false,
    studioId: 0,
  },
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case CLEAN_REQUEST_ERRORS: {
      return {
        ...state,
        addStudio: { ...state.addStudio, error: '' },
        updateStudio: { ...state.updateStudio, error: '' },
      };
    }

    case FETCH_STUDIOS_LIST_START:
      return { ...state, isFetching: true };
    case FETCH_STUDIOS_LIST_SUCCESS:
      return {
        ...state,
        studiosList: action.payload.data,
        isFetching: false,
      };
    case FETCH_STUDIOS_LIST_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case ADD_STUDIO_START:
      return {
        ...state,
        addStudio: { isFetching: true },
      };
    case ADD_STUDIO_SUCCESS:
      const vtListToUpdate = state.studiosList;
      const vtUdatedList = [...vtListToUpdate];
      vtUdatedList.unshift(action.payload.data);

      return {
        ...state,
        addStudio: { isFetching: false },
        studiosList: vtUdatedList,
      };
    case ADD_STUDIO_ERROR:
      return {
        ...state,
        addStudio: {
          isFetching: false,
          error: action.payload.response.data.message,
        },
      };
    case DELETE_STUDIO_START: {
      return {
        ...state,
        deleteStudio: {
          isFetching: true,
          studioId: action.payload,
        },
      };
    }
    case DELETE_STUDIO_SUCCESS: {
      return {
        ...state,
        deleteStudio: {
          isFetching: false,
        },
      };
    }
    case DELETE_STUDIO_ERROR: {
      return {
        ...state,
        deleteStudio: {
          isFetching: false,
        },
      };
    }
    case REMOVE_STUDIO: {
      const list = state.studiosList.filter(v => v.id !== action.payload);
      return {
        ...state,
        studiosList: list,
      };
    }
    case UPDATE_STUDIO_START: {
      return {
        ...state,
        updateStudio: {
          isFetching: true,
          studioId: action.payload,
        },
      };
    }
    case UPDATE_STUDIO_SUCCESS: {
      const vtListToUpdate = state.studiosList;
      const vtUpdatedList = vtListToUpdate.map(item => {
        if (item.id === action.payload.data.id) {
          item = action.payload.data;
        }
        return item;
      });

      return {
        ...state,
        updateStudio: {
          isFetching: false,
        },
        studiosList: vtUpdatedList,
      };
    }
    case UPDATE_STUDIO_ERROR: {
      return {
        ...state,
        updateStudio: {
          isFetching: false,
        },
      };
    }

    default:
      return state;
  }
};
