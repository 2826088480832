import {
  USER_CONTACTS_REPORT_START,
  USER_CONTACTS_REPORT_SUCCESS,
  USER_CONTACTS_REPORT_ERROR,
  TIMESHEETS_REPORT_START,
  TIMESHEETS_REPORT_SUCCESS,
  TIMESHEETS_REPORT_ERROR,
  VACATIONS_REPORT_START,
  VACATIONS_REPORT_SUCCESS,
  VACATIONS_REPORT_ERROR,
  WFH_REPORT_START,
  WFH_REPORT_SUCCESS,
  WFH_REPORT_ERROR,
} from '../actions/types';

const initialValues = {
  generatingUserContactsReport: false,
  generatingTimeSheetsReport: false,
  generatingVacationsReport: false,
  generatingWFHReport: false,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case VACATIONS_REPORT_START:
      return {
        ...state,
        generatingVacationsReport: true,
      };
    case VACATIONS_REPORT_SUCCESS:
    case VACATIONS_REPORT_ERROR:
      return {
        ...state,
        generatingVacationsReport: false,
      };

    case TIMESHEETS_REPORT_START:
      return {
        ...state,
        generatingTimeSheetsReport: true,
      };
    case USER_CONTACTS_REPORT_START:
      return {
        ...state,
        generatingUserContactsReport: true,
      };
    case TIMESHEETS_REPORT_SUCCESS:
    case TIMESHEETS_REPORT_ERROR:
      return {
        ...state,
        generatingTimeSheetsReport: false,
      };
    case USER_CONTACTS_REPORT_SUCCESS:
    case USER_CONTACTS_REPORT_ERROR:
      return {
        ...state,
        generatingUserContactsReport: false,
      };
    case WFH_REPORT_START:
      return {
        ...state,
        generatingWFHReport: true,
      };
    case WFH_REPORT_SUCCESS:
    case WFH_REPORT_ERROR:
      return {
        ...state,
        generatingWFHReport: false,
      };
    default:
      return state;
  }
};
