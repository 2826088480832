import React, { Component } from 'react';
import { connect } from 'react-redux';
import FontAwesome from 'react-fontawesome';
import { Row, Col } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Select2 } from '../../form';

class CustomSearch extends Component {
  constructor(props) {
    super(props);

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidUpdate() {
    const { clearSearch, handleResetSearch, reset } = this.props;
    if (clearSearch) {
      reset();
      handleResetSearch();
    }
  }

  handleSelect(data) {
    const { handleSearchData } = this.props;
    const searchValue = data && data.label !== undefined ? data.label : '';

    handleSearchData(searchValue);
  }

  render() {
    const { allUsers, isDisabled } = this.props;
    const allUsersList = allUsers.map(user => {
      return {
        value: user.id,
        label: user.name,
      };
    });

    return (
      <div className="away-search-form">
        <Row>
          <Col md={{ size: 12 }}>
            <Field
              className="away-dropdown-form version-2"
              id="search"
              name="search"
              IsDisabled={isDisabled}
              component={Select2}
              placeholder="Search by name"
              classNamePrefix="away"
              isSearchable={true}
              isClearable={true}
              openMenuOnClick={false}
              onChange={this.handleSelect}
              options={allUsersList}
            />
            <span className={`showSearch ${isDisabled ? 'disabled' : ''}`}>
              <FontAwesome name="search" className="fa-small" />
            </span>
          </Col>
        </Row>
      </div>
    );
  }
}

const selector = formValueSelector('CustomSearchForm');
const CustomSearchForm = reduxForm({
  form: 'CustomSearchForm',
  enableReinitialize: true,
})(CustomSearch);

const mapStoreToProps = (store, ownProps) => {
  return {
    search: selector(store, 'search'),
    initialValues: { ...ownProps.initialValues },
    allUsers: store.team.allUsers,
  };
};

export default connect(mapStoreToProps)(CustomSearchForm);
