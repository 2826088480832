import React, { Fragment, Component } from 'react';

import Header from '../../components/ui/Header';
import VacationTypesSettings from './components/VacationTypesSettings';
import StudiosSettings from './components/StudiosSettings';
import CompanySettings from './components/CompanySettings';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageRendered: 'vacationTypes',
    };

    this.handlePageRenderedChange = this.handlePageRenderedChange.bind(this);
  }

  handlePageRenderedChange(page) {
    this.setState({
      ...this.state,
      pageRendered: page,
    });
  }

  render() {
    const { pageRendered } = this.state;
    let settingsContent;

    switch (pageRendered) {
      case 'vacationTypes':
        settingsContent = <VacationTypesSettings />;
        break;
      case 'studios':
        settingsContent = <StudiosSettings />;
        break;
      case 'company':
        settingsContent = <CompanySettings />;
        break;
      default:
        settingsContent = <VacationTypesSettings />;
    }

    return (
      <Fragment>
        <Header title="Settings" />
        <div className="away-tabs">
          <div className="card-shadow">
            <div className="at-header">
              <button
                className={`at-link ${pageRendered === 'vacationTypes' && 'active'}`}
                onClick={() => this.handlePageRenderedChange('vacationTypes')}
              >
                Vacation Types
              </button>
              <button
                className={`at-link ${pageRendered === 'studios' && 'active'}`}
                onClick={() => this.handlePageRenderedChange('studios')}
              >
                Studios
              </button>
              <button
                className={`at-link ${pageRendered === 'company' && 'active'}`}
                onClick={() => this.handlePageRenderedChange('company')}
              >
                Company
              </button>
            </div>
            <div className="at-body">{settingsContent}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Settings;
