import React from 'react';
import { Row, Col } from 'reactstrap';
import VacationTypeListItem from '../VacationTypesListItem';
import TooltipItem from '../../../../../../components/ui/TooltipItem';

const VacationTypesList = ({
  vacationTypesList,
  onEdit,
  onDelete,
  onCreate,
  onPopulateUserDefaults,
  yearToPopulate,
  isAdmin,
}) => {
  const vacations =
    vacationTypesList &&
    vacationTypesList.length &&
    vacationTypesList.map((vacation, i) => (
      <VacationTypeListItem key={i} vacationData={vacation} onEdit={onEdit} onDelete={onDelete} />
    ));

  return (
    <div className="away-list">
      <div className="list-title-section">
        <h3 className="list-title">Vacation Types</h3>
        {
          //TODO: uncomment when in version 2 and api ready
          /*<button className="btn btn-primary btn-small" onClick={onCreate}>
          Add
        </button>*/
        }
        {isAdmin && (
          <TooltipItem
            id="populate-users"
            button={{
              text: 'Populate ' + yearToPopulate + ' users vacations',
              action: onPopulateUserDefaults,
              className: 'btn btn-primary btn-small',
            }}
            tooltip={{
              placement: 'bottom',
              text:
                "This generates default vacation values for all users who doesn't already have them generated for " +
                yearToPopulate,
            }}
          />
        )}
      </div>
      <Row>
        <Col md={{ size: 12 }}>
          <div className="list-header">
            <Row>
              <Col md={{ size: 4 }} className="list-cell">
                <span className="list-cell-text">Name</span>
              </Col>
              <Col md={{ size: 3 }} className="list-cell">
                <span className="list-cell-text">Days</span>
              </Col>
              <Col md={{ size: 3 }} className="list-cell">
                <span className="list-cell-text">Calendar color</span>
              </Col>
              <Col md={{ size: 2 }} className="list-cell" />
            </Row>
          </div>
        </Col>
      </Row>
      {vacations}
    </div>
  );
};

export default VacationTypesList;
