import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Avatar from '../../../components/ui/Avatar';
import Progressbar from '../../../components/ui/Progressbar';
import FontAwesome from 'react-fontawesome';
import './ProfileCard.scss';

class ProfileCard extends Component {
  render() {
    const { user, studios } = this.props;
    const userStudio = user && studios && studios.filter(studio => studio.value === user.studio_id);
    const studioLabel = userStudio && userStudio[0] && userStudio[0].label;

    const userIsContractor = user.contract === 'self-employed';

    return (
      <div className="card-shadow profile-card">
        <Row className="top-row align-items-center">
          <Col md="4">
            <Avatar size="xl" title={user.name} avatar={user.avatar} isSignedInUser isLink />
          </Col>
          <Col md="8">
            <h3>{user.name}</h3>
            <p>{user.job}</p>
            <span>
              <FontAwesome name="map-marker" className="fa-small" />
              {studioLabel}
            </span>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Progressbar
              totalDays={user.total_all}
              daysLeft={user.free_all}
              nrPending={user.pending_requests}
              unpaidDaysTaken={user.taken_unpaid}
              isContractor={userIsContractor}
              hasLabel
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStoreToProps = store => ({
  studios: store.constants.studios,
});

export default connect(mapStoreToProps)(ProfileCard);
