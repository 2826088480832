import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import CustomLoader from '../../../../components/ui/Loader';
import { formatDate } from '../../../../utils/Date';

class HistoryRequestsListItem extends Component {
  editBtn = this.props.hasEdit && (
    <span className="edit-icon" onClick={() => this.props.onEdit(this.props.requestData)}>
      <FontAwesome name="edit" className="fa-small" />
    </span>
  );

  deleteBtn = this.props.hasDelete && (
    <span className="delete-icon" onClick={() => this.props.onDelete(this.props.requestData)}>
      <FontAwesome name="trash" className="fa-small" />
    </span>
  );

  render() {
    const { requestData, requestId, requestActionInProgress } = this.props;
    if (requestActionInProgress && requestId === requestData.id) {
      return (
        <div className="list-item">
          <CustomLoader />
        </div>
      );
    }

    const startDate = formatDate(requestData.startDate, 'MMM DD');
    const endDate = formatDate(requestData.endDate, 'MMM DD, YYYY');
    const period = `${startDate} - ${endDate}`;
    const isWorkOnHolidayRequest = requestData.vacationTypeId === '-2';

    return (
      <div className="list-item">
        <Row className="li-row">
          <Col md={{ size: 3 }} className="li-cell-mobile">
            <span className="li-cell-mobile-text">Period</span>
          </Col>
          <Col md={{ size: 3 }} className="li-cell">
            {period}
          </Col>
          <Col md={{ size: 2 }} className="li-cell-mobile">
            <span className="li-cell-mobile-text">Request Type</span>
          </Col>
          <Col md={{ size: 2 }} className="li-cell li-capitalized">
            {requestData.vacationType}
          </Col>
          <Col md={{ size: 2 }} className="li-cell-mobile">
            <span className="li-cell-mobile-text">Number Of Days</span>
          </Col>
          <Col md={{ size: 2 }} className="li-cell">
            {requestData.workDays}
          </Col>
          <Col md={{ size: 2 }} className="li-cell-mobile">
            <span className="li-cell-mobile-text">Status</span>
          </Col>
          <Col md={{ size: 2 }} className="li-cell">
            <div className={`li-status li-status-${requestData.status}`}>{requestData.status}</div>
          </Col>
          <Col md={{ size: 3 }} className="li-cell-mobile">
            <span className="li-cell-mobile-text">Approved By</span>
          </Col>
          <Col md={{ size: 2 }} className="li-cell">
            {requestData.approvedBy}
          </Col>

          <Col md={{ size: 1 }} className="li-cell li-cell-end">
            <div className="li-action-buttons">
              {!isWorkOnHolidayRequest && this.editBtn}
              {this.deleteBtn}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

HistoryRequestsListItem.propTypes = {
  requestData: PropTypes.object,
  onApprove: PropTypes.func,
  onDeny: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

const mapStateToProps = store => {
  return {
    requestActionInProgress: store.signedInUserVacations.actionRequest.isFetching,
    requestId: store.signedInUserVacations.actionRequest.requestId,
  };
};

export default connect(mapStateToProps)(HistoryRequestsListItem);
