import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';

import { BootstrapTable, TableHeaderColumn, SizePerPageDropDown } from 'react-bootstrap-table';

import { formatDate } from '../../utils/Date';
import { getAlias } from '../../utils/Avatar';
import { fetchTeamAdminList, createNewUser } from '../../redux/actions/teamAdminActions';
import {
  setTeamAdminSelectedStudio,
  setTeamAdminSelectedYear,
  setTeamAdminSelectedStatus,
  setTeamAdminPage,
  setTeamAdminPageSize,
  setTeamAdminSortBy,
} from '../../redux/actions/uiActions';
import Header from '../../components/ui/Header';
import UserProfileModal from '../../components/modals/UserProfileModal';

class TeamAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: props.teamAdminListActivePage || 1,
      activePageSize: props.teamAdminListActivePageSize || 50,
      createNewUserModalOpen: false,
    };

    this.handleAdminSortChange = this.handleAdminSortChange.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
  }

  componentDidMount() {
    const {
      fetchTeamAdmin,
      selectedStudio,
      selectedYear,
      selectedStatus,
      teamAdminListActivePage,
      teamAdminListActivePageSize,
    } = this.props;

    fetchTeamAdmin({
      studio_id: selectedStudio.value,
      year: selectedYear.value,
      page: teamAdminListActivePage,
      count: teamAdminListActivePageSize,
      blocked: selectedStatus.value,
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    const {
      selectedStudio,
      selectedYear,
      selectedStatus,
      selectedField,
      selectedOrder,
      fetchTeamAdmin,
      teamAdminListActivePageSize,
      setTeamAdminPage,
    } = this.props;
    setTeamAdminPage(pageNumber);

    fetchTeamAdmin({
      studio_id: selectedStudio.value,
      year: selectedYear.value,
      orderByField: selectedField,
      orderDirection: selectedOrder,
      page: pageNumber,
      count: teamAdminListActivePageSize,
      blocked: selectedStatus.value,
    });

    //on page change scroll to the top of table
    document.getElementsByClassName('react-bs-container-body')[0].scrollTo(0, 0);
  }

  handlePageSizeChange(pageSize) {
    this.setState({
      activePage: 1,
      activePageSize: pageSize,
    });
    const {
      selectedStudio,
      selectedYear,
      selectedStatus,
      selectedField,
      selectedOrder,
      fetchTeamAdmin,
      setTeamAdminPageSize,
      setTeamAdminPage,
    } = this.props;
    setTeamAdminPageSize(pageSize);

    fetchTeamAdmin({
      studio_id: selectedStudio.value,
      year: selectedYear.value,
      orderByField: selectedField,
      orderDirection: selectedOrder,
      page: 1,
      count: pageSize,
      blocked: selectedStatus.value,
    });

    setTeamAdminPage(1);
  }

  handleAdminSortChange = (sortName, sortOrder) => {
    const {
      selectedStudio,
      selectedYear,
      selectedStatus,
      setTeamAdminSortBy,
      setTeamAdminPage,
      teamAdminListActivePageSize,
      fetchTeamAdmin,
    } = this.props;
    this.setState({ activePage: 1 });
    setTeamAdminSortBy(sortName ? sortName : 'name', sortOrder === 'desc' ? sortOrder : 'asc');
    setTeamAdminPage(1);

    fetchTeamAdmin({
      studio_id: selectedStudio.value,
      year: selectedYear.value,
      orderByField: sortName ? sortName : 'name',
      orderDirection: sortOrder === 'desc' ? sortOrder : 'asc',
      page: 1,
      count: teamAdminListActivePageSize,
      blocked: selectedStatus.value,
    });
  };

  handleSelectStudio = selectedOption => {
    const {
      selectedYear,
      selectedStatus,
      setTeamAdminSelectedStudio,
      setTeamAdminSortBy,
      setTeamAdminPage,
      teamAdminListActivePageSize,
      fetchTeamAdmin,
    } = this.props;
    this.setState({ activePage: 1 });
    setTeamAdminSelectedStudio(selectedOption);
    setTeamAdminSortBy('name', 'asc');
    setTeamAdminPage(1);

    fetchTeamAdmin({
      studio_id: selectedOption.value,
      year: selectedYear.value,
      orderByField: 'name',
      orderDirection: 'asc',
      page: 1,
      count: teamAdminListActivePageSize,
      blocked: selectedStatus.value,
    });
  };

  handleSelectYear = selectedOption => {
    const {
      selectedStudio,
      selectedStatus,
      setTeamAdminSelectedYear,
      setTeamAdminSortBy,
      setTeamAdminPage,
      teamAdminListActivePageSize,
      fetchTeamAdmin,
    } = this.props;
    this.setState({ activePage: 1 });
    setTeamAdminSelectedYear(selectedOption);
    setTeamAdminSortBy('name', 'asc');
    setTeamAdminPage(1);

    fetchTeamAdmin({
      studio_id: selectedStudio.value,
      year: selectedOption.value,
      orderByField: 'name',
      orderDirection: 'asc',
      page: 1,
      count: teamAdminListActivePageSize,
      blocked: selectedStatus.value,
    });
  };

  handleSelectStatus = selectedOption => {
    const {
      selectedStudio,
      selectedYear,
      setTeamAdminSelectedStatus,
      setTeamAdminPage,
      teamAdminListActivePageSize,
      fetchTeamAdmin,
    } = this.props;
    this.setState({ activePage: 1 });
    setTeamAdminSelectedStatus(selectedOption);
    setTeamAdminSortBy('name', 'asc');
    setTeamAdminPage(1);

    fetchTeamAdmin({
      studio_id: selectedStudio.value,
      year: selectedYear.value,
      orderByField: 'name',
      orderDirection: 'asc',
      page: 1,
      count: teamAdminListActivePageSize,
      blocked: selectedOption.value,
    });
  };

  toggleCreateNewUserModal = () => {
    this.setState({ createNewUserModalOpen: !this.state.createNewUserModalOpen });
  };

  handleSubmitCreateNewUser = data => {
    const {
      fetchTeamAdmin,
      selectedStudio,
      selectedYear,
      teamAdminListActivePage,
      teamAdminListActivePageSize,
    } = this.props;

    const payload = {
      ...data,
      date_of_birth: formatDate(data.date_of_birth, 'YYYY-MM-DD'),
      company_joined_at: formatDate(data.company_joined_at, 'YYYY-MM-DD'),
      company_id: 1, // todo: use company ID
      blocked: 0,
      nda: 0,
      us_visa: 0,
      tshirt_size: '',
    };

    this.props.createNewUser(payload, () =>
      fetchTeamAdmin({
        studio_id: selectedStudio.value,
        year: selectedYear.value,
        page: teamAdminListActivePage,
        count: teamAdminListActivePageSize,
        blocked: 0,
      }),
    );
  };

  onRowSelect = row => {
    const { selectedYear } = this.props;
    const { id } = row;
    const year = selectedYear.value;
    this.props.history.push(`/team-admin/member/${id}/${year}`);
  };

  onToggleDropDown = toggleDropDown => {
    toggleDropDown();
  };

  renderSizePerPageDropDown = props => {
    return (
      <SizePerPageDropDown
        variation="dropup"
        open={props.open}
        onClick={() => this.onToggleDropDown(props.toggleDropDown)}
      />
    );
  };

  render() {
    const {
      teamAdminList,
      teamAdminListTotal,
      studios,
      availableYearStatistics,
      selectedStudio,
      selectedYear,
      selectedStatus,
      teamAdminListHeaders,
      userRights,
      isFetching,
    } = this.props;

    const availableUserStatuses = [
      {
        label: 'All Users',
        value: -1,
      },
      {
        label: 'Active Users',
        value: 0,
      },
      {
        label: 'Inactive Users',
        value: 1,
      },
    ];

    const studioSelect = (
      <Select
        className="away-dropdown"
        classNamePrefix="away"
        placeholder="Studios"
        value={selectedStudio}
        onChange={this.handleSelectStudio}
        options={[
          {
            label: 'All Studios',
            value: 0,
          },
          ...studios,
        ]}
        isSearchable={false}
      />
    );

    const yearSelect = (
      <Select
        className="away-dropdown"
        classNamePrefix="away"
        placeholder="Years"
        value={selectedYear}
        onChange={this.handleSelectYear}
        options={[...availableYearStatistics]}
        isSearchable={false}
      />
    );

    const statusSelect = (
      <Select
        className="away-dropdown"
        classNamePrefix="away"
        placeholder="Status"
        value={selectedStatus}
        onChange={this.handleSelectStatus}
        options={[...availableUserStatuses]}
        isSearchable={false}
      />
    );

    const statisticsHeaders = teamAdminListHeaders.map((header, i) => {
      const takenProp = `taken_${header.id}`;
      const totalProp = `total_${header.id}`;

      const sortFlag = header.type === 'loyality' ? totalProp : takenProp;
      const columnWidth = header.type !== 'medical' ? '120' : '200';

      return (
        <TableHeaderColumn
          key={i}
          dataField={`${sortFlag}`}
          dataFormat={(cell, row) => {
            const vacationTypeStats =
              header.type === 'loyality'
                ? `${row[totalProp]}`
                : `${row[takenProp]} / ${row[totalProp]}`;
            return <span>{vacationTypeStats}</span>;
          }}
          dataAlign="center"
          dataSort={true}
          width={columnWidth}
        >
          <span className="at-th">
            <span className="at-th-title">{header.name}</span>
            {header.type !== 'loyality' && <span className="at-th-label">taken / total</span>}
          </span>
        </TableHeaderColumn>
      );
    });

    const selectRowProp = {
      mode: 'radio',
      hideSelectColumn: true, // enable hide selection column.
      clickToSelect: true,
      onSelect: this.onRowSelect,
    };

    const options = {
      page: this.state.activePage, // which page you want to show as default
      sizePerPageList: [
        {
          text: '50 Rows',
          value: 50,
        },
        {
          text: '100 Rows',
          value: 100,
        },
      ], // you can change the dropdown list for size per page
      sizePerPage: this.state.activePageSize, // which size per page you want to locate as default
      pageStartIndex: 1, // where to start counting the pages
      paginationSize: 5, // the pagination bar size.
      onPageChange: this.handlePageChange,
      onSizePerPageList: this.handlePageSizeChange,
      onSortChange: this.handleAdminSortChange,
      alwaysShowAllBtns: true, // Always show next and previous button
      withFirstAndLast: true,
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      noDataText: !isFetching && teamAdminList.length === 0 ? 'No records found' : 'Loading...',
    };

    return (
      <Fragment>
        <Header title="Team Admin" />
        <Row className="team-admin-row">
          <Col md={{ size: 12 }}>
            <div className="filter-area">
              <div className="admin-filters">
                {statusSelect}
                {userRights.includes('hasManagementRights') && studioSelect}
                {yearSelect}
              </div>
              {userRights.includes('hasManagementRights') && (
                <button
                  onClick={this.toggleCreateNewUserModal}
                  className="btn btn-primary btn-small"
                >
                  Add New User
                </button>
              )}
            </div>
          </Col>
        </Row>

        <Row>
          <Col md={{ size: 12 }}>
            <div className="away-table-b">
              <BootstrapTable
                data={teamAdminList}
                hover
                remote={true}
                pagination={true}
                fetchInfo={{ dataTotalSize: teamAdminListTotal }}
                options={options}
                selectRow={selectRowProp}
                bodyStyle={{
                  height: 'calc(100vh - 350px)',
                }}
                trClassName="at-row"
                tableHeaderClass="at-header"
                bootstrap4
              >
                <TableHeaderColumn
                  dataField="role"
                  dataFormat={(cell, row) => {
                    const role = getAlias(row.role_label);
                    return <span>{role}</span>;
                  }}
                  dataAlign="center"
                  dataSort={true}
                  width="80"
                >
                  <span className="at-th">Role</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  isKey={true}
                  dataField="name"
                  dataFormat={(cell, row) => {
                    const name = row.name;
                    return <span className="at-name">{name}</span>;
                  }}
                  dataAlign="center"
                  dataSort={true}
                  width="200"
                >
                  <span className="at-th">Name</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="company_joined_at"
                  dataFormat={(cell, row) => {
                    const joinedAt = row.company_joined_at;
                    return <span>{formatDate(joinedAt, 'MMM DD, YYYY')}</span>;
                  }}
                  dataAlign="center"
                  dataSort={true}
                  width="150"
                >
                  <span className="at-th">Joined</span>
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="free_all"
                  dataFormat={(cell, row) => {
                    const daysOffAvailable = row.free_all;
                    return <span>{daysOffAvailable}</span>;
                  }}
                  dataAlign="center"
                  dataSort={true}
                  width="150"
                >
                  <span className="at-th">Days Off Available</span>
                </TableHeaderColumn>
                {statisticsHeaders}
              </BootstrapTable>
            </div>
          </Col>
        </Row>

        <UserProfileModal
          form="CreateNewUserForm"
          showModal={this.state.createNewUserModalOpen}
          toggleModal={this.toggleCreateNewUserModal}
          updateUserProfile={this.handleSubmitCreateNewUser}
          initialValues={{ company_joined_at: new Date(), date_of_birth: new Date() }}
        />
      </Fragment>
    );
  }
}

const mapStoreToProps = store => ({
  userRights: store.signedInUser.userRights,
  teamAdminList: store.teamAdmin.adminList,
  teamAdminListPage: store.teamAdmin.adminListPage,
  teamAdminListTotal: store.teamAdmin.adminListTotal,
  teamAdminListCount: store.teamAdmin.adminListCount,
  teamAdminListHeaders: store.teamAdmin.adminListHeaders,
  isFetching: store.teamAdmin.isFetching,
  studios: store.constants.studios,
  availableYearStatistics: store.constants.availableYearStatistics,
  selectedStudio: store.ui.selectedTeamAdminStudio,
  selectedYear: store.ui.selectedTeamAdminYear,
  selectedStatus: store.ui.selectedTeamAdminStatus,
  selectedField: store.ui.selectedTeamAdminField,
  selectedOrder: store.ui.selectedTeamAdminOrder,
  teamAdminListActivePage: store.ui.teamAdminListActivePage,
  teamAdminListActivePageSize: store.ui.teamAdminListActivePageSize,
});

const mapDispatchToProps = dispatch => ({
  setTeamAdminSelectedStudio: bindActionCreators(setTeamAdminSelectedStudio, dispatch),
  setTeamAdminSelectedYear: bindActionCreators(setTeamAdminSelectedYear, dispatch),
  setTeamAdminSelectedStatus: bindActionCreators(setTeamAdminSelectedStatus, dispatch),
  setTeamAdminPage: bindActionCreators(setTeamAdminPage, dispatch),
  setTeamAdminPageSize: bindActionCreators(setTeamAdminPageSize, dispatch),
  setTeamAdminSortBy: bindActionCreators(setTeamAdminSortBy, dispatch),
  fetchTeamAdmin: bindActionCreators(fetchTeamAdminList, dispatch),
  createNewUser: bindActionCreators(createNewUser, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(TeamAdmin);
