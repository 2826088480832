import {
  SEND_NEW_PASSWORD_START,
  SEND_NEW_PASSWORD_SUCCESS,
  SEND_NEW_PASSWORD_ERROR,
  FETCH_RESET_PASSWORD_START,
  FETCH_RESET_PASSWORD_SUCCESS,
  FETCH_RESET_PASSWORD_ERROR,
  SEND_RESET_PASSWORD_EMAIL_START,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS,
  SEND_RESET_PASSWORD_EMAIL_ERROR,
  RESET_FORGOT_PASSWORD_FLOW,
} from './types';
import { getData, postData, baseURL } from './api';

export const sendResetPasswordEmail = payload => dispatch => {
  dispatch({ type: SEND_RESET_PASSWORD_EMAIL_START });
  postData(
    `${baseURL}password-reset/init`,
    payload,
    SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    SEND_RESET_PASSWORD_EMAIL_ERROR,
    dispatch,
  );
};

export const fetchResetPasswordTokenData = token => dispatch => {
  dispatch({ type: FETCH_RESET_PASSWORD_START });

  const params = {};

  return getData(
    `${baseURL}password-reset/${token}`,
    params,
    FETCH_RESET_PASSWORD_SUCCESS,
    FETCH_RESET_PASSWORD_ERROR,
    dispatch,
  );
};

export const sendNewPassword = (payload, token) => dispatch => {
  dispatch({ type: SEND_NEW_PASSWORD_START });
  postData(
    `${baseURL}password-reset/${token}`,
    payload,
    SEND_NEW_PASSWORD_SUCCESS,
    SEND_NEW_PASSWORD_ERROR,
    dispatch,
  );
};

export const resetForgotPasswordFlow = () => dispatch => {
  dispatch({ type: RESET_FORGOT_PASSWORD_FLOW });
};
