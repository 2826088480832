import React from 'react';
import cx from 'classnames';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const renderTextArea = ({
  input,
  placeholder,
  type,
  meta: { touched, error, active },
  id,
  label,
  labelClassName,
  rows,
  ...custom
}) => {
  const labelActive = active || input.value || input.value === 0 || input.value !== '';
  const labelClass = labelActive ? cx(labelClassName, 'active') : labelClassName;
  return (
    <FormGroup className={`${type === 'hidden' && 'd-none'}`}>
      <Label className={labelClass} for={id}>
        {label}
      </Label>
      <Input
        invalid={touched && !!error}
        {...input}
        type={type}
        placeholder={placeholder}
        {...custom}
        id={id}
        rows={rows}
      />
      {touched && error ? <FormFeedback>{error}</FormFeedback> : null}
    </FormGroup>
  );
};

export default renderTextArea;
