import React, { Fragment } from 'react';
import { Label, Input, FormGroup, Col, Row } from 'reactstrap';
import DateRangePicker from '../ui/DateRangePicker';
import { formatDate } from '../../utils/Date';

const renderDateRangePicker = ({ input, showDatePicker, togglePicker }) => (
  <Fragment>
    <Row>
      <Col>
        <FormGroup>
          <Label className="active" for="startDate">
            From date
          </Label>
          <Input
            readOnly
            className={showDatePicker ? 'force-focus' : ''}
            type="text"
            value={formatDate(input.value.range1.startDate, 'MM/DD/YYYY')}
          />
        </FormGroup>
      </Col>
      <Col>
        <FormGroup>
          <Label className="active" for="endDate">
            To date
          </Label>
          <Input
            readOnly
            className={showDatePicker ? 'force-focus' : ''}
            type="text"
            value={formatDate(input.value.range1.endDate, 'MM/DD/YYYY')}
          />
        </FormGroup>
      </Col>
    </Row>
    <DateRangePicker
      {...input}
      handleSelect={input.onChange}
      showDatePicker={showDatePicker}
      togglePicker={togglePicker}
    />
  </Fragment>
);

export default renderDateRangePicker;
