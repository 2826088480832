import React, { Component, Fragment } from 'react';
import { Button } from 'reactstrap';
import moment from 'moment';
import TimesheetsReportModal from './components/TimesheetsReportModal';

import ReportIcon from '../../../../assets/Report.svg';
import CustomLoader from '../../../../components/ui/Loader';

class VacationsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showTimesheetsReportModal: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal = () => {
    this.setState({
      showTimesheetsReportModal: !this.state.showTimesheetsReportModal,
    });
  };

  render() {
    const { showTimesheetsReportModal } = this.state;
    const availableMonths = moment.months().map((month, i) => ({ id: ++i, name: month }));

    const reportIcon = (
      <div className="report-icon">
        <img alt="Report" src={ReportIcon} />
      </div>
    );

    return (
      <div className="report-card">
        <div className="card-shadow">
          {this.props.generatingReport ? (
            <CustomLoader />
          ) : (
            <Fragment>
              <h3 className="report-title">Timesheets</h3>
              <div>
                {reportIcon}
                <Button className="btn btn-primary" onClick={this.toggleModal}>
                  Generate
                </Button>
              </div>
            </Fragment>
          )}
        </div>
        <TimesheetsReportModal
          studios={this.props.studios}
          showModal={showTimesheetsReportModal}
          toggleModal={this.toggleModal}
          initialValues={{
            blocked: {
              label: 'Active Users',
              value: 0,
            },
            studio_id: {
              label: 'All Studios',
              value: 0,
            },
            year: {
              label: new Date().getFullYear(),
              value: new Date().getFullYear(),
            },
            month: {
              label: availableMonths[0].name,
              value: 1,
            },
          }}
        />
      </div>
    );
  }
}

export default VacationsReport;
