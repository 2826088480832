import {
  FETCH_TEAM_VACATIONS_START,
  FETCH_TEAM_VACATIONS_SUCCESS,
  FETCH_TEAM_VACATIONS_ERROR,
} from './types';
import { getData, baseURL } from './api';

export const fetchTeamVacations = params => dispatch => {
  dispatch({ type: FETCH_TEAM_VACATIONS_START });

  if (params.search && params.search === '') delete params.search;

  return getData(
    `${baseURL}api/vacations/team`,
    params,
    FETCH_TEAM_VACATIONS_SUCCESS,
    FETCH_TEAM_VACATIONS_ERROR,
    dispatch,
  );
};
