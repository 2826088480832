import React, { Component } from 'react';
import Avatar from 'react-avatar-edit';
import { Row, Col } from 'reactstrap';

import './avatarEditor.scss';

class AvatarEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: null,
    };
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.setState({ preview: null });
  }

  onCrop(preview) {
    this.props.handleSelect(preview);
    this.setState({ preview });
  }

  render() {
    return (
      <Row className="away-avatar-editor">
        <Col md={{ size: 8 }} className="aae-cell">
          <Avatar
            width={'100%'}
            imageWidth={300}
            height={295}
            onCrop={this.onCrop}
            onClose={this.onClose}
          />
        </Col>
        <Col md={{ size: 4 }} className="aae-cell">
          <div className="away-avatar-preview">
            <h3>Preview</h3>
            {!!this.state.preview ? (
              <img src={this.state.preview} alt="Preview" className="away-avatar-preview-img" />
            ) : (
              <div className="away-avatar-preview-img" />
            )}
          </div>
        </Col>
      </Row>
    );
  }
}

export default AvatarEditor;
