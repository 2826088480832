import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as authActions from '../../redux/actions/authActions';
import {
  fetchResetPasswordTokenData,
  sendNewPassword,
  resetForgotPasswordFlow,
} from '../../redux/actions/passwordActions';
import { Container, Row, Col, Button } from 'reactstrap';

import CustomLoader from '../../components/ui/Loader';
import { PasswordInput } from '../../components/form';
import validate from './ResetPasswordValidation';
import logo from '../../assets/logo.svg';
import './password.scss';
import '../../styles/theme.scss';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    const resetPasswordToken = urlParams.get('token');

    this.state = {
      token: resetPasswordToken,
    };
  }

  componentDidMount() {
    this.props.resetForgotPasswordFlow();
    this.props.fetchResetPasswordTokenData(this.state.token);
  }

  handleSubmitClicked(formValues) {
    const { sendNewPassword } = this.props;
    sendNewPassword(formValues, this.state.token);
  }

  componentDidUpdate() {
    const {
      newPasswordSaved,
      isAuthenticated,
      history,
      user,
      newPassword,
      authActions,
    } = this.props;
    if (newPasswordSaved) {
      if (!isAuthenticated) {
        authActions.loginUser({
          email: user.email,
          password: newPassword,
        });
      } else {
        history.push('/');
      }
    }
  }

  render() {
    const { handleSubmit, isFetching, user, token } = this.props;

    if (isFetching) {
      return <CustomLoader />;
    }

    return (
      <Container>
        <Row className="password-wrap">
          <Col md={{ size: 4 }}>
            <div className="card-shadow">
              <img src={logo} alt="Logo" />
              {token && token.expiresInSeconds && token.expiresInSeconds > 0 ? (
                <form>
                  <div className="greeting">
                    <p>Hello {user.name},</p>
                    <p>Please enter a new password</p>
                  </div>
                  <Field
                    onFocus={this.onFocus}
                    name="password"
                    label="Password"
                    component={PasswordInput}
                  />
                  <Field
                    onFocus={this.onFocus}
                    name="confirmPassword"
                    label="Confirm password"
                    component={PasswordInput}
                  />
                  <Button
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
                  >
                    Continue
                  </Button>
                </form>
              ) : (
                <div className="greeting">
                  <p className="center">The reset password token has expired.</p>
                  <Link className="btn btn-link" to={'/forgot-password'}>
                    Please try again.
                  </Link>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const selector = formValueSelector('ResetPasswordForm');
const mapStoreToProps = store => ({
  newPassword: selector(store, 'password'),
  user: store.password.user,
  token: store.password.token,
  newPasswordSaved: store.password.newPasswordSaved,
  isFetching: store.password.isFetching,
  isAuthenticated: store.auth.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({
  fetchResetPasswordTokenData: bindActionCreators(fetchResetPasswordTokenData, dispatch),
  sendNewPassword: bindActionCreators(sendNewPassword, dispatch),
  authActions: bindActionCreators(authActions, dispatch),
  resetForgotPasswordFlow: bindActionCreators(resetForgotPasswordFlow, dispatch),
});

const ResetPasswordForm = reduxForm({
  form: 'ResetPasswordForm',
  validate,
})(ResetPassword);

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(ResetPasswordForm);
