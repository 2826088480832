import React, { Fragment } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import Moment from 'moment';

const DeleteOrAcceptRequestModal = ({ isOpen, toggle, onConfirm, header, bodyText, vacation }) => {
  return (
    <Modal className="away-modal" isOpen={isOpen} toggle={toggle}>
      <ModalBody className="away-modal-body">
        {vacation && (
          <Fragment>
            <div className="away-modal-line">
              <h3>{header}</h3>
            </div>
            <div className="reject-person-info">
              <p>{vacation.name}</p>
              <p>
                {`${Moment(vacation.startDate).format('D MMM Y')}
                 -
                  ${Moment(vacation.endDate).format('D MMM Y')}`}
              </p>
              <p className="vacation-type">{vacation.vacationType}</p>
            </div>
          </Fragment>
        )}
        <div className="away-modal-buttons">
          <Button color="primary" onClick={onConfirm}>
            Yes
          </Button>
          <Button className="btn-link btn-grey" onClick={toggle}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteOrAcceptRequestModal;
