const validate = values => {
  const errors = [];
  if (values.name === '') {
    errors.name = 'Please enter a studio name';
  }
  if (values.company_name === '') {
    errors.company_name = 'Please enter a company name';
  }
  if (values.company_address === '') {
    errors.company_address = 'Please enter a company address';
  }
  if (values.company_registration_code === '') {
    errors.company_registration_code = 'Please enter a company registration code';
  }

  return errors;
};

export default validate;
