import {
  CREATE_NEW_USER_ERROR,
  CREATE_NEW_USER_START,
  CREATE_NEW_USER_SUCCESS,
  DELETE_TEAM_ADMIN_MEMBER_AVATAR_ERROR,
  DELETE_TEAM_ADMIN_MEMBER_AVATAR_START,
  DELETE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS,
  FETCH_MEMBER_VACATION_DEFAULTS_ERROR,
  FETCH_MEMBER_VACATION_DEFAULTS_START,
  FETCH_MEMBER_VACATION_DEFAULTS_SUCCESS,
  FETCH_TEAM_ADMIN_LIST_ERROR,
  FETCH_TEAM_ADMIN_LIST_START,
  FETCH_TEAM_ADMIN_LIST_SUCCESS,
  FETCH_TEAM_ADMIN_MEMBER_ERROR,
  FETCH_TEAM_ADMIN_MEMBER_START,
  FETCH_TEAM_ADMIN_MEMBER_SUCCESS,
  UPDATE_TEAM_ADMIN_MEMBER_AVATAR_ERROR,
  UPDATE_TEAM_ADMIN_MEMBER_AVATAR_START,
  UPDATE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS,
  UPDATE_TEAM_ADMIN_MEMBER_PROFILE_ERROR,
  UPDATE_TEAM_ADMIN_MEMBER_PROFILE_START,
  UPDATE_TEAM_ADMIN_MEMBER_PROFILE_SUCCESS,
  UPDATE_TEAM_ADMIN_MEMBER_STATS_ERROR,
  UPDATE_TEAM_ADMIN_MEMBER_STATS_START,
  UPDATE_TEAM_ADMIN_MEMBER_STATS_SUCCESS,
  UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_ERROR,
  UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_START,
  UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_SUCCESS,
  RESET_TEAM_ADMIN_MEMBER,
} from '../actions/types';

const initialValues = {
  isFetching: false,
  adminList: [],
  adminListPage: 1,
  adminListTotal: 1,
  adminListCount: 50,
  adminListHeaders: [],
  adminMember: {},
  adminMemberHeaders: [],
  adminMemberVacationDefaults: [],
  isChangingAvatar: false,
};

export default (state = initialValues, action) => {
  switch (action.type) {
    case FETCH_TEAM_ADMIN_LIST_START:
    case FETCH_TEAM_ADMIN_MEMBER_START:
    case FETCH_MEMBER_VACATION_DEFAULTS_START:
    case UPDATE_TEAM_ADMIN_MEMBER_PROFILE_START:
    case UPDATE_TEAM_ADMIN_MEMBER_STATS_START:
    case UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_START:
    case CREATE_NEW_USER_START:
      return { ...state, isFetching: true };
    case UPDATE_TEAM_ADMIN_MEMBER_AVATAR_START:
    case DELETE_TEAM_ADMIN_MEMBER_AVATAR_START:
      return { ...state, isChangingAvatar: true };
    case FETCH_TEAM_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        adminList: action.payload.list,
        adminListPage: action.payload.page,
        adminListTotal: action.payload.total,
        adminListCount: action.payload.count,
        adminListHeaders: action.payload.headers,
        isFetching: false,
      };
    case FETCH_TEAM_ADMIN_MEMBER_SUCCESS:
      return {
        ...state,
        adminMember: action.payload.user,
        adminMemberHeaders: action.payload.headers,
        isFetching: false,
      };

    case UPDATE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS:
    case DELETE_TEAM_ADMIN_MEMBER_AVATAR_SUCCESS:
      return {
        ...state,
        adminMember: { ...state.adminMember, ...action.payload.user },
        isChangingAvatar: false,
      };
    case UPDATE_TEAM_ADMIN_MEMBER_PROFILE_SUCCESS:
      return {
        ...state,
        adminMember: { ...state.adminMember, ...action.payload.user },
        isFetching: false,
      };
    case FETCH_MEMBER_VACATION_DEFAULTS_SUCCESS:
      return {
        ...state,
        adminMemberVacationDefaults: action.payload.data,
        isFetching: false,
      };
    case UPDATE_TEAM_ADMIN_MEMBER_STATS_SUCCESS:
      return {
        ...state,
        adminMemberVacationDefaults: action.payload.data,
        isFetching: false,
      };
    case CREATE_NEW_USER_SUCCESS:
    case UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_TEAM_ADMIN_LIST_ERROR:
    case FETCH_TEAM_ADMIN_MEMBER_ERROR:
    case FETCH_MEMBER_VACATION_DEFAULTS_ERROR:
    case UPDATE_TEAM_ADMIN_MEMBER_PROFILE_ERROR:
    case UPDATE_TEAM_ADMIN_MEMBER_STATS_ERROR:
    case UPDATE_TEAM_ADMIN_MEMBER_CARRYOVER_ERROR:
    case CREATE_NEW_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    case UPDATE_TEAM_ADMIN_MEMBER_AVATAR_ERROR:
    case DELETE_TEAM_ADMIN_MEMBER_AVATAR_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isChangingAvatar: false,
      };
    case RESET_TEAM_ADMIN_MEMBER:
      return {
        ...state,
        adminMember: {},
        adminMemberHeaders: [],
        adminMemberVacationDefaults: [],
      };
    default:
      return state;
  }
};
