import React from 'react';
import { Route, Switch } from 'react-router-dom';
import requireAuth from '../components/hoc/requireAuth';

import Login from './login/Login';
import ResetPassword from './forgotPassword/ResetPassword';
import ForgotPassword from './forgotPassword/ForgotPassword';
import DashboardContainer from './dashboard/DashboardContainer';
import People from './people/People';
import Person from './people/Person';
import Teams from './teams/Teams';
import TeamAdmin from './teamAdmin/TeamAdmin';
import TeamAdminMember from './teamAdmin/TeamAdminMember';
import Profile from './profile/Profile';
import History from './history/History';
import Settings from './settings/Settings';
import Reports from './reports/Reports';
import VacationRequests from './vacationRequests/VacationRequests';
import PageNotFound from './pageNotFound/PageNotFound';

const Routes = () => (
  <Switch>
    <Route path="/" exact component={requireAuth(DashboardContainer)} />
    <Route path="/login" component={Login} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/people" exact component={requireAuth(People)} />
    <Route path="/people/:id" component={requireAuth(Person)} />
    <Route
      path="/teams/"
      component={requireAuth(Teams, [
        'hasApproverRights',
        'hasManagementRights',
        'hasStudioManagementRights',
      ])}
    />
    <Route
      path="/team-admin"
      exact
      component={requireAuth(TeamAdmin, ['hasManagementRights', 'hasStudioManagementRights'])}
    />
    <Route
      path="/team-admin/member/:id/:year"
      component={requireAuth(TeamAdminMember, ['hasManagementRights', 'hasStudioManagementRights'])}
    />
    <Route path="/history" component={requireAuth(History)} />
    <Route
      path="/vacation-requests"
      component={requireAuth(VacationRequests, ['hasApproverRights'])}
    />
    <Route path="/profile" component={requireAuth(Profile)} />
    <Route path="/settings" component={requireAuth(Settings, ['hasManagementRights'])} />
    <Route
      path="/reports"
      component={requireAuth(Reports, ['hasManagementRights', 'hasStudioManagementRights'])}
    />
    <Route path="*" component={requireAuth(PageNotFound)} />
  </Switch>
);

export default Routes;
