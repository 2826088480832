import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import EditTeamForm from './EditTeamForm';

class EditTeamModal extends Component {
  render() {
    const { showModal, toggleModal, team } = this.props;
    return (
      <Modal className="away-modal" isOpen={showModal} toggle={toggleModal}>
        <ModalBody className="away-modal-body">
          <div className="away-modal-line">
            <h3>Edit team</h3>
          </div>
          <div>
            <EditTeamForm
              toggleModal={toggleModal}
              initialValues={{
                ...team,
                users:
                  team && team.users && team.users.length
                    ? team.users.map(item => ({ label: item.name, value: item.id }))
                    : [],
              }}
              team={team}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default EditTeamModal;
