import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { bindActionCreators } from 'redux';
import { validateToken, skipTokenValidation } from '../../redux/actions/authActions';
import logo from '../../assets/logo.svg';
import './AppLoader.scss';

class AuthInit extends Component {
  checkAuth() {
    const { validateToken, skipTokenValidation } = this.props;
    const token = localStorage.getItem('token');
    if (token) {
      validateToken(token);
    } else {
      skipTokenValidation();
    }
  }

  componentDidMount() {
    this.checkAuth();
  }

  render() {
    const { isValidatingToken, children } = this.props;
    if (isValidatingToken) {
      return (
        <div className="loading">
          <img src={logo} alt="Away" />
          <Loader type="ThreeDots" color="#ff8a5d" />
        </div>
      );
    }
    return children;
  }
}

const mapStoreToProps = store => ({
  isValidatingToken: store.auth.isValidatingToken,
});

const mapDispatchToProps = dispatch => ({
  validateToken: bindActionCreators(validateToken, dispatch),
  skipTokenValidation: bindActionCreators(skipTokenValidation, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(AuthInit);
