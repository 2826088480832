import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import './progressbar.scss';

const Progressbar = ({
  totalDays,
  daysLeft,
  unpaidDaysTaken,
  isContractor,
  nrPending,
  isSmall,
  isCenter,
  hasLabel,
  ...custom
}) => {
  const totalDaysHere = totalDays && totalDays !== 'undefined' ? totalDays : 0;
  const daysLeftHere = daysLeft && daysLeft !== 'undefined' ? daysLeft : 0;
  const percentOfDays = Math.floor((daysLeft / totalDays) * 100);
  let progressClass;
  let labelTop;
  let labelBottom;

  let bigLabelTop;
  if (nrPending !== 'undefined' && nrPending > 0) {
    bigLabelTop = (
      <p className="label label-bottom">{`(${nrPending} pending request${
        nrPending > 1 ? 's' : ''
      })`}</p>
    );
  }

  if (percentOfDays >= 50) {
    progressClass = `pg-primary`;
  } else if (percentOfDays < 50 && percentOfDays >= 25) {
    progressClass = 'pg-warning';
  } else if (percentOfDays < 25) {
    progressClass = 'pg-danger';
  }

  if (isSmall) {
    labelTop = (
      <p className={`label label-sm ${isCenter && 'label-center'}`}>
        {daysLeftHere} vacation days left
        {isContractor && `, ${unpaidDaysTaken} unpaid days taken`}
      </p>
    );
  } else {
    labelTop = (
      <div className="label label-top">
        <p>{`${daysLeftHere} vacation days left`}</p>
        {bigLabelTop}
      </div>
    );
    labelBottom = <p className="label label-bottom">Out of {totalDaysHere}</p>;
  }

  return (
    <div className={`away-progressbar ${isSmall && 'progressbar-sm'}`}>
      {hasLabel && labelTop}
      <Progress
        className={`progress ${progressClass} ${isSmall && 'pg-small'}`}
        value={percentOfDays}
        {...custom}
      />
      {hasLabel && labelBottom}
      {isContractor && !isSmall && (
        <p className={`label ${isSmall ? 'label-bottom' : 'label-top'}`}>
          {unpaidDaysTaken} unpaid days taken
        </p>
      )}
    </div>
  );
};

Progressbar.propTypes = {
  totalDays: PropTypes.number,
  daysLeft: PropTypes.number,
  unpaidDaysTaken: PropTypes.number,
  isContractor: PropTypes.bool,
  nrPending: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isSmall: PropTypes.bool,
  isCenter: PropTypes.bool,
  hasLabel: PropTypes.bool,
};

Progressbar.defaultProps = {
  totalDays: 0,
  daysLeft: 0,
  unpaidDaysTaken: 0,
  isContractor: false,
  nrPending: 0,
  isSmall: false,
  isCenter: false,
  hasLabel: false,
};

export default Progressbar;
