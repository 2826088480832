import React, { Component } from 'react';
import cx from 'classnames';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import FontAwesome from 'react-fontawesome';

class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
    };

    this.toggleShowPassword = this.toggleShowPassword.bind(this);
  }

  toggleShowPassword = e => {
    e.stopPropagation();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  render() {
    const {
      input,
      placeholder,
      meta: { touched, error, active },
      id,
      label,
      labelClassName,
      ...custom
    } = this.props;

    const labelActive = active || input.value || input.value === 0 || input.value !== '';
    const labelClass = labelActive ? cx(labelClassName, 'active') : labelClassName;
    const { showPassword } = this.state;

    return (
      <FormGroup>
        <Label className={labelClass} for={id}>
          {label}
        </Label>
        <Input
          invalid={touched && !!error}
          {...input}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          {...custom}
        />
        <span
          className={`showPassword ${!input.value && 'disabled'}`}
          onClick={!input.value ? () => {} : this.toggleShowPassword}
        >
          {showPassword ? (
            <FontAwesome name="eye" className="fa-small" />
          ) : (
            <FontAwesome name="eye-slash" className="fa-small" />
          )}
        </span>

        {touched && error ? <FormFeedback>{error}</FormFeedback> : null}
      </FormGroup>
    );
  }
}

export default PasswordInput;
