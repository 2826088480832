import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as authActions from '../../redux/actions/authActions';
import { Container, Row, Col, Button } from 'reactstrap';
import { TextInput, PasswordInput } from '../../components/form';
import validate from './LoginFormValidations';
import logo from '../../assets/logo.svg';
import './login.scss';
import '../../styles/theme.scss';

class Login extends Component {
  state = { formFocused: false };
  handleSubmitClicked(formValues) {
    const { authActions } = this.props;
    this.setState({ formFocused: false });
    authActions.loginUser(formValues);
  }

  componentDidUpdate() {
    const { isAuthenticated, history, location, constantsFetched, userDataFetched } = this.props;
    if (isAuthenticated && constantsFetched && userDataFetched) {
      if (location.state && location.state.from && location.state.params) {
        history.push(location.state.from + location.state.params);
      } else {
        history.push('/');
      }
    }
  }

  onFocus = () => {
    this.setState({ formFocused: true });
  };

  render() {
    const { handleSubmit, loginAttemptFailed } = this.props;
    const { formFocused } = this.state;
    return (
      <Container>
        <Row className="login-wrap">
          <Col md={{ size: 4 }}>
            <div className="card-shadow">
              <img src={logo} alt="Logo" />
              <form>
                {loginAttemptFailed && !formFocused && (
                  <p className="pass-error">Invalid username or password.</p>
                )}
                <Field
                  onFocus={this.onFocus}
                  name="email"
                  type="email"
                  label="E-mail"
                  component={TextInput}
                />
                <Field
                  onFocus={this.onFocus}
                  name="password"
                  label="Password"
                  component={PasswordInput}
                />
                <Button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleSubmit(this.handleSubmitClicked.bind(this))}
                >
                  Log in
                </Button>
                <Link className="btn btn-link" to={'/forgot-password'}>
                  Forgot your password?
                </Link>
              </form>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStoreToProps = store => ({
  isAuthenticated: store.auth.isAuthenticated,
  constantsFetched: store.constants.constantsFetched,
  userDataFetched: store.signedInUser.userDataFetched,
  loginAttemptFailed: store.auth.loginAttemptFailed,
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(authActions, dispatch),
});

const LoginForm = reduxForm({
  form: 'LoginForm',
  validate,
})(Login);

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(LoginForm);
