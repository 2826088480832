import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { TextInput, Select2 } from '../../../../../../components/form';
import { addStudio, updateStudio } from '../../../../../../redux/actions/studiosSettingsActions';
import { resetStudioModalForm } from '../../../../../../redux/actions/uiActions';

import validate from './validations';

class StudioModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleSubmitClicked(data) {
    const { edit, addStudio, updateStudio } = this.props;

    const requestPartners = data.partners.map(partner => {
      return {
        id: partner.value,
        name: partner.label,
      };
    });

    const requestData = {
      id: data.id,
      name: data.name,
      company_name: data.company_name,
      company_address: data.company_address,
      company_registration_code: data.company_registration_code,
      partners: requestPartners,
    };

    if (edit) {
      updateStudio(requestData);
    } else {
      addStudio(requestData);
    }

    this.props.toggleModal();
  }

  handleCloseModal() {
    this.props.toggleModal();
    this.props.resetStudioModalForm();
  }

  render() {
    const { showModal, handleSubmit, pristine, edit, allUsers } = this.props;
    const allUsersList = allUsers.map(user => {
      return {
        value: user.id,
        label: user.name,
      };
    });

    return (
      <Modal className="away-modal" isOpen={showModal} toggle={this.handleCloseModal}>
        <ModalBody className="away-modal-body">
          <Fragment>
            <div className="away-modal-line">
              <h3>{edit ? 'Edit Studio' : 'Add Studio'}</h3>
            </div>

            <div>
              <Field id="studioName" name="name" label="Studio Name" component={TextInput} />
            </div>

            <Field
              className="away-dropdown-form version-2"
              id="studioPartners"
              label="Studio partner"
              name="partners"
              component={Select2}
              classNamePrefix="away"
              isMulti
              isClearable={false}
              isSearchable={true}
              options={allUsersList}
            />

            <div>
              <Field
                id="companyName"
                name="company_name"
                label="Company Name"
                component={TextInput}
              />
            </div>

            <div>
              <Field
                id="companyAddress"
                name="company_address"
                label="Address"
                component={TextInput}
              />
            </div>

            <div>
              <Field
                id="companyCui"
                name="company_registration_code"
                label="CUI"
                component={TextInput}
              />
            </div>

            <div>
              <Field id="id" name="id" label="Studio Id" type="hidden" component={TextInput} />
            </div>

            <div className="away-modal-buttons">
              <Button
                disabled={pristine}
                onClick={handleSubmit(this.handleSubmitClicked)}
                className="btn btn-primary"
              >
                Submit
              </Button>
              <Button onClick={this.handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
    );
  }
}

const selector = formValueSelector('StudioModalForm');
const StudioModalForm = reduxForm({
  validate,
  form: 'StudioModalForm',
  enableReinitialize: true,
})(StudioModal);

const mapStoreToProps = (store, ownProps) => {
  return {
    partners: selector(store, 'partners'),
    initialValues: { ...ownProps.initialValues },
    allUsers: store.team.allUsers,
  };
};

const mapDispatchToProps = dispatch => ({
  addStudio: bindActionCreators(addStudio, dispatch),
  updateStudio: bindActionCreators(updateStudio, dispatch),
  resetStudioModalForm: bindActionCreators(resetStudioModalForm, dispatch),
});

export default connect(
  mapStoreToProps,
  mapDispatchToProps,
)(StudioModalForm);
