import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { DateRange, Select2 } from '../../../../../../components/form';
import { generateVacationsReport } from '../../../../../../redux/actions/reportsActions';
import { formatDate } from '../../../../../../utils/Date';
import validate from './validations';
import '../../../../reportsModal.scss';

class VacationsReportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDateRange: false,
    };

    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.togglePicker = this.togglePicker.bind(this);
  }

  handleSubmitClicked(data) {
    const requestData = {
      start_date: formatDate(data.range.range1.startDate, 'YYYY-MM-DD'),
      end_date: formatDate(data.range.range1.endDate, 'YYYY-MM-DD'),
      studio_id: data.studio_id.value,
    };
    const reportName = `VacationReport_${data.studio_id.label}_${formatDate(
      data.range.range1.startDate,
      'YYYY.MM.DD',
    )}-${formatDate(data.range.range1.endDate, 'YYYY.MM.DD')}`;

    this.props.generateVacationsReport(requestData, reportName);
    this.props.toggleModal();
    this.props.reset();
  }

  handleCloseModal() {
    this.props.toggleModal();
    this.props.reset();
  }

  togglePicker = () => {
    this.setState({
      showDateRange: !this.state.showDateRange,
    });
  };

  render() {
    const { showModal, handleSubmit } = this.props;

    return (
      <Modal className="away-modal" isOpen={showModal} toggle={this.handleCloseModal}>
        <ModalBody className="away-modal-body">
          <Fragment>
            <div className="away-modal-line">
              <h3>Vacations Report</h3>
            </div>
            <Field
              className="away-dropdown-form version-2"
              id="studio"
              label="Studio"
              name="studio_id"
              component={Select2}
              placeholder="Select a studio"
              classNamePrefix="away"
              options={[
                {
                  label: 'All Studios',
                  value: 0,
                },
                ...this.props.studios,
              ]}
            />
            <div
              className="away-daterange-inputs"
              id="daterange"
              onClick={this.togglePicker.bind(this)}
            >
              <Field
                name="range"
                component={DateRange}
                showDatePicker={this.state.showDateRange}
                togglePicker={this.togglePicker}
              />
            </div>

            <div className="away-modal-buttons">
              <Button onClick={handleSubmit(this.handleSubmitClicked)} className="btn btn-primary">
                Generate
              </Button>
              <Button onClick={this.handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
    );
  }
}

const VacationsReportModalForm = reduxForm({
  validate,
  form: 'VacationsReportModalForm',
  enableReinitialize: true,
})(VacationsReportModal);

const mapDispatchToProps = dispatch => ({
  generateVacationsReport: bindActionCreators(generateVacationsReport, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(VacationsReportModalForm);
