import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { logoutUser } from '../../../redux/actions/authActions';

const Logout = ({ logout }) => (
  <Button color="link" onClick={logout}>
    Logout
  </Button>
);

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logoutUser, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(Logout);
