import _ from 'lodash';

export const processApiError = error => {
  const { response } = error;
  let errorText = '';

  if (response) {
    if (response.status === 422 && response.data) {
      errorText = Object.values(response.data).map(err => err.join('; '));
    }
  }
  return `API Error: ${_.upperFirst(errorText.toString())}`;
};
